import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./NotFoundPage.css";

const NotFoundImg = require("../../assets/notfound.png");

const NotFoundPage = () => {
  const [link, setLink] = useState("");

  useEffect(() => {
    setLink(window.location.href);
  });

  return (
    <div className="not-found-background">
      <div className="not-found-inner">
        <img src={NotFoundImg} alt="not found image" />
        <h1>Page not found</h1>
        <h5>The page you requested does not exist </h5>
        <p>{link}</p>
        <Link to="/dashboard" className="btn btn-primary">
          Back To Dashboard
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
