import React from 'react';
// import UserImg from '../../../../../public/assets/media/profile_user.jpg';
import { useNavigate } from "react-router-dom";
import './keyupdates.css'

const Keyupdates = () => {
    const navigate = useNavigate();

    const keyUpdates = () => {
        navigate('/keyupdates' , { replace: true });
    }

    const redirectTo = (id) => {
        navigate(`/keyupdatedetail/${id}`);
    };
    return (
        <>
            <div className="keyupdate-sec">
                <h3 className="title">
                    <span>Key Updates</span>
                    {/* <span className='icon' onClick={keyUpdates}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-arrow-right fa-w-14"><path fill="currentColor" d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z" className=""></path></svg>
                    </span> */}
                </h3>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">All</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="trending-tab" data-toggle="tab" href="#trending" role="tab" aria-controls="trending" aria-selected="false">Trending</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="analytics-tab" data-toggle="tab" href="#analytics" role="tab" aria-controls="analytics" aria-selected="false">Analytics</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="finance-tab" data-toggle="tab" href="#finance" role="tab" aria-controls="finance" aria-selected="false">Finance</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="stock-tab" data-toggle="tab" href="#stock" role="tab" aria-controls="stock" aria-selected="false">Stock</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="team-tab" data-toggle="tab" href="#team" role="tab" aria-controls="team" aria-selected="false">Teams</button>
                    </li>
                </ul>
                <div className="tab-content w-100" id="myTabContent">
                    <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                        <ul className="list-unstyled m-0">
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="green">A</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text orange">
                                                Teams
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            {/* <span className="read-update" onClick={() => redirectTo(1)}>
                                                Read More
                                            </span> */}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            {/* 
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <img src={UserImg} alt="userimg" />
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text red">
                                                Trending
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            <span className="read-update">
                                                Read More
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="blue">C</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text grey">
                                                Stock
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            <span className="read-update">
                                                Read More
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <img src={UserImg} alt="userimg" />
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text blue">
                                                Finance
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            <span className="read-update">
                                                Read More
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="red">G</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text red">
                                                Trending
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            <span className="read-update">
                                                Read More
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <img src={UserImg} alt="userimg" />
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text yellow">
                                                Analytics
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            <span className="read-update">
                                                Read More
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="yellow">P</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text blue">
                                                Finance
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            <span className="read-update">
                                                Read More
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="trending" role="tabpanel" aria-labelledby="trending-tab">
                        <ul className="list-unstyled m-0">
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="green">A</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text red">
                                                Trending
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            {/* <span className="read-update" onClick={() => redirectTo(1)}>
                                                Read More
                                            </span> */}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="red">G</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text red">
                                                Trending
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            <span className="read-update">
                                                Read More
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
                        <ul className="list-unstyled m-0">
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="green">A</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text yellow">
                                                Analytics
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            {/* <span className="read-update" onClick={() => redirectTo(1)}>
                                                Read More
                                            </span> */}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="finance" role="tabpanel" aria-labelledby="finance-tab">
                        <ul className="list-unstyled m-0">
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="green">A</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text blue">
                                                Finance
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            {/* <span className="read-update" onClick={() => redirectTo(1)}>
                                                Read More
                                            </span> */}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="yellow">P</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text blue">
                                                Finance
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            <span className="read-update">
                                                Read More
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="stock" role="tabpanel" aria-labelledby="stock-tab">
                        <ul className="list-unstyled m-0">
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="blue">C</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text grey">
                                                Stock
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            {/* <span className="read-update" onClick={() => redirectTo(1)}>
                                                Read More
                                            </span> */}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="team" role="tabpanel" aria-labelledby="team-tab">
                        <ul className="list-unstyled m-0">
                            <li>
                                <div className="news-row my-0 d-flex flex-wrap justify-content-between">
                                    <p className="user-img d-inline-flex my-2">
                                        <span className="green">A</span>
                                    </p>
                                    <div className="news-data">
                                        <h6 className="m-0 mt-1">lorem Ipsum dolar set amet</h6>
                                        <p className="my-1 news-content">This is a dummy news update post content. This is a dummy news post content. This is a dummy news update post content.</p>
                                        <p className="my-1 update-meta">
                                            <span className="label-text orange">
                                                Teams
                                            </span>
                                            <span className="post-date">Nov-4-2021</span>
                                            {/* <span className="read-update" onClick={() => redirectTo(1)}>
                                                Read More
                                            </span> */}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Keyupdates;
