import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function ProjectWiseRoleFilters({
  projecttId,
  getPermisionByRole,
  permisionByRoleId,
  getPermisionByRoleId,
}) {
  const [roleList, setRoleList] = useState<any>([]);
  const [roleListDropdown, setRoleListDropdown] = useState<any>();

  useEffect(() => {
    axios
      .get(`https://brain.akseerresearch.com/api/role/${projecttId}`)
      .then((res) => {
        let arr: any = [];
        console.log(
          `Role List =====> for project id => ${projecttId}`,
          res.data
        );
        for (let i = 0; i <= res.data?.length; i++) {
          console.log("PROJECT IDS ====>", res.data[i].id);
          arr.push({
            value: res.data[i]?.role?.id,
            label: res.data[i]?.role?.role,
          });
          setRoleListDropdown(arr);
        }
      })
      .catch((err) => console.log(err));

    return () => {
      return setRoleListDropdown([]);
    };
  }, [projecttId]);

  if (roleListDropdown?.length === 0) {
    return (
      <Select
        onChange={(values: any) => getPermisionByRole(values.value)}
        options={[{ value: 1, label: "No Roles Found" }]}
      />
    );
  } else {
    return (
      <Select
        onChange={(values: any) => {
          getPermisionByRole(values.value);
          getPermisionByRoleId(values.value);
        }}
        options={roleListDropdown}
      />
    );
  }
}
