import React, { useEffect, useState } from "react";
import "./ActiveSwitch.css";

interface Props {
  status: string | number | boolean;
  id?: string | number | null;
}

export default function ActiveSwitch({ status, id = null }: Props) {
  const [swtichStatus, setSwitchStatus] = useState<string | number | boolean>();

  //   useEffect(() => {
  //     let propType: string | number | boolean = typeof status;
  //     switch (propType) {
  //       case "boolean":
  //         if (status === true) {
  //           setSwitchStatus(true);
  //         } else {
  //           setSwitchStatus(false);
  //         }
  //         break;
  //       case "string":
  //         if (status === "active") {
  //           setSwitchStatus(true);
  //         } else {
  //           setSwitchStatus(false);
  //         }
  //         break;
  //       case "number":
  //         if (status === 0) {
  //           setSwitchStatus(true);
  //         } else {
  //           setSwitchStatus(false);
  //         }
  //         break;
  //       default:
  //         setSwitchStatus(false);
  //         break;
  //     }
  //   });

  const handleChange = (): void => {
    setSwitchStatus(!swtichStatus);
    console.log(swtichStatus);
  };

  return (
    <div className="custom-active-switch">
      <span className={`status-label ${!swtichStatus ? "active" : ""}`}>
        Inactive
      </span>
      <label className="switch">
        <input
          onChange={() => {
            handleChange();
          }}
          type="checkbox"
        />
        <span className="slider round"></span>
      </label>
      <span className={`status-label ${swtichStatus ? "active" : ""}`}>
        Active
      </span>
    </div>
  );
}
