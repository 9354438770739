import React from 'react';
import MainLayout from '../../layout/MainLayout/MainLayout'
import List from './components/List';
import './Index.css';

const ContactDairy = () => {
  return (
      <MainLayout>
          <List/>
      </MainLayout>
  )
}

export default ContactDairy
