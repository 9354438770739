import {
  baseURL,
  AnalystPanelBaseURL,
  AnalystPanelBaseURLUpdate,
} from "./BaseURL";

export const loginAPI = `${baseURL}/login`;
export const logoutAPI = `${baseURL}/logout`;

export const projectListAPI = `${baseURL}/project`;
export const ProjectEditAPI = `${baseURL}/project`;
export const ProjectAddAPI = `${baseURL}/project`;
export const ProjectChangeStatusAPI = `${baseURL}`;

export const rolAddAPI = `${baseURL}/role`;
export const rolesActiveProjectsAPI = `${baseURL}/getactiveprojects`;
export const rolesListAPI = `${baseURL}/role`;
export const roleseditAPI = `${baseURL}/role`;

export const permissionListAPI = `${baseURL}/permission`;
export const permissionAddAPI = `${baseURL}`;
export const permissionUpdateAPI = `${baseURL}`;
export const permissionDeleteAPI = `${baseURL}`;
export const permissionEditAPI = `${baseURL}/permission`;
export const permissionAssignAPI = `${baseURL}/permission/add`;

export const userListAPI = `${baseURL}/user`;
export const userEditAPI = `${baseURL}/user`;
export const userGetAPI = `${baseURL}/user`;
export const userAddAPI = `${baseURL}/user`;

// Key Updates API List
export const KeyupdatesCategoriesAPI = `${baseURL}/category`;
export const KeyUpdatesEditCategoryAPI = `${baseURL}/category`;
export const KeyUpdatesListAPI = `${baseURL}/keyupdates`;
export const KeyUpdatesAddAPI = `${baseURL}/keyupdates`;
export const KeyUpdatesAddCategoryAPI = `${baseURL}/category`;
export const ForgotPasswordAPI = `${baseURL}/forgotpassword`;

// Analyst Panel
export const DefaultFinancialDataAPI = `${AnalystPanelBaseURL}/financial`;
export const ManageUserListAPI = `${baseURL}/askanalyst/users`;
export const ManageUserSectorListAPI = `${baseURL}/askanalyst/sectors`;
export const ManageUserCompanyListAPI = `${baseURL}/askanalyst/sector/companies`;
export const ManageUserAssignAPI = `${baseURL}/askanalyst/assign/sector/company`;
export const ManageUserEditFetchAPI = `${baseURL}/askanalyst/users`;

//AskAnalyst Recent Development
export const AddRecentDevelopment = `${baseURL}/askanalyst/recent/development`;
export const AddDraftRecentDevelopment = `${baseURL}/askanalyst/recent/development/draft`;
export const FetchAllDraftsRecentDevelopment = `${baseURL}/askanalyst/recent/development/draft`;
export const FetchSingleDraftRecentDevelopment = `${baseURL}/askanalyst/recent/development/draft`;


//Charting Tool
export const ChartingDataTypeFilter = `${baseURL}/chartingtool/getdatatype`;
export const ChartingSectorFilter = `${baseURL}/chartingtool/getsector`;
export const ChartingCommodityFilter = `${baseURL}/chartingtool/getcommodity`;
export const ChartingPVCData = `${baseURL}/chartingtool/pvcdata`;

// Contact Directory
export const contactDirectoryAPI = `${baseURL}/contactdirectory`;
export const contactDirectoryAddAPI = `${baseURL}/contactdirectory`;
export const contactDirectoryUpdateAPI = `${baseURL}/contactdirectory`;
export const contactDirectoryDeleteAPI = `${baseURL}/contactdirectory`;

// Policy SOPs
export const policyFilesListAPI = `${baseURL}/policiesandsop/policiesandsop`;
export const policyFilesUploadAPI = `${baseURL}/policiesandsop/policiesandsop`;

// Ask Analyst Admin
export const SearchSuggestionAPI = `${AnalystPanelBaseURLUpdate}/search/suggestions`;
// export const FinancialUpdateNotitificationAPI  = `${AnalystPanelBaseURLUpdate}/financial/notifications`;
export const FinancialUpdateNotitificationAPI = `${AnalystPanelBaseURLUpdate}/financial/notifications`;
export const FetchFinancialDataAPI = `${AnalystPanelBaseURLUpdate}/financial`;
// export const FinancialUpdateNotitificationAPI  = `${AnalystPanelBaseURLUpdate}/financial/notifications`;
export const CompanyInformationUpdateAPI = `${AnalystPanelBaseURLUpdate}/information/company/updated`;
