import React , {useState , useRef, createElement} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState , RichUtils } from 'draft-js';
// import draftFunc from './draftJsFunc.js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
// import createCounterPlugin from '@draft-js-plugins/counter';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import '@draft-js-plugins/counter/lib/plugin.css';
// import { handleNewLine, insertNewUnstyledBlock } from 'draft-js-utils';
// import { handleNewLine, insertNewUnstyledBlock } from 'draft-js-utils';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import autoJson from './autos_json.json';

// // Creates an Instance. At this step, a configuration object can be passed in as an argument.
// const counterPlugin = createCounterPlugin();
// // Extract a counter from the plugin.
// const { CharCounter } = counterPlugin;


const PatternContentChild = ({buttonCondition, tableRatioProp, boxId}) => {
    const [editorState, setEditorState] = useState(()=> EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [showTableModal, setShowTableModal] = useState(false);
    const [showChartModal , setShowChartModal] = useState(false);
    const [selectChartType , setSelectChartType] = useState();
    const [showTitleModal, setShowTitleModal] = useState(false);
    const [showRichModal, setShowRichModal] = useState(false);
    const [titleMaxLength, setTitleMaxLength] = useState(0);
    const [titleRemainingLength, setTitleRemainingLength] = useState(0);
    const [BoxTitleValue , setBoxTitleValue] = useState("");
    const [titleTextSize, setTitleTextSize ] = useState();
    const [showDataModal, setShowDataModal] = useState(false);
    const [showChartDataModal, setShowChartDataModal] = useState(false);
    const [buttonActive, setButtonActive] = useState(true);
    const [selectedArea , setSelectedArea] = useState('');
    const [dataRepresent , setDataRepresent] = useState('');
    const [tableRatio, setTableRatio] = useState();
    const [tableRow, setTableRow] = useState();
    const [tableCol, setTableCol] = useState();

    let rowChange = tableRow;
    let colChange = tableCol;
    let colData = [];
    // let resetId = boxId + '-reset';


    for (var i = 0; i < autoJson.length; i++) {
        for (var key in autoJson[i]) {
            if (colData.indexOf(key) === -1) {
                colData.push(key);
            }
        }
    }

    const handleCloseDataModal = () =>{
        setShowDataModal(false);
    }

    const handleTableRatioSubmit = () =>{
        setShowTableModal(false);
        setShowDataModal(true);
    }

    const handleDataModalSubmit = () =>{
        setShowDataModal(false);
        generateTable(rowChange , colChange , selectedArea);
        setTimeout(() => {
            setTableRatio('');
            setTableRow(0);
            setTableCol(0);
        }, 1000);
    }

    const handleShowTableModal = (event) => {
        setSelectedArea(boxId);
        let getRatio = event.target.getAttribute('ratio');
        setTableRatio(getRatio);
        let ratioSplit = getRatio.split('x');
        let afterParse = [parseInt(ratioSplit[0]) , parseInt(ratioSplit[1])];
        setTableRow(Math.floor(afterParse[1] / 4.16));
        setTableCol(Math.floor(afterParse[0] / 6.25));
        setTimeout(() => {
            setShowTableModal(true);
        } , 1000)
    };

    const handleRowChange = (event) =>{
        if(event.target.value > tableRow){
            alert("can't change to more than max limit.")
        }
        else{
            rowChange = event.target.value;
        }
    }

    const handleColChange = (event) =>{
        if(event.target.value > tableCol){
            alert("can't change to more than max limit.")
        }
        else{
            colChange = event.target.value;
        }
    }

    const generateTable = (row , column , element) =>{
        //Create a HTML Table element.
        let table = document.createElement("TABLE");
        let tableHead = document.createElement("THEAD");
        let tableBody = document.createElement("TBODY");

        let elementPath = `#` + element + ` .content-box` ;

        //Add the header row with thead.
        table.appendChild(tableHead);
        for(var i=1; i<=1; i++){
            let tableHeadRow = document.createElement("TR");
            tableHead.appendChild(tableHeadRow);
            for (var i = 0; i < column; i++) {
                let headerCell = document.createElement("TH");
                headerCell.innerHTML = colData[i];
                tableHeadRow.appendChild(headerCell);
            }
        }

        //Add tbody and the data rows in body.
        for (var i = 1; i < row; i++) {
            let tableBodyRow = document.createElement("TR")
            for (var j = 0; j < column; j++) {
                let bodyCell = document.createElement("TD");
                bodyCell.innerHTML = autoJson[i][colData[j]];
                tableBodyRow.appendChild(bodyCell);
            }
            tableBody.appendChild(tableBodyRow);
        }
        table.appendChild(tableBody);
        let dvTable = document.querySelector(elementPath);
        dvTable.innerHTML = "";
        setButtonActive(false);
        setTimeout(()=>{
            dvTable.appendChild(table);
        } , 100)
    }

    // box title functions start

    const boxTitle = (element) => {
        let elementPath = `#` + element + ` .content-box` ;
        let heading = document.createElement("h4");
        let dvTitle = document.querySelector(elementPath);
        dvTitle.innerHTML = "";
        heading.innerHTML = BoxTitleValue;
        setButtonActive(false);
        setTimeout(()=>{
            dvTitle.appendChild(heading);
        } , 100)
        setShowTitleModal(false);
        setBoxTitleValue('');
    }

    const handleTitleTextSubmit = () => {
        boxTitle(selectedArea);
        setShowDataModal(false);
        setTitleRemainingLength(0);
    }

    const handleShowTitleModal = (event) => {
        setSelectedArea(boxId);
        var element = document.getElementById(event.target.parentNode.parentNode.id);
        console.log(element.offsetWidth , "current Element width");
        var elementWidth = element.offsetWidth;
        // use width to get characters limit
        var elementCharCapacity = parseInt((elementWidth/8) * 1.6);
        setTitleMaxLength(elementCharCapacity);
        setTimeout(() => {
            setShowTitleModal(true);
        } , 1000)
    }

    const handleTitleChange = (event) =>{
        setTitleRemainingLength(titleMaxLength - event.target.value.length);
        setBoxTitleValue(event.target.value);
    }
    // box title functions end


    //rich text functions start
    const handleShowRichModal = (event) => {
        setSelectedArea(boxId);
        let dataRepresent = event.target.getAttribute('data-format');
        // console.log(dataRepresent , "dataRepresent");
        setDataRepresent(dataRepresent);
        setTimeout(() => {
            setShowRichModal(true);
        } , 1000)
    };

    const handleEditText = () =>{
        setTimeout(() => {
            setShowRichModal(true);
        } , 1000)
    }

    const handleRichTextSubmit = () => {
        boxRichText(selectedArea);
        setShowRichModal(false);
    }

    const handleCloseRichModal = () => {
        setShowRichModal(false);
    }

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
        console.log(editorState , "editor start");
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }

    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
    }

    const boxRichText = (element) => {
        let elementPath = `#` + element + ` .content-box` ;
        let dvTitle = document.querySelector(elementPath);
        console.log(dvTitle);
        let richContent = document.createElement('div');
        richContent.classList.add("rich-content");
        dvTitle.innerHTML = "";
        dvTitle.append(richContent);
        richContent.innerHTML = createMarkup(convertedContent).__html;
        // console.log(createMarkup(convertedContent).__html);
        setButtonActive(false);
        // setTimeout(()=>{
        //     dvTitle.appendChild(heading);
        // } , 100)
        setShowTitleModal(false);
        // setEditorState('');
    }
    //rich text functions end

    //Chart functions start
    const handleShowChartModal = (event) => {
        setSelectedArea(boxId);
        let dataRepresent = event.target.getAttribute('data-format');
        setDataRepresent(dataRepresent);
        setTimeout(() => {
            setShowChartModal(true);
        } , 1000)
    };

    const handleSelectChart = (event) =>{
        const selectedType = event.target.value;
        setSelectChartType(selectedType);
    }

    const handleChartTypeSubmit = () =>{
        setShowChartDataModal(true);
        setShowChartModal(false);
    }

    const handleChartDataModalSubmit = () =>{
        setShowChartDataModal(false);
        generateChart(selectChartType , selectedArea);
    }

    const chartRef = useRef();

    const generateChart = (chartType , element) =>{
        //Create targeted element
        let elementPath = 'content-box-' + element;
        let root = am5.Root.new(elementPath);
        chartRef.current = root;
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingBottom: 10,
            paddingRight: 3,
            paddingTop: 10,
            paddingLeft: 0,
        }));

        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);

        // Generate random data
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        let value = 100;

        function generateData() {
            value = Math.round((Math.random() * 10 - 5) + value);
            am5.time.add(date, "day", 1);
            return {
                date: date.getTime(),
                value: value
            };
        }

        function generateDatas(count) {
            let data = [];
            for (var i = 0; i < count; ++i) {
                data.push(generateData());
            }
            console.log(data , "gathered data");
            return data;
        }

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            baseInterval: {
                timeUnit: "day",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                // labels: {
                //     template:{
                //         fontSize: '5'
                //     }
                // }
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                // labels: {
                //     template:{
                //         fontSize: 12
                //     }
                // }
            })
        }));

        // Add series
        if(chartType == "Line"){
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            let series_Line = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {})
            }));

            // Add scrollbar
            // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
            // chart.set("scrollbarX", am5.Scrollbar.new(root, {
            //     orientation: "horizontal"
            // }));

            // Set data
            let data = generateDatas(120);
            series_Line.get("tooltip").label.set("text","{valueY}");
            series_Line.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series_Line.appear(1000);
        }
        else{
            let series_Bar = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: "Series",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    valueXField: "date",
                    fill: am5.color(0x095256),
                    stroke: am5.color(0x095256),
                    tooltip: am5.Tooltip.new(root, {})
            }));

            // Set data
            let data = generateDatas(50);
            series_Bar.get("tooltip").label.set("text","{valueY}");
            series_Bar.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series_Bar.appear(1000);
        }
        chart.appear(1000, 100);
        setButtonActive(false);
    }
    //Chart functions end
    function handleResetChart() {
        chartRef.current.dispose();
        let elementPath = `#` + boxId + ` .content-box` ;
        let resetElement = document.querySelector(elementPath);
        resetElement.innerHTML = "";
        setButtonActive(true);
    }

    //general modal functions start
    const handleReset = () => {
        let elementPath = `#` + boxId + ` .content-box` ;
        console.log(elementPath , "clean elementPath");
        let resetElement = document.querySelector(elementPath);
        resetElement.innerHTML = "";
        setButtonActive(true);
        setEditorState('');
        return false;
    }
    const handleCloseModal = () => {
        setShowTableModal(false);
        setShowTitleModal(false);
        setShowChartModal(false);
        setShowChartDataModal(false);
        setTitleRemainingLength(0);
    }
    //general modal functions end

    return (
        <>

            <div id={"content-box-" + boxId} className="content-box w2-100 h2-100">
            </div>
            {buttonActive ?
                <div className="user-option list-unstyled m-0 d-flex flex-wrap justify-content-center">
                    <>
                        {buttonCondition <= 15 ?
                            <>
                                <button data-format="text"  onClick={(e) => handleShowTitleModal(e)}>
                                    Title
                                </button>
                            </>
                        :
                            <>
                                <button data-format="chart" onClick={(e) => handleShowChartModal(e)}>
                                    Chart
                                </button>
                                <button data-format="table" ratio={tableRatioProp} onClick={(e) => handleShowTableModal(e)}>
                                    Table
                                </button>
                                <button data-format="rich-text"  onClick={(e) => handleShowRichModal(e)}>
                                    Text
                                </button>
                            </>
                        }
                    </>
                </div>
            :
                // null
                dataRepresent == "chart" ?
                    <button type="button" className="reset-button btn-absolute" onClick={handleResetChart}>Reset</button>
                : dataRepresent == "rich-text" ?
                    <>
                        <button type="button" className="edit-button reset-button btn-absolute" onClick={handleEditText}>Edit</button>
                        <button type="button" className="reset-button btn-absolute" onClick={handleReset}>Reset</button>
                    </>
                :
                    <button type="button" className="reset-button btn-absolute" onClick={handleReset}>Reset</button>
            }

            <Modal className="table-modal" show={showTableModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title className="text-center d-inline-block w-100">Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Max. Limit For Table Ratio : {tableRow} x {tableCol} (Row <small>x</small> Column)</p>
                    {/* <p className="d-flex flex-wrap justify-content-start align-items-center"><span col className="mr-3">Rows: {tableRow} </span><span col>Columns: {tableCol}</span></p> */}
                    <p className="d-inline-block w-100">Enter No. Rows : <input type="number" onChange={(e) => handleRowChange(e)} max={tableRow} min="1" placeholder={"Max limit " + tableRow}/>  </p>
                    <p className="d-inline-block w-100">Enter No. Columns : <input type="number" onChange={(e) => handleColChange(e)} max={tableCol} min="1" placeholder={"Max limit " + tableCol}/>  </p>
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" onClick={handleTableRatioSubmit}>
                        Submit
                    </Button>
                    <Button variant="outline-dark" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="data-modal" show={showDataModal} onHide={handleCloseDataModal}>
                <Modal.Header>
                    <Modal.Title className="text-center d-inline-block w-100">{dataRepresent} Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Table Data Follow Modal!</p>
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" onClick={handleDataModalSubmit}>
                        Submit
                    </Button>
                    <Button variant="outline-dark" onClick={handleCloseDataModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Block Title Input Modal */}
            <Modal className="Block-title-modal" show={showTitleModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title className="text-center d-inline-block w-100">Enter Block Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea rows="3" cols="50" className="mx-auto" maxLength={titleMaxLength} style={{fontSize: titleTextSize}} onChange={(e) => handleTitleChange(e)}></textarea>
                    {titleRemainingLength > 0 ?
                        <span className={ titleRemainingLength >= 15 ? "remain-char green" : titleRemainingLength >= 10 ? "remain-char yellow" : "remain-char red"}>{titleRemainingLength}</span>
                    :
                        null
                    }
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" onClick={handleTitleTextSubmit}>
                        Submit
                    </Button>
                    <Button variant="outline-dark" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Block Title Input Modal */}

            {/* Block Rich Modal */}
            <Modal size="lg" className="Block-rich-modal" show={showRichModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title className="text-center d-inline-block w-100">Enter Detail Text Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        // handleReturn={(event) => {
                        //     // override behavior for enter key
                        //     var newEditorState = null;
                        //     if (event.keyCode === 13 && event.shiftKey) {
                        //         // with shift, make a new block
                        //         newEditorState = insertNewUnstyledBlock(editorState);
                        //     } else if (event.keyCode === 13 && !event.shiftKey) {
                        //         // without shift, just a normal line break
                        //         newEditorState = RichUtils.insertSoftNewline(editorState);
                        //     }
                        //     if (newEditorState) {
                        //         this.setState({'editorState': newEditorState})
                        //         return true;
                        //     }
                        //     return false;
                        // }}
                    />
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" onClick={handleRichTextSubmit}>
                        Submit
                    </Button>
                    <Button variant="outline-dark" onClick={handleCloseRichModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Block Rich Modal */}

            {/* Block Chart Modal */}

            <Modal className="table-modal chart-type-modal" show={showChartModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title className="text-center d-inline-block w-100">Select Chart Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap justify-content-between" onChange={(e) => handleSelectChart(e)}>
                        <label htmlFor="line-type"><input id="line-type" type="radio" className="form-control" value="Line" name="chart" /> Line</label>
                        <label htmlFor="bar-type"><input id="bar-type" type="radio" className="form-control" value="Bar" name="chart" /> Bar</label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" onClick={handleChartTypeSubmit}>
                        Submit
                    </Button>
                    <Button variant="outline-dark" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className="data-modal" show={showChartDataModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title className="text-center d-inline-block w-100">{dataRepresent} Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Chart Data Follow Modal!</p>
                    <p>{selectedArea}</p>
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" onClick={handleChartDataModalSubmit}>
                        Submit
                    </Button>
                    <Button variant="outline-dark" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Block Chart Modal */}
        </>
    )
}

export default PatternContentChild;
