import React , {useState , useEffect} from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import {AddRecentDevelopment , AddDraftRecentDevelopment} from '../../../../api/ApiList';
import GlobalNotification from "../../components/GlobalNotification/GlobalNotification";
import { INotificationItems } from "../../Interfaces/AnalystPanelInterfaces";
import { useCookies } from "react-cookie";
import historyClock from "../../.././../assets/history-clock.svg";
import RecentDevelopmentDrafts from "../../components/RecentDevelopmentDraft/RecentDevelopmentDrafts";
import SearchBarWithFilters from "../../components/SearchBarWithFilters/SearchBarWithFilters";
import RecentDevelopmentForm from "../../components/RecentDevelopmentEditor/RecentDevelopmentForm";
import { DoDecryption, FindRequiredPermission } from "../../../../AuthFunctions";

export default function RecentDevelopment() {
  const [notifications, setNotifications] = useState<Array<INotificationItems>>();
  const [notify, setNotify] = useState<boolean>(true);
  const [errList , setErrList] = useState<any>([]);
  const [enable , setEnable] = useState<boolean>(false);
  const [draftSidebarShow, setDraftSidebarShow] = useState<boolean>(true);
  // const [draftList , setDraftList] = useState<any>([])
  const [submitFlag , setSubmitFlag] = useState<boolean>(false);
  const [noCompanyFlag , setNoCompanyFlag] = useState<boolean>(false);
  const [selectedCompanyData , setSelectedCompanyData] = useState<any>()
  const [formList , setFormList] = useState([
    {
      "year": "",
      "qtr": "",
      "cat": null,
      "news": ""
    }
  ])
  const [userHasRoles, setUserHasRoles] = useState();
  let userInfo:any = localStorage.getItem("userActive");

  const [cookies, setCookies] = useCookies();

  const validate = async (values) => {
    // debugger;
    let errors: any = [];
    console.log(errors , "errors array");
    // debugger;
    for(let i = 0; i <= values.length - 1; i++){
      let obj = {
        index: 0,
        year: "",
        qtr: "",
        cat: "",
        news: ""
      }
      if(values[i].year === ""){
        obj.year = "Field is Required";
        setSubmitFlag(false)
      }else{
        obj.year = "";
      }
      if(values[i].qtr === ""){
        obj.qtr = "Field is Required";
        setSubmitFlag(false)
      }else{
        obj.qtr = "";
      }
      if(values[i].cat === null){
        obj.cat = "Field is Required";
        setSubmitFlag(false)
      }else{
        obj.cat = "";
      }
      if(values[i].news === ""){
        obj.news = "Field is Required";
        setSubmitFlag(false)
      }else{
        obj.news = "";
      }
      if(values[i].year === "" || values[i].qtr === "" || values[i].cat === null || values[i].news === ""){
        // obj.index = i;
        // errors[i] = obj;
        setEnable(true);
        setSubmitFlag(false)
      }
      else if(values[i].year !== "" && values[i].qtr !== "" && values[i].cat !== (null || undefined) && values[i].news !== ""){
        if(i === values.length - 1){
          setSubmitFlag(true)
        }

      }
      obj.index = i;
      errors[i] = obj;
    }

    if(errors.length > 0){
      setErrList(errors);
      return false;
    }
    else{
      setSubmitFlag(true)
    }
  };

  //adding more form instance on assign more action
  const handleAddMore = () =>{
    setSubmitFlag(false);
    setFormList([...formList ,
      {
        "year": "",
        "qtr": "",
        "cat": null,
        "news": ""
      }
    ])
  }

  //removing field on delete action
  const handleRemoveMore = (i) =>{
    console.log(i , " handle remove i");
    const formListClone = [...formList];
    const removeInstance = parseInt(i);
    formListClone.splice(removeInstance , 1);
    setFormList(formListClone);
  }

  const handleYearChange = (e) => {
    let index = parseInt(e.target.getAttribute('name').split('-')[1]);
    let key = e.target.getAttribute('name').split('-')[0];
    let val = e.target.value;
    const formListClone = [...formList];
    formListClone[index][key] = val;
    setFormList(formListClone);
    validate(formList);
    setEnable(false);
  }

  const handleQtrChange = (e) => {
    let index = parseInt(e.target.getAttribute('name').split('-')[1]);
    let key = e.target.getAttribute('name').split('-')[0];
    let val = e.target.value;
    const formListClone = [...formList];
    formListClone[index][key] = val;
    setFormList(formListClone);
    validate(formList);
    setEnable(false);
  }

  const handleCatChange = (e) => {
    let index = parseInt(e.target.getAttribute('name').split('-')[1]);
    let key = e.target.getAttribute('name').split('-')[0];
    let val = e.target.value;
    const formListClone = [...formList];
    formListClone[index][key] = parseInt(val);
    setFormList(formListClone);
    validate(formList);
    setEnable(false);
  }

  const handleNewsChange = (e) => {
    // debugger;
    let index = parseInt(e.target.getAttribute('name').split('-')[1]);
    let key = e.target.getAttribute('name').split('-')[0];
    let val = e.target.value;
    const formListClone = [...formList];
    formListClone[index][key] = val;
    setFormList(formListClone);
    validate(formList);
    setEnable(false);
  }

  const handleSubmit = async () => {
    // debugger;
    if(selectedCompanyData){
      validate(formList);
      let userInfo:any = localStorage.getItem("userActive");
      userInfo = JSON.parse(userInfo)
      const payload = {
        user_company:{"user_id": userInfo.id, "company_id": selectedCompanyData.id},
        data: formList
      }

      if(submitFlag){
        console.log("flag is true ======");
        const formData = new FormData();
        formData.append("data" , JSON.stringify(formList));
        formData.append("user_company" , JSON.stringify({"user_id": userInfo.id, "company_id": selectedCompanyData.id}));
        axios({
            method: "POST",
            url: AddRecentDevelopment,
            data: formData,
        }).then((response) => {
            console.log(response.data , "success response");
            toast.success(response.data.success , {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setErrList([]);

            axios({
              method: "POST",
              url: AddDraftRecentDevelopment,
              data: formData,
            }).then((response) => {
                // console.log(response.data , "add draft success response");
                // toast.success(response.data.success , {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
                setErrList([]);
            }).catch((err) => {
                console.log(err.response , "error");
            })
        }).catch((err) => {
            console.log(err.response , "error");
        })

      }

    }else{
      setEnable(true);
      setNoCompanyFlag(true);
    }
  }

  const handleSubmitDraft = async () => {
    // debugger;
    if(selectedCompanyData){
      validate(formList);
      // let userInfo:any = localStorage.getItem("userActive");
      userInfo = JSON.parse(userInfo)
      // const payload = {
      //   user_company:{"user_id": userInfo.id, "company_id": selectedCompanyData.id},
      //   data: formList
      // }

      // console.log(payload , "draft submission payload");

      if(submitFlag){
        // console.log("flag is true ======");
        const formData = new FormData();
        formData.append("data" , JSON.stringify(formList));
        formData.append("user_company" , JSON.stringify({"user_id": userInfo.id, "company_id": selectedCompanyData.id}));
        axios({
            method: "POST",
            url: AddDraftRecentDevelopment,
            data: formData,
        }).then((response) => {
            // console.log(response.data , "add draft success response");
            toast.success(response.data.success , {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setErrList([]);
        }).catch((err) => {
            console.log(err.response , "error");
        })
      }

    }else{
      setEnable(true);
      setNoCompanyFlag(true);
    }
  }

  const toggleDraftSidebar = () => {
    // console.log("TOGGLING SIDEBAR SHOW =====>", draftSidebarShow);
    setDraftSidebarShow(!draftSidebarShow);
  };

  useEffect(() => {
    setEnable(false);
    setNoCompanyFlag(false);
    setUserHasRoles(
      
      DoDecryption(localStorage.getItem("UserAuth")).authorization
    );
  } , [selectedCompanyData])

  const notificationRequest = () => {
    axios
      .get(
        "https://brain.akseerresearch.com/api/askanalyst/financial/notifications",
        {
          headers: {
            Authorization: `Bearer ${
              cookies?.auth?.access_token ?? cookies?.auth?.access_token
            }`,
          },
        }
      )
      .then(function (response) {
        console.log(response.data.response, "notification response");
        setNotifications(response.data.response);
        setNotify(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const notifyOnClick = (e) => {
    const payload = {
      company_id: parseInt(e.target.getAttribute("data-compId")),
      user_id: parseInt(e.target.getAttribute("data-userId")),
      type: e.target.getAttribute("data-type"),
      period: e.target.getAttribute("data-period"),
    };

    const formData = new FormData();

    formData.append("company_id", JSON.stringify(payload.company_id));
    formData.append("user_id", JSON.stringify(payload.user_id));
    formData.append("type", JSON.stringify(payload.type));
    formData.append("period", JSON.stringify(payload.period));

    axios({
      method: "post",
      url: "https://brain.akseerresearch.com/api/financial/approval/data",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${cookies?.auth?.access_token}`,
      },
      data: formData,
    })
      .then(function (response) {
        console.log(response, "notification response after click ");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid p-0">
      <div className="recent-notification-block d-inline-block position-absolute" style={{height: '40px',width: '60px'}}>
        <GlobalNotification
          notify={notify}
          notificationRequest={notificationRequest}
          setNotify={setNotify}
          notifications={notifications}
          notifyOnClick={notifyOnClick}
        />
      </div>
      <RecentDevelopmentDrafts 
        draftSidebarShow={draftSidebarShow}
        toggleDraftSidebar={toggleDraftSidebar}
        user={userInfo}
        setSelectedCompanyData={setSelectedCompanyData}
        setFormList={setFormList}
      />
      <div className="row d-inline-block w-100" style={{ margin: "30px auto" }}>
          <div className="recent-development-main-filter w-100 d-flex flex-wrap">
            <SearchBarWithFilters setSelectedCompanyData={setSelectedCompanyData}/>
          </div>
          {/* <button className="btn btn-light">View Draft</button>  */}
      </div>
      <div className={`recent-company-title-block d-inline-flex align-content-center w-100 mb-3 ${selectedCompanyData ? "justify-content-between" : "justify-content-end"} `}>
        {selectedCompanyData ? 
          <h3 className="recent-company-title m-0" style={{ color: "#3969d5", textTransform: "capitalize" }}>{selectedCompanyData.name} ({selectedCompanyData.symbol})</h3>
        : null}
        <button className="btn view-draft-btn" onClick={() => toggleDraftSidebar()}>
          <span>
            <img src={historyClock} alt="" />
          </span>
          View Drafts
        </button>
      </div>
      <div className="row">
        {formList ? formList.map((d , i) => (
          <div key={i} className="col-12 px-1 fields-instance">
            <RecentDevelopmentForm 
              nameIndex={i} 
              formListObject={d}
              errList={errList.length === formList.length ? errList : null}
              handleYearChange={handleYearChange}
              handleQtrChange={handleQtrChange}
              handleCatChange={handleCatChange}
              handleNewsChange={handleNewsChange}
            />
            { formList.length > 1 ?
              <button className='btn remove-instance' onClick={()=>handleRemoveMore(i)}>X</button>
            : null}
          </div>
        )) : 
          null
        }
        <div className="col-12 d-flex flex-wrap justify-content-end">
          <button type="button" className="btn btn-link add-from-instance" onClick={handleAddMore}>+ Add More</button>
        </div>
      </div>
      <div className="col-12 d-flex flex-wrap justify-content-center">
        <button type="button" className="btn btn-cancel mx-2 draft-all" disabled={enable} onClick={handleSubmitDraft}>Save as Draft</button>
        <button type="button" className="btn btn-primary mx-2 save-all" disabled={enable} onClick={handleSubmit}>Submit for Approval</button>
        {
          (userHasRoles)  && (FindRequiredPermission(userHasRoles, "Ask Analyst", "Approver") || FindRequiredPermission(userHasRoles, "Ask Analyst", "Admin")) ? 
            <>
              <button type="button" className="btn btn-success btn-cancel mx-2 draft-all" disabled >Approve Draft</button>
              <button type="button" className="btn btn-danger btn-cancel mx-2 draft-all" disabled>Reject Draft</button>
            </>
          :
          <></>
        }
      </div>
      {noCompanyFlag ?
        <p className="my-3 text-center d-inline-block w-100" style={{ color: "#3969d5", textTransform: "capitalize" }}>Please Select Company First</p> 
      : null}
    </div>
  );
}
