import React, {useState , useEffect , useRef} from 'react';
import { useCookies } from 'react-cookie';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {Exporting , ExportingMenu} from '@amcharts/amcharts5/plugins/exporting';
import '@amcharts/amcharts5/.internal/plugins/exporting/ExportingCSS'
import downloadIcon from '../../../../assets/media/download_icon.svg';
import shareIcon from '../../../../assets/media/share_icon.svg';
import copyIcon from '../../../../assets/media/copy_icon.svg';
import './ColumnChart.css';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';

const ColumnChart = ({data}) => {
    const [chartData , setChartData] = useState([]);
    const [destroyColumn , setDestroyColumn] = useState(false);
    const chartRefColumn = useRef<any>();

    useEffect(() => {

        let p = new Promise((resolve, reject) => {
            resolve(setDestroyColumn(true));
            console.log(data.data, "dataList for chart");
        });
        p.then(() => {
            setChartData(data);
        }).then(() => {
            generateChart(data.chart_data , data.header)
        });
    }, [data])

    const generateChart:any = (data1 , header) =>{
        if(destroyColumn === true){
            chartRefColumn.current.dispose();
        }
        //Create targeted element
        let elementPath = "columnChart";
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root = am5.Root.new(elementPath);
        chartRefColumn.current = root;
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        root.numberFormatter.setAll({
            numberFormat: "#.#",
            numericFields: ["valueY"]
        });
        
        setDestroyColumn(true);
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX"
        }));

        chart.get('colors')?.set('colors' , [
            am5.color(0x3969D5),
            am5.color(0x6c98f9),
            am5.color(0x8bbdfb),
            am5.color(0x6883be),
            am5.color(0x35a4ff),
            am5.color(0x0088ff),
        ])

        chartRefColumn.current._logo._childrenDisplay.visible = false;
        chart._settings.paddingBottom = 25;
        chart._settings.paddingLeft = 0;
        
        
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            // behavior: "none"
            behavior: "zoomX",
        }));
        // cursor.lineY.set("visible", false);
        
        
        // Generate random data
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        let value = 100;        
        
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,  
            baseInterval: {       
                timeUnit: "day",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 1,
                strokeWidth: 0.2,
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let xRenderer = xAxis.get('renderer');
        xRenderer.grid.template.setAll({
            stroke: am5.color('#ffffff')
        });
        
        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 1,
                strokeWidth: 0.2
            })
        }));
        let yRenderer = yAxis.get('renderer');
        yRenderer.grid.template.setAll({
            stroke: am5.color('#ffffff')
        });

        let series:any = [];

        for(var i=0; i<= header.length-1 ; i++){
            // debugger;
            series[i] = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: header[i],
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: header[i],
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {
                    labelText: `${header[i]} : {valueY}`
                })
            }));
        }        
        
        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal",
            height: 10,
        }));        
        
        let scrollbarX = chart.get("scrollbarX");
        scrollbarX?.thumb.setAll({
            fill: am5.color(0xf5f5f5),
            fillOpacity: 0.1
        });

        // var legend = chart.children.push(
        //     am5.Legend.new(root, {
        //       nameField: "categoryX",
        //       x: am5.percent(35),
        //       y: am5.percent(100),
        //       centerX: am5.percent(0),
        //       centerY: am5.percent(0)
        //     })
        // );
        // legend.data.setAll(chart.series.values);

        // console.log(data , "fflfs data example");
        for(var i=0; i<= header.length-1 ; i++){
            series[i].data.setAll(data1);
            series[i].appear(1000);
        }
        
        

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

        var watermark = am5.Label.new(chartRefColumn.current , {
            text: "Source: [bold]Akseer Research [/]",
            centerX: am5.percent(25),
            centerY: am5.percent(-1000),
            textAlign: "center",
            opacity: 0.5
        })
        chart.plotContainer.children.push(watermark);

    }

    const downLoadImage = () => {
        if(destroyColumn === true){
            var exporting = Exporting.new(chartRefColumn.current, {
                pngOptions: {
                    minWidth: 500,
                    maxWidth: 991,
                },
            });
            console.log(exporting.exportImage , " export data image uri");
            exporting.download("png");
        }
    }

    return (
        <>
        <div className='column-chart-block col-12 p-0'>
            <div className='title-block'>
                <h4>
                    <span>Bar Chart</span>
                    <ul className='list-unstyled m-0 d-flex flex-wrap justify-content-end'>
                        {/* <li><span><img src={shareIcon} alt="share"/></span></li>
                        <li><span><img src={copyIcon} alt="copy"/></span></li> */}
                        <li><span onClick={downLoadImage}><img src={downloadIcon} alt="download"/></span></li>
                    </ul>
                </h4>
            </div>
            <div className='col-12 px-2 data-block d-flex flex-wrap justify-content-between'>
                <div id="columnChart" className='column-chart'>
                
                </div>
            </div>
        </div>
        </>
    )
}

export default ColumnChart;