import React from "react";

const ProjectAssign = (props) => {
  // console.log(props.assign , "props assign");
  return (
    <>
      <div className="d-flex flex-wrap w-100">
        {props.allProjects &&
          props.allProjects.length > 0 &&
          props.allProjects.map((project, index) => (
            <div
              className="d-inline-flex flex-wrap project-filter-main"
              key={index}
            >
              <div className="project form-group">
                <label className="d-inline-flex w-100">
                  {typeof props.assign !== "undefined" &&
                  project.name in props.assign === true ? (
                    <input
                      type="checkbox"
                      name={`project[${project.name}].project_id`}
                      //   istrue={"true"}
                      //   projectname={project.name}
                      value={project.id.toString()}
                      //   project={index}
                      defaultChecked={true}
                      onChange={props.handleCheck}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name={`project[${project.name}].project_id`}
                      //   istrue={"false"}
                      //   projectname={project.name}
                      value={project.id.toString()}
                      //   project={index}
                      onChange={props.handleCheck}
                    />
                  )}
                  {project.name}
                </label>
              </div>
              <div className="access-options w-100">
                {typeof props.assign !== "undefined" &&
                  project.role &&
                  project.role.length > 0 &&
                  project.role.map((role, i) => (
                    <label className="d-inline-flex w-100" key={i}>
                      {project.name === true &&
                      role.id ===
                        JSON.parse(props.assign[project.name].role_id) ? (
                        <input
                          className={"proj-" + index}
                          type="radio"
                          name={`role[${project.name}].role_id`}
                          // project={project.name}
                          // projectid={project.id.toString()}
                          value={role.id.toString()}
                          id={project.index}
                          defaultChecked={true}
                          onChange={props.handleRadio}
                        />
                      ) : project.name in props.assign === true ? (
                        <input
                          className={"proj-" + index}
                          type="radio"
                          name={`role[${project.name}].role_id`}
                          // project={project.name}
                          // projectid={project.id.toString()}
                          value={role.id.toString()}
                          id={project.index}
                          onChange={props.handleRadio}
                          defaultChecked={false}
                        />
                      ) : (
                        <input
                          className={"proj-" + index}
                          type="radio"
                          name={`role[${project.name}].role_id`}
                          // project={project.name}
                          // projectid={project.id.toString()}
                          value={role.id.toString()}
                          id={project.index}
                          disabled
                          onChange={props.handleRadio}
                        />
                      )}
                      {role.role}
                    </label>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default ProjectAssign;
