import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import MainLayout from "../MainLayout/MainLayout";
// import { columns, data } from "./Data";

import "./UserLogTable";
import axios from "axios";

export default function UserLogTable( {data} ) {

  const columns = [
    {
      name: "Name",
      selector: "user",
      sortable: true,
    },
    {
      name: "IP",
      selector: "user_ip",
      sortable: true,
    },
    {
      name: "Detail",
      selector: "detail",
      sortable: true,
    },
    {
      name: "project",
      selector: "project",
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      selector: "action",
    },
    {
      name: "Module",
      sortable: true,
      selector: "module",
    },
    {
      name: "Status",
      sortable: true,
      selector: "status",
    },
    {
      name: "Created At",
      sortable: true,
      selector: 'created_at',
      cell: (d) => {
        return d.created_at
      }
    },
    {
      name: "Updated At",
      sortable: true,
      selector: 'updated_at',
      cell: (d) => {
        return d.updated_at
      }
    }
  ];

  // const tableData = {
  //   columns,
  //   log
  // };


  const tableData = {
    columns,
    data
  };

  const handleClick = (title) => {
    console.log(`You clicked me! ${title}`);
  };

  console.log("TableData ======>", tableData)
  console.log("Log ======>", data)
  console.log("Columns ======>", columns)
  
  // useEffect(() => {
  //   axios.get("https://brain.akseerresearch.com/api/activitylog")
  //     .then((res) => {
  //       console.log("Log Data ======>", res.data)
  //       setLog(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [])

  return (
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
  );
}

