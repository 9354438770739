import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layout/MainLayout/MainLayout";
import UpcomingBirthdays from "./components/UpcomingBirthdays/UpcomingBirthdays";
import UpcomingEvents from "./components/UpcomingEvents/UpcomingEvents";
import KeyUpdatesCategories from "./sections/KeyUpdatesCategories/KeyUpdatesCategories";
import AddKeyUpdateCategory from "./components/AddKeyUpdateCategory/AddKeyUpdateCategory";
import KeyUpdates from "./sections/KeyUpdates/KeyUpdates";
import Button from "react-bootstrap/Button";
import "./Style.css";

export default function Index() {
  const [categoryAddModal, setCategoryAddModal] = useState<boolean>(false);
  let [count, setCount] = useState<number>(0);

  const history = useNavigate();

  // -------------- Show and Hide Modal Logic --------------------

  const handleAddModalShow = () => {
    setCategoryAddModal(!categoryAddModal);
  };

  const addKeyUpdates = () => {
    history("./addkeyupdate");
  };

  return (
    <MainLayout>
      <div className="d-inline-flex flex-wrap w-100 keyupdate-main">
        <div className="d-inline-flex w-100 add-key-filter">
          <Button
            variant="light"
            name="addcategory"
            className="add-category"
            onClick={handleAddModalShow}
          >
            + Add Category
          </Button>
          <Button
            variant="primary"
            name="addkeyupdates"
            className="add-keyupdates"
            onClick={addKeyUpdates}
          >
            + Add Key Update
          </Button>
        </div>
        <div className="d-inline-flex flex-wrap justify-content-between w-100">
          <div className="col-lg-9 mb-lg-0 mb-4 pl-0">
            <KeyUpdates />
          </div>
          <div className="col-lg-3 pr-lg-0 p-0">
            <KeyUpdatesCategories setCount={setCount} count={count} />
            <UpcomingEvents />
            <UpcomingBirthdays />
          </div>
        </div>
      </div>

      <AddKeyUpdateCategory
        addModalShow={categoryAddModal}
        handleAddModalShow={handleAddModalShow}
        setCount={setCount}
        count={count}
      />
    </MainLayout>
  );
}
