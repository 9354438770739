import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/SelectField/SelectField";
import { rolAddAPI } from "../../../../api/ApiList";
import { toast } from "react-toastify";

// import '../../helper';
const RoleAddModal = ({
  addModalShow,
  closeModal,
  setData,
  data,
  ...props
}) => {
  const [able, setAble] = useState(false);
  // let history = useHistory();
  ////validation////////
  const validate = Yup.object({
    role: Yup.string().required("Role is required"),
    project: Yup.string().required("Project is required"),
  });
  ////end-validation////////

  // console.log(projectList);
  return (
    <>
      <Modal
        className="table-modal add-project-modal"
        show={addModalShow ? true : false}
        centered
      >
        <Modal.Header>
          <Modal.Title
            className="text-left d-inline-block w-100"
            onClick={closeModal}
          >
            Add Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Formik
              initialValues={{
                role: "",
                project: "",
              }}
              validationSchema={validate}
              onSubmit={async (values, { setFieldError }) => {
                setAble(true);
                // debugger;
                await axios({
                  headers: { "Content-Type": "aplication/json" },
                  method: "post",
                  url: `${rolAddAPI}`,
                  data: values,
                })
                  .then(function (response) {
                    setData((data) => [response.data.record, ...data]);
                    toast.success("Role Added Succesfully!");
                    closeModal();
                    setAble(false);
                  })
                  .catch(function (error) {
                    setAble(false);
                    var result = error.response.data.error;
                    // console.log(result);
                    Object.keys(result).map(function (key) {
                      setFieldError(key, result[key][0]);
                    });
                  });
              }}
            >
              {(formik) => (
                <>
                  <Form>
                    <SelectField
                      label="Project"
                      name="project"
                      placeholder="Select a Project"
                      projectlist={props.projectlist}
                    />
                    <InputField
                      type="text"
                      label="Role"
                      name="role"
                      placeholder="Enter Role Name"
                    />
                    <div className="form-group d-inline-flex justify-content-end w-100 position-relative">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={able}
                        name="submit"
                        style={{ background: "#3969d5" }}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-light btn-cancel ml-2"
                        name="cancel"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RoleAddModal;
