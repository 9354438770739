import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ManageUserTable from "./components/UserTable/ManageUserTable";
import AssignUser from "./components/AssignUser/AssignUser";
import {
  DoDecryption,
  FindRequiredPermission,
} from "../../../../AuthFunctions";

export default function ManageUser({
  userHasRoles,
}): React.FunctionComponentElement<Element> {
  const [assignModalShow, setAssignModalShow] = useState<boolean>(false);
  const [submitFlag, setSubmitFlag] = useState(false);
  // const [userHasRoles, setUserHasRoles] = useState(false);

  // useEffect(() => {
  //   setUserHasRoles(
  //     DoDecryption(localStorage.getItem("UserAuth")).authorization
  //   );
  // }, []);

  console.log("User Has Roles in User Managesntd ====>", userHasRoles);

  const showModal = () => {
    setAssignModalShow(true);
    setSubmitFlag(false);
  };
  return (
    <div className="manage-user-table container-fluid mt-3 px-0">
      <div
        className="d-inline-flex w-100 assign-form-btn my-3"
        style={styles.assignParent}
      >
        {FindRequiredPermission(userHasRoles, "Ask Analyst", "Approver") ||
        FindRequiredPermission(userHasRoles, "Ask Analyst", "Admin") ? (
          <button style={styles.assignBtn} className="btn" onClick={showModal}>
            + Assign User
          </button>
        ) : (
          <></>
        )}
      </div>
      <Card>
        <Card.Header>
          <h4>Manage Users</h4>
        </Card.Header>
        <Card.Body>
          <ManageUserTable
            userHasRoles={userHasRoles}
            assignModalShow={assignModalShow}
          />
        </Card.Body>
      </Card>
      <AssignUser
        submitFlag={submitFlag}
        setSubmitFlag={setSubmitFlag}
        setAssignModalShow={setAssignModalShow}
        assignModalShow={assignModalShow}
      />
    </div>
  );
}

const styles = {
  assignParent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  assignBtn: {
    border: "0.25px solid rgb(57 109 213 / 30%)",
    background: "#ffffff",
    fontFamily: "'Lato', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    color: "#3969d5c7",
    borderRadius: "4px",
  },
};
