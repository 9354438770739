import { ActionTypes } from "../constants/ActionTypes"

const inititalState = {
    isAuthenticated: false,
}

export const authReducer = (state = inititalState, {type, payload}) => {

    if ( type == ActionTypes.SET_AUTH ) {
        localStorage.setItem("auth", JSON.stringify(payload));
        localStorage.setItem("isAuthenticated", true);
        return { ...state, auth: payload, isAuthenticated: true };
    }

    if (type == ActionTypes.GET_AUTH ) {
        const auth = JSON.parse(localStorage.getItem('auth'));
        return {...state, auth: auth};
    }
      
    return state;
};