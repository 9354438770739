import React, { CSSProperties } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import * as CSS from "csstype";
const EditPencilIcon = require("../../../../assets/media/pencil.svg").default;
const TrashIcon = require("../../../../assets/media/trash.svg").default;

export default function FileUploadDropdown({
  projectImage,
  getProjectImage,
}): any {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList as never[]);
    getProjectImage(imageList[0]);
  };
  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <button
              style={
                isDragging ? styles.defaultBoxStyle : styles.defaultBoxStyle
              }
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>
            &nbsp;
            {imageList.map((image, index) => (
              <div
                key={index}
                style={styles.imageContainer}
                className="image-item"
              >
                <img src={image.dataURL} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button className="btn" onClick={() => onImageUpdate(index)}>
                    <img src={EditPencilIcon} alt="Edit Pencile Icon" />
                  </button>
                  <button className="btn" onClick={() => onImageRemove(index)}>
                    <img src={TrashIcon} alt={"delete icon"} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  defaultBoxStyle: {
    width: "100%",
    height: "55px",
    border: "1px dashed",
    borderRadius: "4px",
    background: "#eaf1ff",
    color: "#0000005c",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
};
