import React, { useState } from 'react';
import PatternHeader from '../../PatternContent/patternheader';
import PatternFooter from '../../PatternContent/patternfooter';

const Disclaimer = ({handleRemoveDisclaimer , instanceObject, ...props}) => {
    return (
        <>
            <div className="auto-volume-p1">
                <button className="removebtn" type="button" onClick={handleRemoveDisclaimer}>
                    <svg width="20px" height="20px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-times fa-w-10">
                        <path fill="#ffffff" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" className=""></path>
                    </svg>
                </button>
                <PatternHeader InstanceObj={instanceObject} InstanceIndex={instanceObject.patNo}/>
                <section className="body-content-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body-title">
                                    <h3>Disclaimer</h3>
                                </div>
                                <div className="body-content">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <div className="col-lg-12 p-0">
                                            <div className="disclaimer-content">
                                                <p className="mt-0">This report has been prepared and marketed jointly by Akseer Research (Pvt) Limited and Alfa Adhi Securities (Pvt) Limited, hereinafter referred jointly as “JV” and is provided for information purposes only. Under no circumstances this is to be
                                                    used or considered as an offer to sell or solicitation of any offer to buy. While reasonable care has been taken to ensure that the information contained therein is not untrue or misleading at the time of publication, we make no representation as
                                                    to its accuracy or completeness and it should not be relied upon as such. From time to time, the JV and/or any of their officers or directors may, as permitted by applicable laws, have a position, or otherwise be interested in any transaction, in any
                                                    securities directly or indirectly subject of this report. This report is provided only for the information of professionals who are expected to make their own investment decisions without undue reliance on this report. Investments in capital markets
                                                    are subject to market risk and the JV accepts no responsibility whatsoever for any direct or indirect consequential loss arising from any use of this report or its contents. In particular, the report takes no account of the investment objectives,
                                                    financial situation and particular needs of investors, who should seek further professional advice or rely upon their own judgment and acumen before making any investment. The views expressed in this report are those of the JV’s Research
                                                    Department and do not necessarily reflect those of the JV or its directors. Akseer Research and Alfa Adhi Securities as firms may have business relationships, including investment‐-banking relationships, with the companies referred to in this
                                                    report. The JV or any of their officers, directors, principals, employees, associates, close relatives may act as a market maker in the securities of the companies mentioned in this report, may have a financial interest in the securities of these
                                                    companies to an amount exceeding 1% of the value of the securities of these companies, may serve or may have served in the past as a director or officer of these companies, may have received compensation from these companies for corporate
                                                    advisory services, brokerage services or underwriting services or may expect to receive or intend to seek compensation from these companies for the aforesaid services, may have managed or co-managed a public offering, take-over, buyback,
                                                    delisting offer of securities or various other functions for the companies mentioned in this report.</p>
                                                <h6 className="sub-title">Valuation Methodology</h6>
                                                <p>To arrive at our 12-months Price Target, the JV uses Discounted Cash flow (DCF) method, with a risk-free rate of 13%, beta of 1.0 & market risk premium of 6% to arrive at cost of equity of 19%.</p>
                                                <h6 className="sub-title">Key Risks</h6>
                                                <p>Key downside risks to our investments thesis are: 1) accumulation of circular debt on LNG front, 2) higher than projected FO sales which may lead to accumulation of receivables
                                                    And 3) circular debt not being paid off completely</p>
                                                <h6 className="sub-title">Ratings Criteria</h6>
                                                <p>JV employs a three-tier ratings system to rate a stock, as mentioned below, which is based upon the level of expected return for a specific stock. The rating is based on the following with time horizon of 12-months.</p>
                                                <ul className="list-unstyled m-0">
                                                    <li>
                                                        <p><span>Rating</span> Expected Total Return</p>
                                                    </li>
                                                    <li>
                                                        <p><span>Buy</span> Greater than or equal to +15%</p>
                                                    </li>
                                                    <li>
                                                        <p><span>Hold</span> Between -5% and +15%</p>
                                                    </li>
                                                    <li>
                                                        <p><span>Sell</span> less than or equal to -5%</p>
                                                    </li>
                                                </ul>
                                                <p>Ratings are updated to account for any development impacting the economy/sector/company, changes in analysts’ assumptions or a combination of these factors.</p>
                                                <h6 className="sub-title">Research Dissemination Policy</h6>
                                                <p>The JV endeavours to make all reasonable efforts to disseminate research to all eligible clients in a timely manner through either physical or electronic distribution such as email, fax mail etc.</p>
                                                <h6 className="sub-title">Analyst Certification</h6>
                                                <p>The research analyst, denoted by ‘AC’ on the cover of this report, has also been involved in the preparation of this report, and is a member of JV’s Equity Research Team. The analyst certifies that (1) the views expressed in this report accurately reflect his/her personal views and (2) no part of his/her compensation was, is or will be directly or indirectly related to the specific recommendations or views expressed in this report</p>
                                                <h6 className="sub-title">Contact Details</h6>
                                                <div className="contact-block d-flex flex-wrap">
                                                    <div className="col-md-4 akseer-contact pl-0">
                                                        <h6 className="sub-title black">Akseer Research (Pvt) Limited</h6>
                                                        <ul className="list-unstyled m-0">
                                                            <li>
                                                                <p>1st floor, Shaheen chambers, A-4 central commercial area, KCH society block 7 & 8, Karachi</p>
                                                            </li>
                                                            <li>
                                                                <p><span>T:</span> +92-21-34320358</p>
                                                            </li>
                                                            <li>
                                                                <p><span>E:</span> info@akseerresearch.com</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-4 alfa-contact pl-0">
                                                        <h6 className="sub-title black">Alfa Adhi Securities (pvt) Ltd</h6>
                                                        <ul className="list-unstyled m-0">
                                                            <li>
                                                                <p>3rd floor, Shaheen chambers, A-4 central commercial area, KCH society block 7 & 8, Karachi</p>
                                                            </li>
                                                            <li>
                                                                <p><span>T:</span> +92-21-38694242</p>
                                                            </li>
                                                            <li>
                                                                <p><span>E:</span> info@alfaadhi.net</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PatternFooter/>
            </div>
        </>
    )
}

export default Disclaimer;
