import React from "react";
import Modal from "react-bootstrap/Modal";
import sessionIcon from "../../assets/media/sessionIcon.svg";

const SessionExpire = ({ sessionModal, closeModal, sessionMessage }) => {
  return (
    <>
      <Modal
        style={styles.modalBackdrop}
        className="table-modal session-modal"
        show={sessionModal ? true : false}
        centered
      >
        <Modal.Header style={styles.modalHeader}>
          <Modal.Title
            style={styles.modalTitle}
            className="text-left d-inline-block w-100"
          >
            Session Timeout
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalBody}>
          <div
            style={styles.sessionAlertContent}
            className="session-alert-content"
          >
            <p style={styles.para}>
              <span style={styles.iconSpan}>
                <img src={sessionIcon} />
              </span>{" "}
              {sessionMessage}
            </p>
            <button onClick={closeModal} style={styles.buttonOK}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SessionExpire;

const styles = {
  modalBackdrop: {
    borderRadius: "20px",
    zIndex: "9999999",
  },
  modalHeader: {
    padding: "15px 25px",
    fontWeight: "700",
    letterSpacing: "0.3px",
    color: "#3d3d3d",
    borderBottom: "var(--light-border)",
  },
  modalTitle: {
    color: "#3d3d3d",
    fontWeight: "700",
    fontSize: "18px",
    textTransform: "capitalize",
  },
  modalBody: {
    padding: "10px 15px 50px",
    background: "#fff",
  },
  sessionAlertContent: {
    position: "relative",
  },
  para: {
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
  },
  iconSpan: {
    width: "50px",
    borderRadius: "50%",
    height: "50px",
    alignItems: "center",
    display: "inline-flex",
    fontSize: "50px",
    justifyContent: "center",
    color: "#ffff",
    margin: "0",
  },
  buttonOK: {
    position: "absolute",
    width: "70px",
    right: "15px",
    border: "none",
    textTransform: "uppercase",
    background: "#3969d5",
    color: "#fff",
    fontWeight: "400",
    fontSize: "14px",
    borderRadius: "4px",
    padding: "4px 10px",
  },
};
