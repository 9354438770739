import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import { useCookies } from "react-cookie";
import bellIcon from "../../../../assets/media/bell.svg";

type AppProps = {
  notify: boolean;
  setNotify: Function;
  notifications: any;
  notificationRequest: Function;
  notifyOnClick: Function;
};

const GlobalNotification = ({
  notify,
  setNotify,
  notifications,
  notificationRequest,
  notifyOnClick,
}: AppProps) => {
  const [hideBlinker, setHideBlinker] = useState<boolean>(true);
  const [cookies, setCookies] = useCookies<any>();

  useEffect(() => {
    const pusher = new Pusher("cf736425b7e4224a6bb2", {
      cluster: "ap2",
      forceTLS: true,
      // encrypted: "true",
    });
    const channel = pusher.subscribe("financial-updates-channel");
    channel.bind("financial-updates", (data) => {
      setNotify(true);
    });
  }, []);

  useEffect(() => {
    notificationRequest();
  }, [notify]);

  const notifyClick = (e) => {
    notifyOnClick(e);
  };

  const notifyList = notifications?.map((item: any) => (
    <li>
      <span
        data-compId={item.company_id}
        data-userId={item.user_id}
        data-type={item.type}
        data-period={item.period}
        onClick={(e) => notifyClick(e)}
      >
        {item.message}
      </span>
    </li>
  ));

  return (
    <div
      className={`main-blinker ${hideBlinker ? "hide" : ""}`}
      onMouseLeave={() => {
        setHideBlinker(!hideBlinker);
      }}
    >
      <p
        className=""
        onClick={() => {
          setHideBlinker(!hideBlinker);
        }}
      >
        <span className="count">
          {notifications !== undefined ? notifications.length : 0}
        </span>
        <span>
          <img src={bellIcon} alt="bell" />
        </span>
        {!hideBlinker ? "New Approval Request" : ""}
      </p>
      <ul className="list-unstyled m-0">{notifyList}</ul>
    </div>
  );
};
export default GlobalNotification;
