import React from "react";
import UserImgForm from "./userImg";
import UserDataForm from "./userData";
import "./Profile.css";

export default function Profile() {
  return (
    <div className="user-profile-main">
      <div className="title">
        <h3>User Profile</h3>
      </div>
      <UserImgForm />
      <UserDataForm />
    </div>
  );
}
