import React from 'react';

const PatternOne = ({getPattern, pat_id, ...props}) => {
    var parseJsons = JSON.parse(props.patternJson);
    var tempCount = 0;

    return (
        <>
            <div key={pat_id + "-" + (tempCount + 1)} id={pat_id + tempCount} className="c-temp-item">
                <div className="temp-head">
                    <h6>Header</h6>
                </div>
                <div className="temp-content">
                    {parseJsons[pat_id].columns.map((column , index) => (
                        <div key={pat_id + "-" + index} className={`d-inline-flex flex-wrap align-items-center h2-100 w2-` + column.width}>

                            {column.row.map((row_ , index) => (
                                <>
                                    {/* {console.log(index + "row")} */}
                                    <div key={index + "-" + pat_id} className={`d-inline-flex flex-wrap justify-content-between h2-` + row_.height + ` w2-` + row_.width}>
                                        {row_.columns.map((column , index) => (
                                            <>
                                                {/* {console.log(index + "inner column")} */}
                                                <div key={index + "-" + pat_id + "-" + index} className={`p-bg d-inline-flex flex-wrap mxy-3 he-` + column.height + ` wi-` + column.width}>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </>
                            ))}
                        </div>
                    ),
                    tempCount++
                    )}
                </div>
                <div className="temp-foot">
                    <h6>Footer</h6>
                </div>
                <button id={pat_id} type="" className="add-template" onClick={(e)=>getPattern(e)} data="Lorem Ipsum is a dummy text for testing purpose.">+</button>
            </div>
        </>
    )
}

export default PatternOne;
