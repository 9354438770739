import React, { useState } from 'react'
import './SwitchButton.css';

export default function SwitchButton( {data} ) {
  console.log(data.status , "witch------------------------")
    const [switchValue, setSwitchValue] = useState(true);
    
    return (
    <div className="d-inline-flex">
        <span className='d-inline-flex' style={{color:"grey", fontSize: "12px", marginRight:"10px", display: "flex", alignItems: "center"}}>{JSON.stringify(data.status) === 'true' ? "Active" : "Inactive"}</span>
        <input
            checked={switchValue}
            onChange={() => console.log("value change")}
            onClick={() => setSwitchValue(!switchValue)}
            className="react-switch-checkbox"
            id={data.id}
            type="checkbox"
        />
        <label
            style={{ background: switchValue && '#1D4283' }}
            className="react-switch-label m-0"
            htmlFor={data.id}
        >
            <span className={`react-switch-button`} />
        </label>
    </div>
  )
}
