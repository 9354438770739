import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ListOfRolesData } from "../../Modals/Data";
import "./ViewRolesModal.css";

export default function ViewRolesModal({ show, toggleShow, bodyContent }) {
  const [modalShow, setModalShow] = React.useState(show);

  useEffect(() => {
    console.log("LIST OF ROLES RECIEVED =====>", bodyContent);
  }, [bodyContent]);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter view-roles-modal custom-modal-width"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">View Roles</Modal.Title>
      </Modal.Header>
      <Modal.Body className="view-roles-modal-body">
        <div className="row">
          {bodyContent ? (
            bodyContent.map((b) => {
              // let _permission = JSON.parse(b.permission);
              let _permission = b.permission;
              return (
                <div className="col-4">
                  <div className="permission-container">
                    <div className="permission-heading mb-2">
                      <h6>{b?.role}</h6>
                    </div>
                    <div className="permissions">
                      <ul>
                        {_permission &&
                          _permission.map((p) => {
                            // console.log("PRINTING PERMISSIONNNNSSSSS =====>", p);
                            return (
                              <li>
                                <div className="d-flex">
                                  <span className="mr-2">-</span>
                                  <span>{`${p?.permission_name}`}</span>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h1>Body Not Found</h1>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="form-group d-inline-flex justify-content-end w-100 position-relative">
          <button
            type="submit"
            className="btn btn-primary"
            style={{ background: "#3969d5" }}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-cancel ml-2"
            name="cancel"
            onClick={toggleShow}
          >
            Cancel
          </button>
        </div>
        {/* <Button onClick={toggleShow}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

const styles = {
  permissionContainer: {},
  permisionHeading: {
    border: "1px solid blue",
    borderRadius: "5px",
  },
};
