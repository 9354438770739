import React from "react";
// import TableHeader from '../../TableHeader';
import DataTable from "react-data-table-component";
import SwitchButton from "../../../../components/SwitchButton/SwitchButton";
import { RegisterationListData as data } from "../../../../Modal/Modal";
import "./UserTable.css";

const columns = [
  {
    name: <span className="font-bold">{`S.no`}</span>,
    selector: (row) => row.id,
  },
  {
    name: <span className="font-bold">Date of Registration</span>,
    selector: (row) => row.registeration,
  },
  {
    name: <span className="font-bold">Name</span>,
    selector: (row) => row.name,
  },
  {
    name: <span className="font-bold">Email</span>,
    selector: (row) => row.email,
  },
  {
    name: <span className="font-bold">Phone</span>,
    selector: (row) => <p className="m-0">021-123123</p>,
  },
  {
    name: <span className="font-bold">Lead</span>,
    selector: (row) => <p className="m-0">Facebook</p>,
  },
  {
    name: <span className="font-bold">Action</span>,
    selector: (row) => <SwitchButton data={row} />,
  },
];

export default function UserTable() {
  const DateFilter = () => {
    return (
      <div>
        <input className="form-control" type="date" />
      </div>
    );
  };

  const SearchFilter = () => {
    return (
      <div>
        <input
          className="form-control search-filter"
          placeholder="Search by name . . ."
          type="text"
        />
      </div>
    );
  };

  const PerPageFilter = () => {
    return (
      <select
        style={{ width: "60px" }}
        className="ml-2 form-control"
        name="perPage"
        id="1"
      >
        <option value="1">5</option>
        <option value="1">10</option>
        <option value="1">15</option>
        <option value="1">20</option>
      </select>
    );
  };

  const TableHeader = () => {
    return (
      <div style={styles.tableHeader}>
        <DateFilter />
        <div style={styles.flex}>
          <SearchFilter />
          <PerPageFilter />
        </div>
      </div>
    );
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        pagination
        subHeader
        subHeaderComponent={<TableHeader />}
      />
    </>
  );
}

// ======================================
//              STYLINGS
// ======================================

const styles = {
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  downloadCSVParent: {
    width: "100%",
    display: "flex",
    /* justify-content: center; */
    alignItems: "center",
    flexDirection: "row-reverse",
  },
};
