import React from "react";
import "./PermissionInlineList.css";

export default function PermissionInlineList({ data }) {
  console.log("Data inside permission Inline List", data.permission_name);
  return (
    <div className="inline-permission-list my-2">
      {data.map((d) => {
        console.log(d);
        return (
          <div className="inline-permission-list-item">{d.permission_name}</div>
        );
      })}

      {/* {create && create === 1 ? (
        <div className="inline-permission-list-item">Create</div>
      ) : (
        ""
      )}
      {read && read === 1 ? (
        <div className="inline-permission-list-item">Read</div>
      ) : (
        ""
      )}
      {update && update === 1 ? (
        <div className="inline-permission-list-item">Update</div>
      ) : (
        ""
      )}
      {delete_permission && delete_permission === 1 ? (
        <div className="inline-permission-list-item">Delete</div>
      ) : (
        ""
      )} */}
    </div>
  );
}
