import { cos } from "@amcharts/amcharts5/.internal/core/util/Math";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import NewCompanyAssignForm from "../NewCompanyAssignForm/NewCompanyAssignForm";
import CompanySelectInput from "./CompanySelectInput";
import Loader from "../../../../../../assets/loader-small.gif";
import "./EditAssignUser.css";
import NewAssignForm from "./NewAssignForm";

export default function EditAssignUser({
  setEditShow,
  editShow,
  editDataId,
  createPayload,
  setPayloadUserId,
  payload,
  createDefaultPayload,
  defaultPayload,
  handleDefaultPayload,
}) {
  const [show, setShow] = useState(false);

  const [formInstances, setFormInstances] = useState<any>([{}]);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [editUserSectorList, setEditUserSectorList] = useState<any>([]);
  const [listOfCompanies, setListOfCompanies] = useState<any>([]);
  const [listOfSectors, setListOfSectors] = useState<any>([]);
  const [newComapniesAssign, setNewCompaniesAssign] = useState<any>([]);
  // const [payload, setPayload] = useState<any>([]);
  const [newPayload, setNewPayload] = useState<any>();
  const [assignCompanyState, setAssignCompanyState] = useState<any>([]);
  const [listOfCompaniesAndSectors, setListOfCompaniesAndSectors] =
    useState<any>([]);
  const [_listOfSectors, _setListOfSectors] = useState<any>([]);
  const [listOfComapniesBySector, setListOfCompaniesBySectors] = useState<any>(
    []
  );
  const [defaultCompanies, setDefaultCompanies] = useState<any>([]);

  const createSectorList = (): any => {
    const tempArr: any = [];
    listOfCompaniesAndSectors.map((cs: any, i): any => {
      tempArr.push({
        label: listOfCompaniesAndSectors[i].sector,
        value: listOfCompaniesAndSectors[i].id,
      });
    });

    return tempArr;
  };

  // const createPayload = (value) => {
  //   if (payload.find((f) => f.sector === value.sector)) {
  //     console.log("object already exist");
  //   }
  //   setPayload([...payload, value]);
  // };

  const createNewPayload = (value) => {
    setNewPayload({ ...newPayload, value });
  };

  const createDefaultCompanies = (values) => {
    setDefaultCompanies([...defaultCompanies, ...values]);
    console.log(defaultCompanies);
  };

  // ================= TEST =======================

  // const createPayload = (value) => {
  //   console.log("Value Recieved ---->", value);
  //   const newPayload: any = payload;

  //   if (payload.length === 0) {
  //     console.log("Adding value for first time");
  //     setPayload([...payload, value]);
  //   } else {
  //     for (let i = 0; i <= payload.length; i++) {
  //       if (newPayload[i].sector_id === value.sector_id) {
  //         console.log("Replacing Value");
  //         newPayload[i] = value;
  //         setPayload(newPayload);
  //         return true;
  //       } else {
  //         console.log("Adding single value  ");
  //         return setPayload([...payload, value]);
  //       }
  //     }
  //   }
  // };

  const assignCompanies = (companies) => {
    let _prevCompanies = assignCompanyState;
    let compId: Array<Number> = [];
    let _companies = companies.map((c) => {
      // console.log("Single Company ID", c.value);
      compId.push(c.value);
    });

    setAssignCompanyState(() => {
      return compId.concat(assignCompanyState);
    });

    const unique = assignCompanyState.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    // console.log("Final CompId Array ====>", unique);
    return unique;
  };

  // function onlyUnique(value, index, self) {
  //   return self.indexOf(value) === index;
  // }

  // // usage example:
  // var a = ["a", 1, "a", 2, "1"];
  // var unique = a.filter(onlyUnique);

  // console.log(unique); // ['a', 1, 2, '1']

  // ================= TEST =======================

  const createSelectArray = (arr) => {
    // console.log("PROBLEM IS HERE =====>", arr);

    var tempArr: any = [];
    if (arr) {
      for (let i = 0; i <= arr.length; i++) {
        // console.log(arr[i]?.names);
        // console.log(arr[i]?.id);
        tempArr.push({ label: arr[i]?.name, value: arr[i]?.id });
      }
    }
    // console.log("THE ARRAY ====>", tempArr);
    return tempArr;
  };

  const createDefaultSectorList = (arr) => {
    let tempArr: any = [];
    arr.map((d) => {
      //   console.log("Pushing ===>", { value: d.id, label: d.sector });
      tempArr.push({ value: d.id, label: d.sector });
    });
    return tempArr;
  };

  const fetchListOfCompanies = (sectorId) => {
    let tempArr: any = [];
    axios
      .post(
        "https://brain.akseerresearch.com/api/askanalyst/sector/companies",
        { sec_id: sectorId }
      )
      .then((res) => {
        res.data[0]?.companies.map((d) => {
          //   console.log("from map function =====>", d);
          tempArr.push({ value: d.id, label: d.name });
        });
      })
      .catch((err) => console.log(err));
    // console.log("LIST OF ALLL COMPANIES ========>", tempArr);
    return tempArr;
  };

  useEffect(() => {
    setPayloadUserId(editDataId);

    axios
      .get(
        `https://brain.akseerresearch.com/api/askanalyst/users/${editDataId}`
      )
      .then((res) => {
        setSelectedUser(res.data);
        // console.log("User Payload ====>", res.data);

        // let defaultValues: any = [];
        // console.log("The Actual Object =====>", res.data.data);

        // res?.data?.data.map((dr, i) => {
        //   dr?.companies.map((c) => {
        //     defaultValues.push({ label: c.name, value: c.id });
        //   });
        // });

        // createDefaultPayload(defaultValues, editDataId);
      })
      .catch((err) => console.log(err));

    axios
      .get(`https://brain.akseerresearch.com/api/askanalyst/sectors`)
      .then((res) => {
        setEditUserSectorList(createDefaultSectorList(res.data));
        setListOfSectors(createDefaultSectorList(res.data));
      });

    axios
      .post(`https://brain.akseerresearch.com/api/askanalyst/sector/companies`)
      .then((res) => {
        let tempArr: any = [];
        res.data[0]?.companies.map((d) => {
          //   console.log("from map function =====>", d);
          tempArr.push({ value: d.id, label: d.name });
        });
        // console.log("LIST OF COMPANIES ====>", res.data[0]?.companies);
        setListOfCompanies(createSelectArray(tempArr));
        // console.log("LIST OF COMAPANIES AFTER FILTER ===>", listOfCompanies);
      })
      .catch((err) => console.log(err));

    // ------------ List of companies new api

    axios
      .get(`https://brain.akseerresearch.com/api/askanalyst/sectorandcompanies`)
      .then((res) => {
        // console.log("New List of Companies API ========>", res.data);
        setListOfCompaniesAndSectors(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [editDataId]);

  //   ========================  LOGIC TEST ===================================

  // ---------------- Remove same object algorithm --------------------

  function removePreSelectedValues(a, b) {
    // console.log("OBJECT A RECIEVED AS ==>", a);
    // console.log("OBJECT B RECIEVED AS ==>", b);
    for (var i = 0, len = a.length; i < len; i++) {
      for (var j = 0, len2 = b.length; j < len2; j++) {
        if (a[i].value === b[j].value) {
          b.splice(j, 1);
          len2 = b.length;
        }
      }
    }
    return b;
  }

  // ------------------------- return difference of two arrays -----------------------

  // const arr1 = [
  //   { id: 1, name: "Tom" },
  //   { id: 2, name: "John" },
  // ];
  // const arr2 = [{ id: 1, name: "Tom" }];

  // function getDifference(array1, array2) {
  //   return array1.filter((object1) => {
  //     return !array2.some((object2) => {
  //       return object1.id === object2.id;
  //     });
  //   });
  // }

  // // 👇️ [{id: 2, name: 'John'}]
  // console.log(getDifference(arr1, arr2));

  //   ========================  LOGIC TEST ===================================

  const EditForm = () => {
    return (
      <>
        <div className={`instance_no_`}>
          <div className="form-group">
            <label htmlFor="">Select User Name</label>
            <select className="form-control" disabled>
              <option>{selectedUser?.name}</option>
            </select>
          </div>
          {selectedUser?.data?.map((d) => {
            // console.log("new format ====>", d);

            const createListOfCompaniesBySector = (sector_id): any => {
              const tempArr: any = [];
              const companies: any = listOfCompaniesAndSectors.find(
                (d: any) => d.id === sector_id
              );

              companies?.company?.map((c, i): any => {
                tempArr.push({
                  label: companies?.company[i].name,
                  value: companies?.company[i].id,
                });
              });
              // console.log("Companies Found ====> ", companies);

              // console.log("Companies Found ====> ", tempArr);
              // setListOfCompaniesBySectors(tempArr);
              return tempArr;
            };

            return (
              <>
                <div className="form-group">
                  <label htmlFor="">Select Sector</label>
                  <select disabled className="form-control">
                    <option className="form-control">{d?.sector_name}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="">Select Company</label>

                  {d.companies !== undefined ? (
                    <>
                      <Select
                        onFocus={(value) => {
                          // alert(value);
                          // console.log("FROM ON FOCUSS ======>", value);
                        }}
                        defaultValue={() => {
                          alert("must run on defalt");
                          createPayload(
                            JSON.stringify(
                              assignCompanies(createSelectArray(d.companies))
                            ).replace(/[\])}[{(]/g, "")
                          );
                          handleDefaultPayload(createSelectArray(d.companies));
                          createDefaultCompanies(
                            createSelectArray(d.companies)
                          );
                          return createSelectArray(d.companies);
                        }}
                        options={createListOfCompaniesBySector(d?.sector_id)}
                        onChange={(value) => {
                          console.log("Triggering OnChange Event ====>", value);
                          createPayload(
                            JSON.stringify(assignCompanies(value)).replace(
                              /[\])}[{(]/g,
                              ""
                            )
                          );
                        }}
                        isMulti
                      />
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          })}

          {selectedUser[0]?.sector_companies?.map((a) => {
            // console.log("The Sector ID ===>", a.sector_id);
            // fetchListOfCompanies(a.sector_id);
            return (
              <>
                <div className="form-group">
                  <label htmlFor="">Select Sector</label>
                  <select disabled className="form-control">
                    <option className="form-control">{a.sector_name}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="">Select Company</label>

                  {a.company_name !== undefined ? (
                    <>
                      <Select
                        defaultValue={() => {
                          // console.log(
                          //   "THE DEFAULT VALUES ",
                          //   createSelectArray(a.company_name)
                          // );
                          return createSelectArray(a.company_name);
                        }}
                        options={fetchListOfCompanies(a.sector_id)}
                        onChange={(value) => {
                          createPayload(assignCompanies(value));
                        }}
                        isMulti
                      />{" "}
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };

  // const EditForm = () => {
  //   return (
  //     <>
  //       <div className={`instance_no_`}>
  //         <div className="form-group">
  //           <label htmlFor="">Select User Name</label>
  //           <select className="form-control" disabled>
  //             <option>{selectedUser[0]["name"]}</option>
  //           </select>
  //         </div>
  //         {selectedUser[0]?.sector_companies?.map((a) => {
  //           // console.log("The Sector ID ===>", a.sector_id);
  //           // fetchListOfCompanies(a.sector_id);
  //           return (
  //             <>
  //               <div className="form-group">
  //                 <label htmlFor="">Select Sector</label>
  //                 <select disabled className="form-control">
  //                   <option className="form-control">{a.sector_name}</option>
  //                 </select>
  //               </div>
  //               <div className="form-group">
  //                 <label htmlFor="">Select Company</label>

  //                 {a.company_name !== undefined ? (
  //                   <>
  //                     {/* <CompanySelectInput
  //                       defaultValues={createSelectArray(a.company_name)}
  //                       sectorId={a.sector_id}
  //                       values={fetchListOfCompanies(a.sector_id)}
  //                     /> */}
  //                     <Select
  //                       defaultValue={() => {
  //                         console.log(
  //                           "THE DEFAULT VALUES ",
  //                           createSelectArray(a.company_name)
  //                         );
  //                         return createSelectArray(a.company_name);
  //                       }}
  //                       options={fetchListOfCompanies(a.sector_id)}
  //                       // options={removePreSelectedValues(
  //                       //   createSelectArray(a.company_name),
  //                       //   fetchListOfCompanies(a.sector_id)
  //                       // )}
  //                       onChange={(value) => {
  //                         createPayload(assignCompanies(value));
  //                       }}
  //                       isMulti
  //                     />{" "}
  //                     <hr />
  //                   </>
  //                 ) : (
  //                   <></>
  //                 )}
  //               </div>
  //             </>
  //           );
  //         })}
  //       </div>
  //     </>
  //   );
  // };

  const handleEditSubmit = (values) => {
    const formData = new FormData();
    formData.append("user_id", values.user_id);
    formData.append("company_id", values.company_id);

    axios({
      method: "POST",
      url: `https://brain.akseerresearch.com/api/askanalyst/editsectorandcompanies`,
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((r: any) => {
      setEditShow();
      toast.success("Assigned companies updated succesfully");
    });

    //   axios
    //     .post(
    //       `https://brain.akseerresearch.com/api/askanalyst/assign/sector/company/user`,
    //       {
    //         data: formData,
    //       }
    //     )
    //     .then((res) => {
    //       console.log(res);
    //     })

    //     .catch((err) => console.log(err));
  };

  //   console.log("Final Response", selectedUser);

  if (selectedUser) {
    return (
      <div className="">
        <>
          {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button> */}
          <Modal
            className="table-modal edit-assign-user-modal"
            show={editShow}
            onHide={setEditShow}
          >
            <Modal.Header>
              <Modal.Title className="text-left d-inline-block w-100 px-3">
                Edit User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="assign-form-block">
                {selectedUser ? EditForm() : ""}
                {newComapniesAssign.map((n) => {
                  return (
                    <>
                      <NewAssignForm
                        assignCompanies={assignCompanies}
                        listOfCompaniesAndSectors={listOfCompaniesAndSectors}
                        createNewPayload={createNewPayload}
                        createPayload={createPayload}
                      />
                    </>
                  );
                })}

                {/* {newComapniesAssign.map((f) => {
                  return (
                    <>  
                      <NewCompanyAssignForm
                        payload={payload}
                        assignCompanies={assignCompanies}
                        createPayload={createPayload}
                      />
                    </>
                  );
                })} */}

                <div className="form-group d-flex flex-row-reverse">
                  <button
                    className="btn text-primary add-more-btn"
                    onClick={() =>
                      setNewCompaniesAssign([...newComapniesAssign, {}])
                    }
                  >
                    + Assign More
                  </button>
                </div>
                <div className="form-group d-inline-flex justify-content-center w-100 position-relative my-3 px-3">
                  <button
                    type="submit"
                    className="btn btn-primary px-4"
                    onClick={() => {
                      handleEditSubmit(payload);
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      console.log("Current Payload =====>", payload);
                    }}
                  >
                    show payload
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      console.log(
                        "Current default Payload =====>",
                        defaultPayload
                      );
                    }}
                  >
                    show default payload
                  </button>
                  <button
                    type="button"
                    className="btn btn-cancel ml-2 px-4"
                    name="cancel"
                    onClick={() => {
                      setEditShow();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    );
  } else {
    return (
      <div className="manage-user-edit-loader">
        <img src={Loader} />
      </div>
    );
  }
}
