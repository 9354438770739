import React from "react";
import DataTable from "react-data-table-component";
import EditButton from "../../../components/EditButton/EditButton";
import { ProjectUsers as data } from "../../../Modals/Data";

const Loader = require("../../../../../assets/loader.gif").default;

export default function ListOfUsersByProject(): React.FunctionComponentElement<Element> {
  if (data) {
    return (
      <>
        <div className="px-4">
          <div className="filters w-100 mt-5 d-flex flex-row-reverse">
            <div className="d-flex">
              <select className="form-control mr-3">
                <option value="">Ask Analyst Admin</option>
                <option value="">ESG</option>
                <option value="">Ask Analyst</option>
                <option value="">Brain</option>
              </select>
              <select className="form-control">
                <option value="">Ask Analyst Admin</option>
                <option value="">ESG</option>
                <option value="">Ask Analyst</option>
                <option value="">Brain</option>
              </select>
            </div>
          </div>
          <div className="brain-table-card">
            <div className="brain-table-card-header">
              <h3>List of users of ESG</h3>
            </div>
            <div className="brain-table-card-body">
              <DataTable data={data} columns={columns} pagination />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="full-screen-loader">
        <img src={Loader} alt="" />
      </div>
    );
  }
}

const columns = [
  {
    name: "#",
    selector: (row) => {
      return row.id;
    },
  },
  {
    name: "Name",
    selector: (row) => {
      return row.name;
    },
  },
  {
    name: "Email",
    selector: (row) => {
      return row.email;
    },
  },
  {
    name: "Date Added",
    selector: (row) => {
      return row.date;
    },
  },
  {
    name: "Action",
    selector: (row) => {
      return (
        <EditButton onClick={() => console.log("HELLLOOOO FROM GENERIC")} />
      );
    },
  },
];
