import React, { useState, useEffect } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DoDecryption } from "../../../../AuthFunctions";
import { FindRequiredPermission } from "../../../../AuthFunctions";

export default function CompanyIntroductionEditor({
  company,
  initial,
  createPayload,
  submitCompanyInformation,
  submitCompanyInformationDraft,
  sendForApproval,
  approveFinalDraft,
  userHasRoles,
  draftModeFlage,
}: any) {
  const [length, setlength] = useState<number>(0);

  const findContributorRole = () => {
    let contributor = userHasRoles.find((f) => f.name === "Ask Analyst");
    let _contributor = undefined;

    if (contributor) {
      _contributor = contributor.roles.find((a) => a.roles === "Contributor");
    }
    return _contributor;
  };

  const findAdminRole = () => {
    let admin = userHasRoles.find((f) => f.name === "Ask Analyst");
    let _admin = undefined;

    if (admin) {
      _admin = admin.roles.find((a) => a.roles === "Admin");
    }
    return _admin;
  };

  // console.log("THE ROLE ====> ", );

  // <div className="w-100 d-flex flex-row-reverse">
  //   <span
  //     onClick={() => {
  //       submitCompanyInformationDraft();
  //     }}
  //     className="btn btn-cancel"
  //   >
  //     Save Draft
  //   </span>
  //   <span
  //     className="btn btn-cancel mr-2"
  //     onClick={() => {
  //       console.log("Approving Draft");
  //       sendForApproval();
  //     }}
  //   >
  //     Send For Approval
  //   </span>
  //   <span
  //     className="btn btn-cancel mr-2"
  //     onClick={() => {
  //       approveFinalDraft();
  //     }}
  //   >
  //     Approve Draft
  //   </span>
  //   <button
  //     className="btn btn-primary mr-2"
  //     // style={styles.button}
  //     onClick={() => submitCompanyInformation()}
  //   >
  //     save
  //   </button>
  // </div>

  // returnAccess();

  return (
    <>
      <div
        className="company-introduction-editor"
        style={styles.CompanyIntroductionEditor}
      >
        <CKEditor
          editor={Editor}
          data={`${initial}`}
          config={
            {
              // toolbar: ['bold', 'align', 'paragraph', 'indent', 'blockQuote', 'italic', 'numberedList', 'bulletedList']
            }
          }
          onReady={(editor) => {
            editor.data.set(initial);
            // console.log( 'Editor is ready to use!', editor );
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            createPayload("company_introduction", data);
            const strippedString = data.replace(/(<([^>]+)>)/gi, "");
            const dataLength = strippedString.replaceAll("&nbsp;", " ").length;
            // console.log(strippedString)
            setlength(dataLength);
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
        <p
          className="dataLength"
          style={length < 886 ? styles.dataLength : styles.dataLengthMax}
        >
          {length < 0 ? 0 : length}/885
        </p>
      </div>

      <div className="w-100 d-flex flex-row-reverse">
        <span
          onClick={() => {
            submitCompanyInformationDraft();
          }}
          className="btn btn-cancel"
        >
          Save As Draft
        </span>
        {FindRequiredPermission(userHasRoles, "Ask Analyst", "Contributor") ? (
          <span
            className="btn btn-cancel mr-2"
            onClick={() => {
              console.log("Approving Draft");
              sendForApproval();
            }}
          >
            Send For Approval
          </span>
        ) : (
          <></>
        )}

        {draftModeFlage ? (
          FindRequiredPermission(userHasRoles, "Ask Analyst", "Admin") ||
          (FindRequiredPermission(userHasRoles, "Ask Analyst", "Approver") &&
            draftModeFlage) ? (
            <span
              className="btn btn-cancel mr-2"
              onClick={() => {
                console.log("rejecting Draft");
              }}
            >
              {draftModeFlage ? "Reject Draft" : "Delete Draft"}
            </span>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {FindRequiredPermission(userHasRoles, "Ask Analyst", "Admin") ||
        FindRequiredPermission(userHasRoles, "Ask Analyst", "Approver") ? (
          <span
            className="btn btn-cancel mr-2"
            onClick={() => {
              approveFinalDraft();
            }}
          >
            {!draftModeFlage ? "Submit into Database" : "Approve Draft"}
          </span>
        ) : (
          <></>
        )}

        {/* <button
          className="btn btn-primary mr-2"
          // style={styles.button}
          onClick={() => submitCompanyInformation()}
        >
          save
        </button> */}
      </div>
      {/* <button onClick={() => { console.log(initial) }} className='btn btn-primary'>Show Company</button> */}
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  CompanyIntroductionEditor: {
    height: "100%",
    paddingBottom: "30px",
  },
  dataLength: {
    color: "green",
    padding: "5pt",
    margin: "0",
    position: "absolute",
    right: "0",
    top: "0",
  },
  dataLengthMax: {
    color: "red",
    padding: "5pt",
    margin: "0",
    position: "absolute",
    right: "0",
    top: "0",
  },
  button: {
    margin: "auto",
    background: "rgb(57, 105, 213)",
    minWidth: "100px",
    marginLeft: "calc(100% - 100px)",
    marginBottom: "20px",
  },
};
