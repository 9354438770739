import { ActionTypes } from "../constants/ActionTypes"

const inititalState = {
    products: [
        {
            id: 1,
            title: "Bilal",
            category: "Full-Stack Developer"
        },
    ],
};

export const productReducer = (state = inititalState, {type, payload}) => {
    switch(type) {
        case ActionTypes.SET_PRODUCTS:
            return state;
        default:
            return state;
    }    
};