import { ActionTypes } from "../constants/ActionTypes"
import { KeyupdatesCategoriesAPI } from "../../api/ApiList";

const inititalState = {
    keyupdatesCategories: [{}],
};

export const keyupdatesReducer = (state = inititalState, {type, payload}) => {
    if ( type == ActionTypes.FETCH_KEYUPDATES_CATEGORIES ) {
        return { ...state, keyupdatesCategories: payload }
    } else if( type == ActionTypes.GET_KEYUPDATES_CATEGORIES ) {
        return state 
    } else {
        return state;
    }
    
};