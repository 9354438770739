import { combineReducers } from "redux";
import { productReducer } from "./ProductReducer";
import { authReducer } from "./AuthReducer";
import { searchDataReducer } from "./SearchDataReducer";
import { keyupdatesReducer } from "./KeyupdatesReducers";

const reducers = combineReducers({
    allProducts: productReducer,
    authentication: authReducer,
    searchData: searchDataReducer,
    keyupdates: keyupdatesReducer
})

export default reducers;