import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import InputField from "../../../../components/InputField/InputField";
import { ProjectAddAPI } from "../../../../api/ApiList";
import FileUploadDropdown from "../../components/FileUploadDropdown/FileUploadDropdown";
const ProjectAddModal = ({
  addModalShow,
  closeModal,
  setData,
  data,
  ...props
}) => {
  const [able, setAble] = useState(false);
  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const [projectName, setProjectName] = useState("");
  const [projectImage, setProjectImage] = useState<any>();

  const getProjectImage = (image) => {
    setProjectImage(image);
  };

  const handleSubmit = () => {
    setAble(true);
    const form = new FormData();
    form.append("name", projectName);
    form.append("image", projectImage.file);
    axios({
      headers: { "Content-Type": "aplication/json" },
      method: "post",
      url: `${ProjectAddAPI}`,
      data: form,
    })
      .then(function (response) {
        setData((data) => [response.data.record, ...data]);
        toast.success("Project Added!");
        closeModal();
        setAble(false);
      })
      .catch(function (error) {
        setAble(false);
        toast.error("there was an error");
      });
  };

  return (
    <>
      <Modal
        className="table-modal add-project-modal"
        show={addModalShow ? true : false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-left d-inline-block w-100">
            Add Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                required
                className="form-control w-100"
                onChange={(e) => setProjectName(e.target.value)}
                type="text"
                name="name"
              />
            </div>
            <div className="form-group">
              <FileUploadDropdown
                projectImage={projectImage}
                getProjectImage={getProjectImage}
              />
            </div>
            <div className="form-group">
              <button
                style={{ color: "white" }}
                onClick={() => {
                  handleSubmit();
                }}
                className="btn btn-primary"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-light ml-2"
                name="cancel"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectAddModal;
