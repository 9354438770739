import React , {useState , useEffect} from 'react';
import axios from 'axios';
import {AddDraftRecentDevelopment , FetchSingleDraftRecentDevelopment} from '../../../../api/ApiList';


const RecentDevelopmentDrafts = ({user , toggleDraftSidebar ,  draftSidebarShow , setSelectedCompanyData , setFormList}) => {
    const [draftData, setDraftData] = useState<any>([]);

    useEffect(() => {
      handleFetchDraft(JSON.parse(user));
    } , [draftSidebarShow])


    const handleFetchDraft = async (userInfo) => {
      // console.log(userInfo , "use for fetch drafts");
      axios({
          method: "GET",
          url: AddDraftRecentDevelopment,
          params: {
            "user_id": JSON.stringify(userInfo.id)
          }
      }).then((response) => {
          console.log(response.data , "fetch draft list response");
          setDraftData(response.data)
      }).catch((err) => {
          console.log(err.response , "error");
      })
    }

    const fetchSingleDraft = (i) =>{
      console.log(i , " click draft id");
      axios({
        method: "GET",
        url: FetchSingleDraftRecentDevelopment,
        params: {
          "draft_id": JSON.stringify(i)
        }
      }).then((response) => {
          // const draftObj:any = [];
          setSelectedCompanyData(response.data[0].company)
          console.log(response.data , "fetch single draft from draft list");
          console.log(JSON.parse(response.data[0].data) , "fetch single draft data");
          // response.data.map((d , i) => {
          //   let draftInstance ={
          //     "year": JSON.stringify(d.year),
          //     "qtr": d.quarter,
          //     "cat": d.cat_id,
          //     "news": d.news
          //   }
          //   draftObj.push(draftInstance)
          // })
          setFormList(JSON.parse(response.data[0].data));
          // console.log(draftObj , " draftObj set in form list");
      }).catch((err) => {
          console.log(err.response , "error");
      })
    }

    return (
        <div
        className={`draft-sidebar draft-slider ${
          draftSidebarShow ? "draft-sidebar-active" : ""
        }`}
      >
        <span
          onClick={() => {
            toggleDraftSidebar();
          }}
          className={`btn draft-sidebar-close-btn`}
        >
          x
        </span>
        <div className='recent-draft-list'>
          {draftData && draftData.length > 0 ?
            <>
              <h6 className='pl-5'>Total Drafts : {draftData.length}</h6>  
              <ul className='m-0'>
                {draftData.map((d, i) => {
                  return( 
                    <li>
                      <p className='d-inline-block m-0'>                       
                        <button className="btn draft-btn" data-draft_id={d.id} onClick={() => fetchSingleDraft(d.id)}>Draft #{i + 1}</button>
                        <div className='draft-meta'>
                          <span className='date'>20-oct-2021</span>
                          <span className='time'>20:15 PM</span>
                        </div>
                      </p>
                      <span className='reject-label'>Rejected</span>
                    </li>
                  );
                })}
              </ul>
            </>
            : 
              <p className='text-center my-5' style={{color: "#4170d7"}}>No Drafts Found!</p>
            }
        </div>
      </div>
        // <div className='recent-development-drafts'>
        //     <ul>
        //         {draftList.map((d, i) => {
        //         return <li><button className="btn draft-btn" data-draft_id={d.id} onClick={(e: any) => {fetchDraftCompanyInformation(e.target.getAttribute("data-draft_id"))}}>Draft #{i + 1}</button></li>;
        //         })}
        //     </ul>
        // </div>
    )
}

export default RecentDevelopmentDrafts;
