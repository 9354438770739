import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../layout/MainLayout/MainLayout";
import UpcomingBirthdays from "../../components/UpcomingBirthdays/UpcomingBirthdays";
import UpcomingEvents from "../../components/UpcomingEvents/UpcomingEvents";
import KeyUpdatesCategories from "../../sections/KeyUpdatesCategories/KeyUpdatesCategories";
// import KeyUpdatesDetail from "../../sections/KeyUpdatesDetail/KeyUpdatesDetail";
import AddKeyUpdateCategory from "../../components/AddKeyUpdateCategory/AddKeyUpdateCategory";
import Button from "react-bootstrap/Button";
import UserImg from '../../../../assets/media/profile_user.jpg';
import "../../Style.css";

export default function Index() {
  const [categoryAddModal, setCategoryAddModal] = useState<boolean>(false);
  let [count, setCount] = useState<number>(0);

  const history = useNavigate();

  // -------------- Show and Hide Modal Logic --------------------

  const handleAddModalShow = () => {
    setCategoryAddModal(!categoryAddModal);
  };

  const addKeyUpdates = () => {
    history("./addkeyupdate");
  };

  useEffect(() => {
    console.log("asdasdasd");
  } , [])

  return (
    <MainLayout>
      <div className="d-inline-flex flex-wrap w-100 keyupdate-main">
        <div className="d-inline-flex w-100 add-key-filter">
          <Button
            variant="light"
            name="addcategory"
            className="add-category"
            onClick={handleAddModalShow}
          >
            + Add Category
          </Button>
          <Button
            variant="primary"
            name="addkeyupdates"
            className="add-keyupdates"
            onClick={addKeyUpdates}
          >
            + Add Key Update
          </Button>
        </div>
        <div className="d-inline-flex flex-wrap justify-content-between w-100">
          <div className="col-lg-9 p-0">
            <div className="keyupdate-detail-sec mb-4">
                <h3 className="title">Richird Norton photorealistic rendering as real </h3>
                <div className='keyupdates-detail-main'>
                    <div className='d-flex flex-wrap justify-content-center mt-4'>
                        <div className="col-lg-3 keyupdates-detail-meta">
                            <p className="date-block"><span>08.08.2021</span><span className="line"></span><span>KeyUpdates</span></p>
                            <p className="poster-meta">
                                <span className="poster-img"><img src={UserImg} alt="" /></span>
                                <p>
                                    <span className="poster-name">By Jennifer Lawrence</span>
                                    <span className="poster-position">Thinker & Designer</span>
                                </p>
                            </p>
                            <p className="post-category"><span>Trending</span></p>
                        </div>
                        <div className="col-lg-9 keyupdates-detail-content">
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad facilis aperiam quod perspiciatis temporibus corporis commodi beatae autem ex pariatur, esse nam doloribus saepe sunt amet maiores voluptatibus labore illo.</p>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad facilis aperiam quod perspiciatis temporibus corporis commodi beatae autem ex pariatur, esse nam doloribus saepe sunt amet maiores voluptatibus labore illo.</p>                            
                            <p className="quote-block">"Ad facilis aperiam quod perspiciatis temporibus corporis commodi beatae autem ex pariatur, Lorem ipsum dolor sit"</p>
                        </div> 
                    </div> 
                </div>
            </div>
          </div>
          <div className="col-lg-3 pr-0">
            <KeyUpdatesCategories setCount={setCount} count={count} />
            <UpcomingEvents />
            <UpcomingBirthdays />
          </div>
        </div>
      </div>

      <AddKeyUpdateCategory
        addModalShow={categoryAddModal}
        handleAddModalShow={handleAddModalShow}
        setCount={setCount}
        count={count}
      />
    </MainLayout>
  );
}
