import {useState , useEffect} from 'react';
import axios from 'axios';
// import Header from "../../../../components/Header/Header";
// import Sidebar from "../../../../components/Sidebar/Sidebar";
import MainLayout from '../../../../layout/MainLayout/MainLayout';
import EditKeyUpdates from '../../components/EditKeyUpdates/EditKeyUpdates';
import { useParams } from 'react-router-dom';
import {
    KeyUpdatesListAPI
} from "../../../../api/ApiList";


type ObjType = {
    id: number | string;
    name: string;
    description: string;
    detail_description: string
}

const EditKeyUpdate = () => {

    const [editObj , setEditObj] = useState<ObjType>({
        id: "",
        name: "",
        description: "",
        detail_description: ""
    })

    const params = useParams();
    const getEditObj = async () =>{
        await axios.get(KeyUpdatesListAPI)
        .then((response)=>{
          console.log(response.data , "keylist");
          console.log(params.id , "params");
          console.log(response.data.filter(item => item.id == params.id)[0]);
          setEditObj(response.data.filter(item => item.id == params.id)[0]);
        })
    }

    useEffect(() => {
        getEditObj();
    }, [])



    return (
        <MainLayout>
            <div className='editkey-main-block'>
                <EditKeyUpdates editObj={editObj}/>
            </div>  
        </MainLayout>
    )
}

export default EditKeyUpdate;

