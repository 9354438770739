export const ActionTypes = {
    
    SET_LOGGED_IN: "SET_LOGGED_IN",
    SET_AUTH: "SET_AUTH",
    GET_AUTH: "SET_AUTH",
    REMOVE_AUTH: "REMOVE_AUTH",

    SET_PRODUCTS: "SET_PRODUCTS",
    SELECTED_PRODUCT: "SELECTED_PRODUCT",
    REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",

    SET_ALL_DATA: "SET_ALL_DATA",
    GET_ALL_DATA: "GET_ALL_DATA",
    SET_SELECTED_DATA: "SET_SELECTED_DATA",
    GET_SELECTED_DATA: "GET_SELECTED_DATA",
    SET_DATA: "SET_DATA",
    GET_DATA: "GET_DATA",

    FETCH_KEYUPDATES_CATEGORIES: "FETCH_KEYUPDATES_CATEGORIES",
    GET_KEYUPDATES_CATEGORIES: "GET_KEYUPDATES_CATEGORIES",
}