import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../../components/InputField/InputField";
import * as Yup from "yup";

const UserData = () => {
  const validate = Yup.object({
    name: Yup.string().required("Full Name is required"),
    email: Yup.string().required("Email is required"),
    cnic: Yup.string().required("Cnic is required"),
  });
  return (
    <>
      <div className="user-form-main">
        <Formik
          initialValues={{
            name: "Ali",
            email: "ali.abbas@akseertechnologies.com",
            cnic: "41231-1231231-1",
            phone: "03123123232",
            designation: "Accountant",
            empId: "emp-001",
            doj: "20-Spet-2019",
            address: "Abc street, Karachi",
            shift: "Morning",
            department: "Finance",
          }}
          validationSchema={validate}
          onSubmit={(values) => {
            console.log(values, "user fields data");
          }}
        >
          {(formik) => (
            <>
              <Form>
                <div className="user-personal-block">
                  <div className="title">
                    <h3>Personal Information</h3>
                  </div>
                  <div className="personal-info">
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Full Name"
                        name="name"
                        placeholder="Enter Full Name"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="email"
                        label="Email"
                        name="email"
                        placeholder="Enter Email"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="CNIC"
                        name="cnic"
                        placeholder="Enter CNIC"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="phone"
                        label="Phone"
                        name="phone"
                        placeholder="Enter Phone"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="user-company-block">
                  <div className="title">
                    <h3>Company Information</h3>
                  </div>
                  <div className="company-info">
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Designation"
                        name="designation"
                        placeholder="Enter Designation"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Employee Id"
                        name="empId"
                        placeholder="Enter Employee Id"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="date"
                        label="Date of Joining"
                        name="doj"
                        placeholder="Enter Date of Joining"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Address"
                        name="address"
                        placeholder="Enter Address"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Shift"
                        name="shift"
                        placeholder="Enter Shift"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        type="text"
                        label="Department"
                        name="department"
                        placeholder="Enter Department"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group my-2 d-inline-block text-right w-100 position-relative">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    name="submit"
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UserData;
