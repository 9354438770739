import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

const MyDropzone = (props) => {

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log(acceptedFiles , "after drop");
    }, [])
    const {acceptedFiles , getRootProps, getInputProps, isDragActive} = useDropzone(
        {
            onDrop , 
            maxFiles:10 , 
            accept: {
                'image/pdf' : ['.pdf']
            } 
        }
    )

    props.setCount(acceptedFiles.length);

    const files = acceptedFiles?acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
    )):[];

    return (
        <div className='dropzone-main'>
            <div {...getRootProps()}>
                <input type='file' name="file" {...getInputProps()} />
                {
                    isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p className='dropzone-text-block d-flex flex-wrap flex-column justify-content-center align-items-center'><span className='drop-text'>Drag Files to Upload</span> or <span className='browse-text'>Upload Files</span></p>
                }
            </div>
                {acceptedFiles.length > 1 ? 
                    <p className='uploaded-files'>
                        <span>{acceptedFiles.length}</span> files added 
                    </p>
                    : 
                    <ul className='list-unstyled m-0 d-flex flex-wrap justify-content-start'>
                        {files}
                    </ul>
                }
        </div>
    )
}

export default MyDropzone;
