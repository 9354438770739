import React, { useState, useEffect } from "react";
import axios from "axios";
// import pdf from '../../../assets/media/pdf.svg';
import { policyFilesListAPI } from "../../../api/ApiList";

const pdf = require("../../../assets/media/pdf.svg").default;

const PolicySOPList = () => {
  const [fileList, setFileList] = useState<any>();

  const list = async () => {
    const response = await axios.get(policyFilesListAPI);
    console.log(response , "policy response");
    setFileList(response.data);
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <div className="policy-list-sec">
      <div className="row flex-wrap justify-content-start">
        {fileList && fileList.length > 0 ? 
          fileList.map((f , i) => {
            return (
              <div className="col-md-3">
                <div className="policy-list-block">
                  <h3>{f[0]['sections']['name']}</h3>
                  <ul className="list-unstyled m-0">
                    {f.map((d:any , index) => {
                      return(
                        <li>
                          <h4>
                              <span>
                                <img src={pdf} alt="pdf" />
                              </span>
                              <a
                                href={`https://brain.akseerresearch.com/${d.file}`}
                                target="_blank"
                              >
                                {d.title}
                              </a>
                            </h4>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )
          })
        : 
          null
        }
      </div>
    </div>
  );
};

export default PolicySOPList;
