import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  ManageUserListAPI,
  ManageUserSectorListAPI,
  ManageUserCompanyListAPI,
} from "../../../../../../api/ApiList";
import "./AssignUser.css";

type AppProps = {
  assignModalShow: boolean;
  setAssignModalShow: Function;
  submitFlag: boolean;
  setSubmitFlag: Function;
};

const AssignUser = ({
  assignModalShow,
  setAssignModalShow,
  submitFlag,
  setSubmitFlag,
}: AppProps) => {
  const [errList, setErrList] = useState<any>({
    user: {
      name: "",
    },
    assign: [
      {
        index: null,
        sector: "",
        company: "",
      },
    ],
  });
  const [enable, setEnable] = useState<boolean>(false);
  const [assignBtn, setAssignBtn] = useState<boolean>(true);
  const [selectUser, setSelectedUser] = useState({ name: "", id: null });
  const [userList, setUserList] = useState([{}]);
  const [sectorList, setSectorList] = useState([{ value: null, label: "" }]);
  const [sortedSectorList, setSortedSectorList] = useState([
    { value: null, label: "" },
  ]);
  const [filterOutIds, setFilterOutIds] = useState<number[]>([]);
  const [assignFields, setAssignFields] = useState([
    { sector: "", company: [] },
  ]);
  const [assignData, setAssignData] = useState({
    user: selectUser,
    assign: assignFields,
  });
  const [companyBySector, setCompanyBySector] = useState([
    { sectorId: null, companies: [{ value: null, label: "" }] },
  ]);

  const list = async () => {
    const response = await axios.get(ManageUserListAPI);
    // console.log(response.data , "=====> manage user list");
    let customUser: any = [];
    if (response.data && response.data.length > 0) {
      response.data.map((a) => {
        customUser.push({
          value: a.id,
          label: a.name,
        });
      });
    }

    // console.log(customSector , " ====> customSector")
    setUserList(customUser);
  };

  const sectorlist = async () => {
    const response = await axios.get(ManageUserSectorListAPI);
    // console.log(response.data , "=====> manage sector list");
    let customSector: any = [];
    if (response.data && response.data.length > 0) {
      response.data.map((a) => {
        customSector.push({
          value: a.id,
          label: a.sector,
        });
      });
    }

    // console.log(customSector , " ====> customSector")
    setSectorList(customSector);
    setSortedSectorList(customSector);
  };

  useEffect(() => {
    list();
    sectorlist();
  }, []);

  useEffect(() => {
    list();
    sectorlist();
  }, [assignModalShow]);

  const handleUserChange = (d) => {
    setEnable(false);
    const selectUserClone = { ...selectUser };
    selectUserClone.id = d.value;
    selectUserClone.name = d.label;
    console.log(selectUserClone, "selectUserClone");
    setSelectedUser(selectUserClone);
    let assignData = {
      user: selectUserClone,
      assign: assignFields,
    };
    validate(assignData);
    setEnable(false);
  };

  const handleSectorChange = async (d, e) => {
    // console.log(d);
    // console.log(e);
    // let currentSector:number = parseInt(assignFields[parseInt(e.name)].sector);
    // if(!filterOutIds.includes(d.value)){
    //     setFilterOutIds([...filterOutIds , d.value])
    //     console.log(filterOutIds , "elements id filter out")

    let sortedClone = sortedSectorList.filter((s) => s.value != d.value);
    setSortedSectorList(sortedClone);

    // console.log(sortedClone , "====> sortedSectorClone");
    // }else{
    //     let resetObj = sectorList.filter(s => s.value === currentSector);
    //     console.log(resetObj , "====> resetObj");
    //     let sortedClone = [...sortedSectorList, resetObj[0]];
    //     setSortedSectorList(sortedClone);
    // }

    let index = parseInt(e.name);
    let val = d.label;
    const assignFieldClone = [...assignFields];
    assignFieldClone[index]["sector"] = val;
    setAssignFields(assignFieldClone);
    let assignData = {
      user: selectUser,
      assign: assignFields,
    };
    validate(assignData);
    setEnable(false);

    //fetch companies on sector change
    const response = await axios.post(ManageUserCompanyListAPI, {
      sec_id: d.value,
    });
    console.log(response.data[0], "company response data");
    //filter companies on basis of sector change
    let customCompanies: any = [];
    if (response.data && response.data.length > 0) {
      response.data[0].companies.map((a) => {
        if (a.assign_to_user === null) {
          customCompanies.push({
            value: a.id,
            label: a.name,
          });
        }
      });
      console.log(customCompanies, " after filter customCompanies");
    }

    //set filter companies on select field mapping
    const companyBySectorClone = [...companyBySector];
    companyBySectorClone[parseInt(e.name)].sectorId = response.data.id;
    companyBySectorClone[parseInt(e.name)].companies = customCompanies;

    //set sector for value on change
    const sectorValues = [...assignFields];
    sectorValues[parseInt(e.name)].sector = d.value;
    setAssignFields(sectorValues);
  };

  const handleCompaniesChange = (d, e) => {
    const companyValues = [...assignFields];
    let compArr: any = [];
    d.map((a) => {
      compArr.push({ [`${Object.keys(a)[0]}`]: a.value });
    });
    companyValues[parseInt(e.name)].company = compArr;
    setAssignFields(companyValues);
    // console.log(companyValues , "companyValuescompanyValues");
    let assignData = {
      user: selectUser,
      assign: assignFields,
    };
    validate(assignData);
    setEnable(false);
  };

  const validate = (values) => {
    // debugger;
    let errors: any = {
      user: {
        name: "",
      },
      assign: [{}],
    };
    // console.log(errors , "errors array");
    console.log(values, "input array");

    if (values.user.name === "") {
      errors.user.name = "Field is Required";
      setSubmitFlag(false);
    }

    for (let i = 0; i < values.assign.length; i++) {
      let obj = {
        index: 0,
        sector: "",
        company: "",
      };
      if (values.assign[i].sector === "") {
        obj.sector = "Field is Required";
        setSubmitFlag(false);
      }
      if (values.assign[i].company.length === 0) {
        obj.company = "Field is Required";
        setSubmitFlag(false);
      }
      if (
        values.assign[i].sector === "" ||
        values.assign[i].company.length === 0
      ) {
        obj.index = i;
        errors.assign[i] = obj;
        setEnable(true);
        setSubmitFlag(false);
      } else {
        obj.index = i;
        errors.assign[i] = obj;
      }
    }
    // debugger;
    for (var i = 0; i < errors.assign.length; i++) {
      if (
        errors.user.name == "" &&
        errors.assign[i].sector == "" &&
        errors.assign[i].company == ""
      ) {
        if (i == errors.assign.length - 1) {
          setSubmitFlag(true);
          console.log(submitFlag, "flag value in if");
        }
      } else {
        console.log(errors, "errors array");
        setSubmitFlag(false);
        setErrList(errors);
        console.log(submitFlag, "flag value in else");
      }
    }
    // else if(errors.user.name === "" && errors.assign.length > 0 || ){
    //     console.log(errors , "err list");
    //     setSubmitFlag(false)
    // }
    // console.log(submitFlag , "flag value");
  };

  const handleSubmit = async () => {
    const assignData = {
      user: selectUser,
      assign: assignFields,
    };
    validate(assignData);
    // debugger
    if (submitFlag) {
      console.log("flag is true ======");
      const formData = new FormData();
      formData.append("data", JSON.stringify(assignData));
      axios({
        method: "POST",
        url: "https://brain.akseerresearch.com/api/askanalyst/assign/sector/company",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          handleClose();
          toast.success("Assigned User Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((err) => {
          console.log(err.response, "error");
        });
    }
  };

  //adding more field on assign more action
  const handleAssignMore = () => {
    setSubmitFlag(false);
    setEnable(false);
    setErrList({
      user: errList.user,
      assign: [
        ...errList.assign,
        {
          index: null,
          sector: "",
          company: "",
        },
      ],
    });
    setAssignFields([...assignFields, { sector: "", company: [] }]);
    setCompanyBySector([
      ...companyBySector,
      {
        sectorId: null,
        companies: [{ value: null, label: "" }],
      },
    ]);
  };

  //removing field on delete action
  const handleRemoveMore = (i) => {
    let fieldsArray = [...assignFields];
    let removeInstance = parseInt(i);
    fieldsArray.splice(removeInstance, 1);
    setAssignFields(fieldsArray);

    let errorArr = { ...errList };
    errorArr.assign.splice(removeInstance, 1);
    setErrList(errorArr);

    // setErrList({
    //     user: errList.user,
    //     assign: [...errList.assign , {
    //         "index": null,
    //         "sector": "",
    //         "company": ""
    //     }]
    // });

    const companyDataArray = [...companyBySector];
    const removeCompanyDataInstance = parseInt(i);
    companyDataArray.splice(removeCompanyDataInstance, 1);
    setCompanyBySector(companyDataArray);
  };

  const handleClose = () => {
    list();
    setAssignModalShow(false);
    setAssignBtn(true);
    setTimeout(() => {
      setAssignFields([{ sector: "", company: [] }]);
      setErrList({ user: { name: "" }, assign: [{}] });
      setSelectedUser({ name: "", id: null });
      setSectorList([{ value: null, label: "" }]);
      setSortedSectorList([{ value: null, label: "" }]);
      setCompanyBySector([
        { sectorId: null, companies: [{ value: null, label: "" }] },
      ]);
      setEnable(false);
    }, 500);
  };

  return (
    <Modal
      className="table-modal assign-user-modal"
      show={assignModalShow}
      centered
    >
      <Modal.Header>
        <Modal.Title className="text-left d-inline-block w-100 px-3">
          Assign User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="assign-form-block">
          <div className="form-group col-12">
            <label className="d-inline-block w-100 mb-1">
              {" "}
              Select User Name
            </label>
            <Select
              id="user-name"
              className="w-100"
              name="user"
              closeMenuOnSelect={true}
              options={userList}
              onChange={(index) => handleUserChange(index)}
              placeholder=""
            />
            {errList && errList.user.name !== (null || undefined || "") ? (
              <span className="field-error">{errList.user.name}</span>
            ) : null}
          </div>

          <div className="multi-assign-block">
            {assignFields.map((assignField, index) => (
              <div className="single-assign-block">
                <>
                  <div className="form-group col-12">
                    <label className="d-inline-block w-100 mb-1">
                      {" "}
                      Select Sector
                    </label>
                    <Select
                      className="w-100"
                      name={`${index}`}
                      closeMenuOnSelect={true}
                      options={sortedSectorList}
                      onChange={(index, event) =>
                        handleSectorChange(index, event)
                      }
                      placeholder=""
                    />
                    {errList &&
                    index === errList.assign[index].index &&
                    errList.assign[index].sector &&
                    errList.assign[index].sector !==
                      (null || undefined || "") ? (
                      <span className="field-error">
                        {errList.assign[index].sector}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-group col-12 company-select">
                    <label className="d-inline-block w-100 mb-1">
                      {" "}
                      Select Companies
                    </label>
                    <Select
                      className="w-100"
                      name={`${index}`}
                      closeMenuOnSelect={false}
                      options={companyBySector[index].companies}
                      isMulti
                      onChange={(index, event) =>
                        handleCompaniesChange(index, event)
                      }
                      placeholder=""
                    />
                    {errList &&
                    index === errList.assign[index].index &&
                    errList.assign[index].company &&
                    errList.assign[index].company !==
                      (null || undefined || "") ? (
                      <span className="field-error">
                        {errList.assign[index].company}
                      </span>
                    ) : null}
                  </div>
                  {assignFields.length > 1 ? (
                    <div className="remove-instance">
                      <button
                        className="btn"
                        onClick={(index) => handleRemoveMore(index)}
                      >
                        X
                      </button>
                    </div>
                  ) : null}
                </>
              </div>
            ))}
          </div>
          {assignBtn ? (
            <div className="add-more-btn col-md-12 d-flex flex-wrap justify-content-end">
              <button onClick={handleAssignMore}>+ Assign More</button>
            </div>
          ) : null}
          <div className="form-group d-inline-flex justify-content-center w-100 position-relative my-3 px-3">
            <button
              type="submit"
              className="btn btn-primary px-4"
              disabled={enable}
              name="submit"
              style={{ background: "#3969d5" }}
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-cancel ml-2 px-4"
              name="cancel"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssignUser;
