import React from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Keyupdates from '../../components/Keyupdates/keyupdates';
import Projectlinks from '../../components/ProjectLinks/projectlinks';
import MainLayout from '../MainLayout/MainLayout';


export default function Dashboard() {
  return (
      <>
        <MainLayout>
          <Keyupdates />
          <Projectlinks />
        </MainLayout>
      </>
  );
}
