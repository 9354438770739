import React from "react";
import { Card } from "react-bootstrap";
import UserTable from "./components/UserTable/UserTable";

const downloadIcon = require("../../../../assets/download.png");

export default function Registration(): React.FunctionComponentElement<Element> {
  return (
    <div className="registered-user-table container-fluid mt-3 px-0">
      <div className="mb-3" style={styles.downloadCSVParent}>
        <button style={styles.downloadCSVBtn} className="btn btn-primary">
          <img className="d-none" src={downloadIcon} />
          Download CSV
        </button>
      </div>
      <Card>
        <Card.Header>
          <h4>Registration</h4>
        </Card.Header>
        <Card.Body>
          <UserTable />
        </Card.Body>
      </Card>
    </div>
  );
}

const styles = {
  downloadCSVParent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    // flexDirection: "row-reverse",
  },
  downloadCSVBtn: {
    background: "#3969D5",
    fontFamily: "'Lato', sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    // padding: "9px 25px",
    borderRadius: "4px",
  },
};
