import React , {useState} from 'react';
import {Tabs , Tab} from 'react-bootstrap';
// import logo from '../../../assets/charting_tool/charting-logo.svg';
import logo from '../../../assets/media/charting.svg'
import economy_icon from '../../../assets/charting_tool/charting-economy.svg';
import market_icon from '../../../assets/charting_tool/charting-market.svg';
import sector_icon from '../../../assets/charting_tool/charting-sector.svg';
import company_icon from '../../../assets/charting_tool/charting-company.svg';
import report_icon from '../../../assets/charting_tool/charting-report.svg';
import Sector from '../sections/Sector/Sector'
import ReportBuilder from '../../___ChartingTool/layout/ChartingHome.jsx'
import './Tabs.css';

const TabsView = () => {

    // console.log("SELECTED COMPANYY INSIDE TABS VIEW ========>", props.selectedCompany)
    const [activeTab, setActiveTab] = useState('sector');
    // function handleRecent(e) {
    //     if(typeof(e) === 'object'){
    //         setActiveTab(e.target.attributes.tabname.value);
    //     }
    //     else{
    //         setActiveTab(e);
    //     }
    // }

    return (
        <>
            <div className='charting-tabs-view'>
                <Tabs id="controlled-tab-example" defaultActiveKey="sector">
                    <Tab eventKey="economy" title={<span><img src={economy_icon} alt="icon" /> Economy</span>}>
                        Economy
                    </Tab>
                    <Tab eventKey="market" title={<span><img src={market_icon} alt="icon" />Market</span>}>
                        Market
                    </Tab>
                    <Tab eventKey="sector" title={<span><img src={sector_icon} alt="icon" />Sector</span>}>
                        <Sector/>
                    </Tab>
                    <Tab eventKey="company" title={<span><img src={company_icon} alt="icon" />Company</span>}>
                        Company
                    </Tab>
                    <Tab eventKey="report" title={<span><img src={report_icon} alt="icon" />Report Builder</span>}>
                        <ReportBuilder/>
                    </Tab>
                </Tabs>
                <span className='charting-logo'>
                    <img src={logo} alt="logo" />
                    <small>Charting Tool</small> 
                </span>
            </div>
        </>
    )
}

export default TabsView;