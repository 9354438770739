import React, { useState } from 'react';
import Patternlist from '../components/PatternList/PatternList';
import MainLayout from '../../../layout/MainLayout/MainLayout';
import Disclaimer from '../components/Disclaimer/Disclaimer';
import PatternContent from '../PatternContent/patterncontent';
import HTML2PDF from 'html2pdf.js/dist/html2pdf.bundle';
import Charting from '../components/Charting/Charting';
import _Charting from '../components/Charting/_Charting';
import '../Charting.css';

const ChartingHome = () => {

    const [addInstances , setAddInstance] = useState([]);
    let instanceID, instanceValue, instanceIDSplit;
    let disclaimerInstance = {patNo: '00', title: 'pat-dis-00', data: 'Lorem Ipsum is a dummy text for testing purpose.'}
    const [isRemoveActive, setIsRemoveActive] = useState(true);
    const [activeDisclaimer , setActiveDisclaimer] = useState(false);
    
    
    const getPattern = (event) => {
        instanceValue = event.target.id;
        instanceID = event.target.id.split('-');
        instanceIDSplit = instanceID[1];
        setAddInstance([...addInstances , {patNo:instanceIDSplit , title: instanceValue , data: event.target.attributes.data.value}]);
        setIsRemoveActive(true);
        setActiveDisclaimer(true)
    }

    const handleRemoveInstance = (index) => {
        const values = [...addInstances];
        values.splice(index , 1);
        setAddInstance(values);
    }

    const handleRemoveDisclaimer = () => {
        setActiveDisclaimer(false);
    }

    const handlePrint = () =>{
        // alert(location);
        setIsRemoveActive(false);

        const prntElement = document.getElementById('printThis');
        HTML2PDF(prntElement, {
            margin:       0,
            filename:     'myfile.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 0.9, logging: true, dpi: 250, letterRendering: true},
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'landscape' }
        });
    }



    return (
        <>
            {/* // <MainLayout> */}
                <Patternlist getPattern={getPattern} />
                <Charting />
                <div className="pattern-content-append">
                    {
                        addInstances.length != 0 ?
                            <div className="d-inline-block w-100 text-center">
                                <button id="printBtn" type="button" onClick={handlePrint}>Generate PDF</button>
                            </div>
                        : null
                    }
                    <div id="printThis">
                        {
                            addInstances.length != 0 ?
                                <>
                                    {addInstances.map((addInstance , index) => (
                                        <>
                                            <div className="charting-page-block"  key={index}>
                                                {isRemoveActive ?
                                                    <button className="removebtn" type="button" onClick={(index)=>handleRemoveInstance(index)}>
                                                        <svg width="20px" height="20px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-times fa-w-10">
                                                            <path fill="#ffffff" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" className=""></path>
                                                        </svg>
                                                    </button>
                                                : null}
                                                <PatternContent key={'PatternContent' + index} instanceObject={addInstance} InstanceData={addInstance.data} InstanceIndex={index}/>
                                            </div>
                                            <div className="html2pdf__page-break"></div>
                                        </>
                                    ))}
                                </>
                            : null
                        }
                        {
                            activeDisclaimer ?
                                <div className="charting-page-block">
                                    <Disclaimer instanceObject={disclaimerInstance} pageLength={addInstances.length} handleRemoveDisclaimer={handleRemoveDisclaimer}/>
                                </div>
                            : null
                        }
                    </div>
                </div>
            {/* // </MainLayout> */}
        </>
    )
}

export default ChartingHome;
