export const RegisterationListData = [
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: false,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: false,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: false,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: false,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
  {
    id: 1,
    name: "Esther",
    email: "esther@gmail.com",
    registeration: "Aug 19, 2022",
    status: true,
  },
];

export const FinancialDummyData = [
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 2,
    classification: "Expense",
    description: "Cost of Sales",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 3,
    classification: "Gross Profit",
    description: "Marketing",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 4,
    classification: "Expense",
    description: "Admin Expense",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 5,
    classification: "Profit",
    description: "Operating Profit",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 6,
    classification: "Expense",
    description: "Depreciation",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 7,
    classification: "Income",
    description: "Other Expenses",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 8,
    classification: "Expense/(Income)",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
  {
    id: 1,
    classification: "Income",
    description: "Net sale",
    data: [
      {
        year: "30/june/08",
        value: 2957,
      },
      {
        year: "30/june/09",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/10",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/11",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/12",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/13",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/14",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/15",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/16",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/17",
        value: Math.floor(Math.random() * 10000),
      },
      {
        year: "30/june/18",
        value: Math.floor(Math.random() * 10000),
      },
    ],
  },
];
