import React, { useState} from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import {
  Formik,
  Form
} from "formik";
import * as Yup from "yup";
import InputField from "../../../components/InputField/InputField";
import SelectField from "../../../components/SelectField/SelectField";
import { toast } from "react-toastify";
import { contactDirectoryUpdateAPI } from "../../../api/ApiList";
import { useCookies } from "react-cookie";

interface IEditObj {
  cell_no: string;
  department: string;
  designation: string;
  email: string;
  extension?: string;
  id: number;
  name: string;
  organization: string;
}

interface ISelectListMain {
  id: number;
  name: string;
}

interface IDataList {
  IEditObj;
}

type AppProps = {
  data: IDataList;
  editModalShow: Boolean;
  editData: IEditObj;
  setData: Function;
  closeModal: Function;
  organizationList: ISelectListMain[];
  departmentList: ISelectListMain[];
  designationList: ISelectListMain[];
};

const ProjectEditModal = ({
  closeModal,
  editModalShow,
  editData,
  setData,
  data,
  organizationList,
  departmentList,
  designationList
}: AppProps) => {

  const [able, setAble] = useState(false);
  const [cookies, setCookies] = useCookies();

  // /* let history = useHistory(); */
  // console.log(props)
  // const [updatedData, setupdatedData] = useState();
  // const [field, meta] = useField(props);

  ////validation////////
  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    organization: Yup.string().required("Organization is required"),
    department: Yup.string().required("Department is required"),
    designation: Yup.string().required("Designation is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    cell: Yup.string().required("Cell is required"),
  });
  ////end-validation////////

  console.log("Edit Modal Data", editData);
  console.log(contactDirectoryUpdateAPI , "asdasd")
  console.log(editData[0].id , "asdasd")

  const close = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        size="lg"
        centered
        className="table-modal edit-project-modal"
        show={editModalShow ? true : false}
      >
        <Modal.Header>
          <Modal.Title
            className="text-left d-inline-block w-100 px-2"
            onClick={close}
          >
            Edit Contact Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Formik
              initialValues={{
                name: editData[0].name,
                organization: editData[0].organization,
                department: editData[0].department,
                designation: editData[0].designation,
                email: editData[0].email,
                cell_no: editData[0].cell_no,
                extension: editData[0].extension,
              }}
              validationSchema={validate}
              onSubmit={ (values, { setFieldError }) => {
                debugger;
                // const _payload = new FormData();
                // _payload.append("name", values.name);
                // _payload.append("organization", values.organization);
                // _payload.append("department", values.department);
                // _payload.append("designation", values.designation);
                // _payload.append("email", values.email);
                // _payload.append("cell_no", values.cell_no);
                // _payload.append("extension", values.extension);
                // setAble(true);
                axios({
                  headers: { 
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${cookies.accessToken}`
                  },
                  method: "put",
                  url: `${contactDirectoryUpdateAPI}/${editData[0].id}`,
                  params: values,
                })
                  .then(function (response) {
                    // console.log(response , "response after contact add");
                    setData((data) => [response.data.record, ...data]);
                    toast.success("Contact Added!" , {
                      position: toast.POSITION.BOTTOM_RIGHT
                    });
                    closeModal();
                    setAble(false);
                  })
                  .catch(function (error) {
                    console.log(error);
                    // if(error.response.status === 400){
                    //   var result = error.response.data.error;
                    //   Object.keys(result).map(function (key) {
                    //     setFieldError(key, result[key][0]);
                    //   });
                    //   setAble(false);
                    // }
                    // else{
                    //   closeModal();
                    //   toast.error("There is an Error");
                    // }
              });
            }}
            >
              {(formik) => (
                <>
                  <Form>
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <InputField
                          type="text"
                          label="Name"
                          name="name"
                          placeholder="Enter Employee Name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <SelectField
                          label="Organization"
                          name="organization"
                          placeholder="Enter Organization"
                          projectlist={organizationList}
                          onChange={formik.handleChange}
                          value={formik.values.organization}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <SelectField
                          label="Department"
                          name="department"
                          placeholder="Enter Department"
                          projectlist={departmentList}
                          onChange={formik.handleChange}
                          value={formik.values.department}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <SelectField
                          label="Designation"
                          name="designation"
                          placeholder="Enter Designation"
                          projectlist={designationList}
                          onChange={formik.handleChange}
                          value={formik.values.designation}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <InputField
                          type="email"
                          label="Email"
                          name="email"
                          placeholder="Enter Email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <InputField
                          type="number"
                          label="Cell No"
                          name="cell_no"
                          placeholder="Enter Extension"
                          onChange={formik.handleChange}
                          value={formik.values.cell_no}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      {/* <div className="form-group col-lg-6"></div> */}
                      <div className="form-group col-lg-6">
                        <InputField
                          type="number"
                          label="Extension"
                          name="extension"
                          placeholder="Enter Extension"
                          onChange={formik.handleChange}
                          value={formik.values.extension}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-label m-0 d-inline-flex w-100"></label>
                        <div className="d-inline-flex w-100 justify-content-end position-relative">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={able}
                            name="submit"
                            style={{ background: "#3969d5" }}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-light btn-cancel ml-2"
                            name="cancel"
                            onClick={close}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectEditModal;
