import axios from "axios";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import CompanyInformationSearchbar from "../../components/CompanyInformationSearchbar/CompanyInformationSearchbar";
import CompanyIntroductionEditor from "../../components/CompanyIntroductionEditor/CompanyIntroductionEditor";
import CostDriverEditor from "../../components/CostDriverEditor/CostDriverEditor";
import RevenueDriverEditor from "../../components/RevenuDriverEditor/RevenueDriverEditor";
import {
  ISelectedCompany,
  ICompanyInformation,
} from "../../Interfaces/AnalystPanelInterfaces";
import "./CompanyInformationSection";
import { CompanyInformationUpdateAPI } from "../../../../api/ApiList";
import DraftSidebar from "../../components/DraftSidebar/DraftSidebar";
import historyClock from "../../.././../assets/history-clock.svg";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import Pusher from "pusher-js";
import CompanyInformationNotifications from "../../components/CompanyInformationNotifications/CompanyInformationNotifications";
import { DoDecryption } from "../../../../AuthFunctions";

const loader = require("../../../../assets/loader.gif");

export default function CompanyInformationSection() {
  const [companySelected, setCompanySelected] = useState<ISelectedCompany>();
  const [companyInformation, setCompanyInformation] = useState<
    ICompanyInformation | any
  >();
  const [companyInformationPayload, setCompanyInformationPayload] =
    useState<any>({});
  const [fetchLoader, setFetchLoader] = useState<boolean>(true);
  const [draftSidebarShow, setDraftSidebarShow] = useState<boolean>(true);
  const [cookies, setCookies] = useCookies();
  const [triggerUpdate, setTriggerUpdate] = useState<any>(0);
  const [defaultCompanySelect, setDefaultCompanySelect] = useState<number>(0);
  const [notify, setNotify] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any>();
  const [userHasRoles, setUserHasRoles] = useState<any>();
  const [draftModeFlage, setDraftModeFlag] = useState(false);
  const [draftApprovalId, setDraftApprovalId] = useState<any>();
  const [searchBarValue, setSearchBarValue] = useState<any>("");
  const [selectedDraftId, setSelectedDraftId] = useState<any>();
  const [searchBarValueChangeFlag, setSearchBarValueChangeFlag] =
    useState<any>(0);

  let { draftApprovalIdParam } = useParams();

  console.log(userHasRoles, "< ======== ROLES");

  // console.log("approval ID param", draftApprovalId);

  const selectCompany = (company): void => {
    setDraftModeFlag(false);
    setCompanySelected(company);
    fetchCompanyInformation(company.id);
  };

  const resetSearchBarValue = () => {
    setSearchBarValueChangeFlag(searchBarValueChangeFlag + 1);
    // alert(searchBarValueChangeFlag);
    setSearchBarValue("");
  };

  const appendSelectedDraftId = (id) => {
    setSelectedDraftId(id);
  };

  const draftSelectCompany = (company) => {
    setCompanySelected(company);
  };

  const appendDraftApprovalId = (id) => {
    setDraftApprovalId(id);
  };

  const draftApprovalMode = (value) => {
    // console.log("toggling draft mode ");
    setDraftModeFlag(value);
  };

  const toggleDraftSidebar = () => {
    // console.log("TOGGLING SIDEBAR SHOW =====>", draftSidebarShow);
    setDraftSidebarShow(!draftSidebarShow);
  };

  const hideDraftSidebar = () => {
    // console.log("Hidding sidebar ===>");
    setDraftSidebarShow(true);
  };

  const createPayload = (key, value: void) => {
    setCompanyInformationPayload({
      ...companyInformationPayload,
      [key]: value,
    });
  };

  const fetchCompanyInformation = (company_id): void => {
    setFetchLoader(false);
    axios
      .post(
        "https://brain.akseerresearch.com/api/askanalyst/information/searched/company",
        { search: company_id }
      )
      .then((response) => {
        setDefaultCompanySelect(() => {
          return defaultCompanySelect + 1;
        });
        setFetchLoader(true);

        setCompanyInformation({
          company_introduction: response.data.api[0].company_introduction
            ? response.data.api[0].company_introduction
            : "",
          cost_drivers: response.data.api[0].cost_drivers
            ? response.data.api[0].cost_drivers
            : "",
          revenue_drivers: response.data.api[0].revenue_drivers
            ? response.data.api[0].revenue_drivers
            : "",
        });
      })
      .catch((err) => {
        setFetchLoader(true);
        console.log(err);
      });
  };

  const fetchCompanyInformationForDraft = (company_id): void => {
    setFetchLoader(true);
    // setDraftModeFlag(true);
    // console.log("GETING COMPANYY INFORMATION =============>", company_id);
    axios
      .post(
        "https://brain.akseerresearch.com/api/askanalyst/information/searched/company",
        { search: company_id }
      )
      .then((response) => {
        setFetchLoader(true);
        // console.log(console.log("Company Information ====>", response));
        resetSearchBarValue();
        draftSelectCompany({
          ...companyInformation,
          name: response?.data?.api[0]?.name,
          sector: response?.data?.api[0]?.sector,
          sector_id: response?.data?.api[0]?.sector_id,
          symbol: response?.data?.api[0].symbol,
          id: response?.data?.api[0].id,
        });
      })
      .catch((err) => {
        setFetchLoader(true);
        console.log(err);
      });
  };

  const fetchDraftCompanyInformation = (company_id): void => {
    setFetchLoader(false);

    axios
      .post(
        "https://brain.akseerresearch.com/api/askanalyst/information/company/draft/record",
        { draft_id: company_id }
      )
      .then((response) => {
        setFetchLoader(true);
        fetchCompanyInformationForDraft(response.data[0].comp_id);
        resetSearchBarValue();
        setCompanyInformation({
          ...companyInformation,
          company_introduction: response.data[0].company_introduction
            ? response.data[0].company_introduction
            : "",
          cost_drivers: response.data[0].cost_drivers
            ? response.data[0].cost_drivers
            : "",
          revenue_drivers: response.data[0].revenue_drivers
            ? response.data[0].revenue_drivers
            : "",
        });
      })
      .catch((err) => {
        setFetchLoader(true);
        console.log(err);
      });
  };

  const sendForApproval = (): void => {
    if (companySelected && companySelected.id) {
      var _payload = new FormData();
      _payload.append("comp_id", `${companySelected.id}`);
      _payload.append("user_id", `${cookies?.userInformation?.id}`);
      if (companyInformationPayload.company_introduction) {
        if (
          companyInformationPayload.company_introduction !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "company_introduction",
            companyInformationPayload.company_introduction
          );
        } else {
          _payload.append("company_introduction", "");
        }
      }
      if (companyInformationPayload.cost_drivers) {
        if (
          companyInformationPayload.cost_drivers !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "cost_drivers",
            companyInformationPayload.cost_drivers
          );
        } else {
          _payload.append("cost_drivers", "");
        }
      }
      if (companyInformationPayload.revenue_drivers) {
        if (
          companyInformationPayload.revenue_drivers !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "revenue_drivers",
            companyInformationPayload.revenue_drivers
          );
        } else {
          _payload.append("revenue_drivers", "");
        }
      }
      axios({
        url: "https://brain.akseerresearch.com/api/askanalyst/information/company/approve",
        method: "post",
        data: _payload,
      })
        .then((res) => {
          setTriggerUpdate(() => triggerUpdate + 1);
          toast.success(res.data.Success, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((err) => {
          Object.keys(err.response.data).map((e) => {
            // console.log(e);
            toast.error(err.response.data[e][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
        });
    } else {
      toast.error("Please select a company From searchbar", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  // console.log("DRAFT APPROVALL ID ======>", draftApprovalIdParam);

  // -------------------------------- Final Submission/Approval ----------------------------

  const approveFinalDraft = (): void => {
    if (companySelected && companySelected.id) {
      var _payload = new FormData();
      _payload.append("comp_id", `${companySelected.id}`);
      if (companyInformationPayload.company_introduction) {
        if (
          companyInformationPayload.company_introduction !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "company_introduction",
            companyInformationPayload.company_introduction
          );
        } else {
          _payload.append("company_introduction", "");
        }
      }
      if (companyInformationPayload.cost_drivers) {
        if (
          companyInformationPayload.cost_drivers !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "cost_drivers",
            companyInformationPayload.cost_drivers
          );
        } else {
          _payload.append("cost_drivers", "");
        }
      }
      if (companyInformationPayload.revenue_drivers) {
        if (
          companyInformationPayload.revenue_drivers !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "revenue_drivers",
            companyInformationPayload.revenue_drivers
          );
        } else {
          _payload.append("revenue_drivers", "");
        }
      }
      axios({
        url: CompanyInformationUpdateAPI,
        method: "post",
        data: _payload,
      })
        .then((res) => {
          toast.success(res.data.Success, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((err) => {
          Object.keys(err.response.data).map((e) => {
            // console.log(e);
            toast.error(err.response.data[e][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
        });
    } else {
      toast.error("Please select a company From searchbar", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  // const approveFinalDraft = (id): void => {
  //   const formData = new FormData();
  //   // formData.append("approval_id", draftApprovalId);
  //   // formData.append("comp_id");
  //   // console.log("Approving Final Draft ===>");
  //   axios({
  //     // url: "https://brain.akseerresearch.com/api/askanalyst/approval/company/information",
  //     url: "https://brain.akseerresearch.com/api/askanalyst/information/company/updated",
  //     method: "POST",
  //     // data: { approval_id: draftApprovalId },
  //     data: formData,
  //   })
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // };

  // -------------------------------- Final Submission/Approval ----------------------------

  useEffect(() => {
    const pusher = new Pusher("cf736425b7e4224a6bb2", {
      cluster: "ap2",
      forceTLS: true,
      // encrypted: "true",
    });
    const channel = pusher.subscribe("financial-updates-channel");
    channel.bind("financial-updates", (data) => {
      setNotify(true);
      // console.log(JSON.stringify(data.message) + " ------ pusher data ");
    });

    setUserHasRoles(
      DoDecryption(localStorage.getItem("UserAuth")).authorization
    );
  }, []);

  // console.log("USER HAS ROLEEEEEEEEE ====>", userHasRoles);

  useEffect(() => {
    if (draftApprovalIdParam) {
      alert("running");
      // console.log("DRAFT WITH ID ======>");
      axios
        .post(
          "https://brain.akseerresearch.com/api/askanalyst/information/company/draft/record",
          { draft_id: draftApprovalIdParam }
        )
        .then((res) => {
          // console.log("DRAFT BY ID RECIEVED =====>", res);
          fetchDraftCompanyInformation(draftApprovalIdParam);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [draftApprovalIdParam]);

  // useEffect(() => {
  //   if (companySelected) {
  //     fetchCompanyInformation(companySelected?.id);
  //   }
  // }, [defaultCompanySelect])

  // useEffect(() => {
  //   if(draftMode) {
  //     if (companySelected) {
  //       fetchCompanyInformation(companySelected?.id);
  //     }
  //   } else {
  //     fetchCompanyInformationForDraft(companySelected?.id)
  //   }

  // }, [companySelected]);

  const submitCompanyInformation = (): void => {
    if (companySelected && companySelected.id) {
      // console.log("Selected Payload", {
      //   ...companyInformationPayload,
      //   ["comp_id"]: companySelected.id,
      // });
      var _payload = new FormData();
      _payload.append("comp_id", `${companySelected.id}`);
      if (companyInformationPayload.company_introduction) {
        if (
          companyInformationPayload.company_introduction !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "company_introduction",
            companyInformationPayload.company_introduction
          );
        } else {
          _payload.append("company_introduction", "");
        }
      }
      if (companyInformationPayload.cost_drivers) {
        if (
          companyInformationPayload.cost_drivers !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "cost_drivers",
            companyInformationPayload.cost_drivers
          );
        } else {
          _payload.append("cost_drivers", "");
        }
      }
      if (companyInformationPayload.revenue_drivers) {
        if (
          companyInformationPayload.revenue_drivers !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "revenue_drivers",
            companyInformationPayload.revenue_drivers
          );
        } else {
          _payload.append("revenue_drivers", "");
        }
      }
      axios({
        url: CompanyInformationUpdateAPI,
        method: "post",
        data: _payload,
      })
        .then((res) => {
          toast.success(res.data.Success, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((err) => {
          Object.keys(err.response.data).map((e) => {
            console.log(e);
            toast.error(err.response.data[e][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
        });
    } else {
      toast.error("Please select a company From searchbar", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const submitCompanyInformationDraft = (): void => {
    if (companySelected && companySelected.id) {
      // console.log("Selected Payload", {
      //   ...companyInformationPayload,
      //   ["comp_id"]: companySelected.id,
      // });
      var _payload = new FormData();
      _payload.append("comp_id", `${companySelected.id}`);
      _payload.append("user_id", `${cookies?.userInformation?.id}`);
      if (companyInformationPayload.company_introduction) {
        if (
          companyInformationPayload.company_introduction !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "company_introduction",
            companyInformationPayload.company_introduction
          );
        } else {
          _payload.append("company_introduction", "");
        }
      }
      if (companyInformationPayload.cost_drivers) {
        if (
          companyInformationPayload.cost_drivers !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "cost_drivers",
            companyInformationPayload.cost_drivers
          );
        } else {
          _payload.append("cost_drivers", "");
        }
      }
      if (companyInformationPayload.revenue_drivers) {
        if (
          companyInformationPayload.revenue_drivers !==
          (null || undefined || "undefined" || "")
        ) {
          _payload.append(
            "revenue_drivers",
            companyInformationPayload.revenue_drivers
          );
        } else {
          _payload.append("revenue_drivers", "");
        }
      }
      axios({
        url: "https://brain.akseerresearch.com/api/askanalyst/information/company/draft",
        method: "post",
        data: _payload,
      })
        .then((res) => {
          setTriggerUpdate(() => triggerUpdate + 1);
          toast.success(res.data.Success, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((err) => {
          Object.keys(err.response.data).map((e) => {
            // console.log(e);
            toast.error(err.response.data[e][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
        });
    } else {
      toast.error("Please select a company From searchbar", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  let companyInfoClone = {};

  const collectData = (key, value) => {
    companyInfoClone = { ...companyInformation, [key]: value };
    setCompanyInformation({ ...companyInfoClone, [key]: value });
  };

  // -------------------------- Notifications Logic -------------------------------

  const notificationRequest = () => {
    axios
      .get("https://brain.akseerresearch.com/api/notifications", {
        headers: {
          Authorization: `Bearer ${
            cookies?.auth?.access_token ?? cookies?.auth?.access_token
          }`,
        },
      })
      .then(function (response) {
        // console.log(response.data.response, "notification response");
        setNotifications(response.data.response);
        setNotify(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid">
      <DraftSidebar
        fetchDraftCompanyInformation={fetchDraftCompanyInformation}
        selectCompany={draftSelectCompany}
        triggerUpdate={triggerUpdate}
        draftSidebarShow={draftSidebarShow}
        toggleDraftSidebar={toggleDraftSidebar}
        draftApprovalMode={draftApprovalMode}
        appendDraftApprovalId={appendDraftApprovalId}
        hideDraftSidebar={hideDraftSidebar}
      />
      <div className="row">
        <div className="col-6 px-0" style={{ marginTop: "30px" }}>
          <CompanyInformationSearchbar
            searchBarValue={searchBarValue}
            onSelect={selectCompany}
            searchBarValueChangeFlag={searchBarValueChangeFlag}
          />
        </div>
        <div className="col-6">
          <div>
            <CompanyInformationNotifications
              notify={notify}
              notificationRequest={notificationRequest}
              setNotify={setNotify}
              draftApprovalMode={draftApprovalMode}
              resetSearchBarValue={resetSearchBarValue}
              draftApprovalIdParam={draftApprovalIdParam}
              fetchDraftCompanyInformation={fetchDraftCompanyInformation}
            />
          </div>
          <div
            className="d-flex flex-row-reverse"
            style={{ marginTop: "30px" }}
          >
            <button
              className={`btn view-draft-btn ${
                !draftSidebarShow ? "d-none" : ""
              } `}
              onClick={() => toggleDraftSidebar()}
            >
              <span>
                <img src={historyClock} alt="" />
              </span>
              View Drafts
            </button>
          </div>
        </div>
      </div>
      {companySelected ? (
        <>
          <div className={`row ${!fetchLoader ? "d-none" : ""}`}>
            <div className="col-6 px-0">
              <h3 className="theme-primary-text mt-5 mb-3 ml-0">
                {companySelected && companySelected.name
                  ? companySelected.name
                  : "**Please select a company from searchbar"}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 px-0">
              <div className="company-information-tabs">
                {fetchLoader ? (
                  <Tabs
                    defaultActiveKey="company_introduction"
                    id="topbar-tabs"
                  >
                    <Tab
                      eventKey="company_introduction"
                      title="Company Introduction"
                    >
                      <CompanyIntroductionEditor
                        companySelected={companySelected}
                        createPayload={createPayload}
                        submitCompanyInformation={submitCompanyInformation}
                        submitCompanyInformationDraft={
                          submitCompanyInformationDraft
                        }
                        sendForApproval={sendForApproval}
                        approveFinalDraft={approveFinalDraft}
                        userHasRoles={userHasRoles}
                        handleChange={collectData}
                        draftModeFlage={draftModeFlage}
                        initial={
                          companyInformation &&
                          companyInformation.company_introduction &&
                          companyInformation.company_introduction !== ""
                            ? companyInformation.company_introduction
                            : ""
                        }
                      />
                    </Tab>
                    <Tab eventKey="revenue_drivers" title="Revenue Drivers">
                      <RevenueDriverEditor
                        companySelected={companySelected}
                        createPayload={createPayload}
                        submitCompanyInformation={submitCompanyInformation}
                        submitCompanyInformationDraft={
                          submitCompanyInformationDraft
                        }
                        sendForApproval={sendForApproval}
                        approveFinalDraft={approveFinalDraft}
                        handleChange={collectData}
                        userHasRoles={userHasRoles}
                        initial={
                          companyInformation &&
                          companyInformation.revenue_drivers &&
                          companyInformation.revenue_drivers !== ""
                            ? companyInformation.revenue_drivers
                            : ""
                        }
                        draftModeFlage={draftModeFlage}
                      />
                    </Tab>
                    <Tab eventKey="cost_drivers" title="Cost Drivers">
                      <CostDriverEditor
                        companySelected={companySelected}
                        createPayload={createPayload}
                        submitCompanyInformation={submitCompanyInformation}
                        submitCompanyInformationDraft={
                          submitCompanyInformationDraft
                        }
                        sendForApproval={sendForApproval}
                        approveFinalDraft={approveFinalDraft}
                        handleChange={collectData}
                        userHasRoles={userHasRoles}
                        draftModeFlage={draftModeFlage}
                        initial={
                          companyInformation &&
                          companyInformation.cost_drivers &&
                          companyInformation.cost_drivers !== ""
                            ? companyInformation.cost_drivers
                            : ""
                        }
                      />
                    </Tab>
                  </Tabs>
                ) : (
                  <div style={styles.loaderParentStyle}>
                    <img src={loader} alt="" />
                  </div>
                )}
              </div>
            </div>
            {/* <button className='btn btn-primary' onClick={() => { console.log(companyInformation) }}>Show company information</button> */}
          </div>
        </>
      ) : (
        <div style={styles.selectCompanyMessage}>
          <h4>Please select a company from search . . .</h4>
        </div>
      )}
    </div>
  );
}

const styles = {
  primaryTitle: {
    fontSize: "25px",
    textTransform: "uppercase",
  },
  loaderParentStyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectCompanyMessage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "25em",
    color: "#007bff78",
  },
};
