import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import ProjectAddModal from "./Add";
import ProjectEditModal from "./Edit";
import axios from "axios";
import DataTable from "react-data-table-component";
import "./Project.css";
import { projectListAPI } from "../../../../api/ApiList";
import ActiveSwitch from "../../../../components/ActiveSwitch/ActiveSwitch";
import ViewRolesModal from "../../components/ViewRolesModal/ViewRolesModal";

const loader = require("../../../../assets/loader.gif");

const ProjectList = () => {
  let [data, setData] = useState<any>();
  let [editData, setEditData] = useState([]);
  let [addModalShow, setaddModalShow] = useState(false);
  let [editModalShow, setEditModalShow] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [viewRoleList, setViewRoleList] = useState<any>();
  const [able, setAble] = useState(false);

  const list = async () => {
    const response = await axios.get(projectListAPI);
    console.log("Project List =======>", response.data);
    setData(response.data);
  };

  useEffect(() => {
    list();
  }, []);

  const paginationComponentOptions = {
    rowsPerPageText: "Show Entries",
  };

  const columns = [
    {
      name: "#",
      selector: (row) => (row.id ? row.id : "N/A"),
    },
    {
      name: "Project",
      selector: (row) => (row.name ? row.name : "N/A"),
    },
    {
      name: "Project Image",
      selector: (row) => (
        <div style={styles.projectImageWrapper}>
          <img
            className="w-100"
            src={
              row.image
                ? `https://brain.akseerresearch.com/${row.image}`
                : "https://reactnativecode.com/wp-content/uploads/2018/02/Default_Image_Thumbnail.png"
            }
          ></img>
        </div>
      ),
    },
    {
      name: "Date Added",
      selector: (row) =>
        // row.updated_at.split("T")[0] ? row.updated_at.split("T")[0] : "N/A",
        "123",
    },
    {
      name: "Status",
      selector: (row) => {
        return <ActiveSwitch status="true" />;
      },
    },
    {
      name: "View Roles",
      selector: (row) =>
        row.cat_id == undefined ? (
          <Button
            variant="light"
            name="viewrole"
            className="view-role"
            onClick={(e) => {
              showViewRoleModal();
              setViewRoleList(row.roles);
            }}
          >
            View Roles
          </Button>
        ) : (
          "N/A"
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <span onClick={() => editModal(row.id)} className="edit-action">
            <svg
              width="15px"
              height="15px"
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="pencil"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="svg-inline--fa fa-pencil fa-w-16"
            >
              <path
                fill="#3969D5"
                d="M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zm-95.196 140.45L174 420.745V386h-48v-48H91.255l224.059-224.059 82.745 82.745zM126.147 468.598l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"
                className=""
              ></path>
            </svg>
          </span>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  function closeModal() {
    setaddModalShow(false);
    setEditModalShow(false);
    list();
  }

  function editModal(e) {
    console.log(e);

    const dataClone = [...data];
    let obj = dataClone.findIndex((obj) => obj.id == e);
    setEditData(dataClone[obj]);
    setEditModalShow(true);
  }

  const showViewRoleModal = () => {
    console.log("SHOW ROLE MODAL ====>", showModal);
    setShowModal(!showModal);
  };

  if (data) {
    return (
      <div className="d-inline-flex flex-wrap w-100 px-4">
        <div className="d-inline-flex flex-wrap w-100 project-main">
          <div className="d-inline-flex w-100 add-project-filter px-0">
            <Button
              variant="primary"
              name="addproject"
              className="add-project"
              onClick={() => setaddModalShow(true)}
            >
              + Add Project
            </Button>
          </div>
          <div className="brain-table-card">
            <div className="brain-table-card-header">
              <h3 className="title">List of Projects</h3>
            </div>
            <div className="brain-table-card-body">
              <DataTable
                className="table w-100 mb-0"
                data={data}
                columns={columns}
                pagination
                paginationComponentOptions={paginationComponentOptions}
              />
            </div>
          </div>
        </div>
        <ProjectAddModal
          addModalShow={addModalShow}
          closeModal={closeModal}
          setData={setData}
          data={data}
        />
        <ViewRolesModal
          bodyContent={viewRoleList}
          show={showModal}
          toggleShow={showViewRoleModal}
        />
        <ProjectEditModal
          editModalShow={editModalShow}
          closeModal={closeModal}
          editData={editData}
          setData={setData}
          data={data}
        />
      </div>
    );
  } else {
    return (
      <div className="full-screen-loader">
        <img src={loader} alt="loader . . . " />
      </div>
    );
  }
};

const styles = {
  projectImageWrapper: {
    width: "50px",
    height: "50px",
    overflow: "hidden",
  },
};

export default ProjectList;
