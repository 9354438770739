import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import TableFilter from "../../../../../../components/TableFilter/TableFilter";
import DataTable from "react-data-table-component";
import { ManageUserListAPI } from "../../../../../../api/ApiList";
// import CompaniesList from  '../CompaniesList';
import "./ManageUserTable.css";
import ViewAssign from "../ViewAssignments/ViewAssignments";
import EditAssignUser from "../EditAssignUser/EditAssignUser";
import { FindRequiredPermission } from "../../../../../../AuthFunctions";

export default function UserTable({ assignModalShow, userHasRoles }) {
  const [data1, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [viewAssign, setViewAssign] = useState<boolean>(false);
  const [modalData, setModalData] = useState([{}]);
  const [_modalData, _setModalData] = useState();
  const [editShow, setEditShow] = useState(false);
  const [editDataId, setEditDataId] = useState<number | undefined>();
  const [editUserSectorList, setEditUserSectorList] = useState<any>();
  const [editUserCompanyList, setEditCompanySectorList] = useState<any>();
  const [payload, setPayload] = useState<any>();
  const [defaultPayload, setDefaultPayload] = useState<any>();

  // ------------------------ Functionality to create payload -------------------------

  const createPayload = (newObject = "") => {
    console.log("creaing payload ====>", newObject);
    setPayload({ ...payload, company_id: newObject });
  };

  const setPayloadUserId = (user_id) => {
    setPayload({ ...payload, user_id: user_id });
  };

  const handleDefaultPayload = (values) => {
    const tempArr: any = [];

    values.map((c, i) => {
      tempArr.push(c.value);
    });

    setDefaultPayload({
      ...defaultPayload,
      company_id: JSON.stringify(tempArr).replace(/[\])}[{(]/g, ""),
    });
  };

  const createDefaultPayload = (value, userId) => {
    const tempArr: any = [];
    value.map((c, i) => {
      tempArr.push(c.value);
    });
    console.log(
      "DEFAULT VALUES RECIEVED IN FUNCTION ====>",
      JSON.stringify(tempArr).replace(/[\])}[{(]/g, "")
    );

    setPayload({
      ...payload,
      user_id: userId,
      company_id: JSON.stringify(tempArr).replace(/[\])}[{(]/g, ""),
    });
  };

  const payloadFormat = {
    user_id: 12,
    company_id: "1,2,3,4,5,6,7,8,9,10",
  };

  // ------------------------ END OF Functionality to create payload -------------------------

  const filteredItems = data1.filter(
    (d: any) =>
      d.name && d.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const list = async () => {
    const response = await axios.get(ManageUserListAPI);
    setData(response.data);
  };

  useEffect(() => {
    list();
  }, [assignModalShow]);

  const subHeaderComponentMemo = useMemo(() => {
    let data = data1;
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    if (filterText != "") {
      setData(
        data.filter(
          (d: any) =>
            d.name && d.name.toLowerCase().includes(filterText.toLowerCase())
        )
      );
    } else {
      list();
    }

    return (
      <TableFilter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const paginationComponentOptions = {
    rowsPerPageText: "Show Entries",
    paginationRowsPerPageOptions: [15, 30, 50, 100],
    selectAllRowsItem: true,
  };

  const ViewModal = (i) => {
    setViewAssign(true);
    let dataClone: any = [...data1];
    // console.log(dataClone , "dataClone");
    dataClone = dataClone.filter((d: any) => d.id === i);

    axios
      .get(
        `https://brain.akseerresearch.com/api/askanalyst/users/${dataClone[0]?.id}`
      )
      .then((res) => {
        console.log(res?.data?.data);
        _setModalData(res?.data?.data);
      })
      .catch((err) => console.log(err));

    console.log(dataClone);
    setModalData(dataClone);

    // sortedSectorList.filter(s => s.value != d.value);
    // console.log(dataClone , "dataClone modal");
  };

  const emptyViewModalState = () => {
    _setModalData(undefined);
  };

  const columnsContributor = [
    {
      name: <span className="font-bold">{`S.no`}</span>,
      selector: (row, index) => index,
      sortable: true,
      grow: 0.5,
    },
    {
      name: <span className="font-bold">Name</span>,
      selector: (row) => row.name,
      sortable: true,
      grow: 1,
    },
    {
      name: <span className="font-bold">Role</span>,
      selector: (row) => (row.roles.length > 0 ? row.roles[0].role : "N/A"),
      sortable: true,
      grow: 2,
    },
    {
      name: <span className="font-bold">Sector & Companies</span>,
      selector: (row) => (
        <button
          style={styles.assignBtn}
          className="btn"
          onClick={() => ViewModal(row.id)}
        >
          View
        </button>
      ),
      sortable: false,
      grow: 1,
    }
  ];

  const columnsAdmin = [
    {
      name: <span className="font-bold">{`S.no`}</span>,
      selector: (row, index) => index,
      sortable: true,
      grow: 0.5,
    },
    {
      name: <span className="font-bold">Name</span>,
      selector: (row) => row.name,
      sortable: true,
      grow: 1,
    },
    {
      name: <span className="font-bold">Role</span>,
      selector: (row) => (row.roles.length > 0 ? row.roles[0].role : "N/A"),
      sortable: true,
      grow: 2,
    },
    {
      name: <span className="font-bold">Sector & Companies</span>,
      selector: (row) => (
        <button
          style={styles.assignBtn}
          className="btn"
          onClick={() => ViewModal(row.id)}
        >
          View
        </button>
      ),
      sortable: false,
      grow: 1,
    },
    {
      name: "Actions",
      cell: (row) => {
          return (
            <>
              <span onClick={() => editModal(row.id)} className="edit-action">
                <svg
                  width="15px"
                  height="15px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="pencil"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="svg-inline--fa fa-pencil fa-w-16"
                >
                  <path
                    fill="#3969d5c7"
                    d="M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zm-95.196 140.45L174 420.745V386h-48v-48H91.255l224.059-224.059 82.745 82.745zM126.147 468.598l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"
                    className=""
                  ></path>
                </svg>
              </span>
            </>
          );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  const editModal = async (e) => {
    console.log(e);
    // const response = await axios.get(`${ManageUserEditFetchAPI}/${e}`);
    // console.log(response , "edit user");
    setEditDataId(e);
    // axios({
    //   headers: { "Content-Type": "aplication/json" },
    //   method: "PUT",
    //   url: `${roleseditAPI}/${props.editdata.id}`,
    //   data: values,
    // })
    // const dataClone = [...data];
    // let obj = dataClone.findIndex((obj) => obj.id == e);
    // setEditData(dataClone[obj]);
    setEditShow(true);
  };

  const DateFilter = () => {
    return (
      <div>
        <input className="form-control" type="date" />
      </div>
    );
  };

  const SearchFilter = () => {
    return (
      <div>
        <input
          className="form-control search-filter"
          placeholder="Search by name . . ."
          type="text"
        />
      </div>
    );
  };

  const PerPageFilter = () => {
    return (
      <select
        style={{ width: "60px" }}
        className="ml-2 form-control"
        name="perPage"
        id="1"
      >
        <option value="1">5</option>
        <option value="1">10</option>
        <option value="1">15</option>
        <option value="1">20</option>
      </select>
    );
  };

  const TableHeader = () => {
    return (
      <div style={styles.tableHeader}>
        <DateFilter />
        <div style={styles.flex}>
          <SearchFilter />
          <PerPageFilter />
        </div>
      </div>
    );
  };

  return (
    <>
      <DataTable
        columns={(FindRequiredPermission(userHasRoles, "Ask Analyst", "Admin") ||
        FindRequiredPermission(userHasRoles, "Ask Analyst", "Approver")) ? columnsAdmin : columnsContributor}
        data={data1 ? data1 : []}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        pagination
        paginationPerPage={15}
        paginationResetDefaultPage={resetPaginationToggle}
        paginationComponentOptions={paginationComponentOptions}
      />
      <ViewAssign
        data={modalData}
        _modalData={_modalData}
        setViewAssign={setViewAssign}
        viewAssign={viewAssign}
        emptyViewModalState={emptyViewModalState}
      />
      {editShow ? (
        <EditAssignUser
          setEditShow={setEditShow}
          editShow={editShow}
          editDataId={editDataId}
          createPayload={createPayload}
          setPayloadUserId={setPayloadUserId}
          createDefaultPayload={createDefaultPayload}
          payload={payload}
          handleDefaultPayload={handleDefaultPayload}
          defaultPayload={defaultPayload}
        />
      ) : null}
    </>
  );
}

// ======================================
//              STYLINGS
// ======================================

const styles = {
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  downloadCSVParent: {
    width: "100%",
    display: "flex",
    /* justify-content: center; */
    alignItems: "center",
    flexDirection: "row-reverse",
  },
  assignBtn: {
    border: "0.25px solid rgb(57 109 213 / 30%)",
    background: "#ffffff",
    fontFamily: "'Lato', sans-serif",
    fontWeight: "500",
    fontSize: "12px",
    color: "#3969d5c7",
    borderRadius: "4px",
    height: "30px",
    padding: "6px 15px",
  },
};
