import React from "react";
import UserImg from '../../../../assets/media/profile_user.jpg';

export default function UpcomingBirthday() {
  console.log(UserImg , "UserImgUserImgUserImgUserImg")
  return (
    <div className="birthdays w-100">
      <h3 className="title">
        <span>Upcoming Birthdays & Anniversaries</span>
      </h3>
      <div className="birthday-list">
        <ul className="list-unstyled m-0">
          <li>
            <div className="birthday-detail">
              <div className="birthday-pic">
                <img src={UserImg} alt="" />
              </div>
              <div className="birthday-person">
                <h6>Ali</h6>
                <span>Birthday</span>
              </div>
            </div>
            <div className="birthday-date">
              <span>10-Feb</span>
            </div>
          </li>
          <li>
            <div className="birthday-detail">
              <div className="birthday-pic">
                <img src={UserImg} alt="" />
              </div>
              <div className="birthday-person">
                <h6>Ali</h6>
                <span>Birthday</span>
              </div>
            </div>
            <div className="birthday-date">
              <span>10-Feb</span>
            </div>
          </li>
          <li>
            <div className="birthday-detail">
              <div className="birthday-pic">
                <img src={UserImg} alt="" />
              </div>
              <div className="birthday-person">
                <h6>Ali</h6>
                <span>Birthday</span>
              </div>
            </div>
            <div className="birthday-date">
              <span>10-Feb</span>
            </div>
          </li>
          <li>
            <div className="birthday-detail">
              <div className="birthday-pic">
                <img src={UserImg} alt="" />
              </div>
              <div className="birthday-person">
                <h6>Ali</h6>
                <span>Birthday</span>
              </div>
            </div>
            <div className="birthday-date">
              <span>10-Feb</span>
            </div>
          </li>
          <li>
            <div className="birthday-detail">
              <div className="birthday-pic">
                <img src={UserImg} alt="" />
              </div>
              <div className="birthday-person">
                <h6>Ali</h6>
                <span>Birthday</span>
              </div>
            </div>
            <div className="birthday-date">
              <span>10-Feb</span>
            </div>
          </li>
          <li>
            <div className="birthday-detail">
              <div className="birthday-pic">
                <img src={UserImg} alt="" />
              </div>
              <div className="birthday-person">
                <h6>Ali</h6>
                <span>Birthday</span>
              </div>
            </div>
            <div className="birthday-date">
              <span>10-Feb</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
