import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import loader from '../../../../assets/loader.gif';

type IkeyList = {
    id: number | string;
    cat_id: number | string;
    name: string;
    description: string;
    status: number | string | boolean;
    detail_description: string;
    category: {
        id: number | string;
        name: string;
        status: number | string | boolean;
    }
}[]

const KeyUpdatesList = () => {

    const [myData, setMyData] = useState<IkeyList>([])
    const history = useNavigate();
    let valCheck;
    


    const columns = [
        {
            name: '#',
            selector: row => row.id ? row.id : "N/A",
        },
        {
            name: 'Title',
            selector: row => row.name ? row.name : "N/A",
        },
        {
            name: 'Description',
            selector: row => row.description ? row.description : "N/A",
        },
        {
            name: 'Category',
            selector: row => row.cat_id ? row.cat_id : "N/A",
        },
        {
            name: 'Date',
            selector: row => row.updated_at.split("T")[0] ? row.updated_at.split("T")[0] : "N/A",
        },    
        {
            name: 'Actions',
            cell: (row) => (
                <>
                <span onClick={() => handleEdit(row.id)} className='edit-action btn-action mx-1'>
                    <svg width="15px" height="15px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-pencil fa-w-16">
                        <path fill="#3969D5" d="M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zm-95.196 140.45L174 420.745V386h-48v-48H91.255l224.059-224.059 82.745 82.745zM126.147 468.598l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z" className=""></path>
                    </svg>
                </span>{'  '}
                <span onClick={()=>handleTrash(row.id)} className='delete-action btn-action mx-1'>
                    <svg width="15px" height="15px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-trash-alt fa-w-14">
                        <path fill="#3969D5" d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z" className=""></path>
                    </svg>
                </span>
              </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const valFunc = async () =>{
        await axios.get("https://brain.akseerresearch.com/api/keyupdates")
        .then((response)=>{
            setMyData(response.data)
        })
    }

    const handleTrash = (e) =>{
        axios.delete(`https://brain.akseerresearch.com/api/keyupdates/${e}`)
        .then((response)=>{
            const a = response.data
            console.log(a)
            valFunc()
        })
    }

    useEffect(() => {
        valFunc();
    }, [])
      

    // const data = myData;

    const handleEdit = (e) =>{
        history(`/keyupdates/editkeyupdate/${e}`);
    }
    
    const paginationComponentOptions = {
        rowsPerPageText: 'Show Entries',
    };


    if(myData && myData.length !== 0) {
        return (           
            <DataTable
            data={myData}
            columns={columns}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            />
        )
    } else {
        return(
            <div className='d-flex justify-content-center align-items-center'>
                <img src={loader} />
            </div>
        )        
    }


}

export default KeyUpdatesList;