import React , {useState} from 'react';
import icon from '../../../../assets/media/share_icon.svg';
import { useNavigate } from 'react-router-dom';
import './CompanySearch.css';

const CompanySearch = () => {
    const navigate = useNavigate();
    const [active , setActive] = useState(false);

    const handleChange = () => {
        setActive(true)
    }

    const handleBlur = () => {
        setActive(false)
    }

    const handleViewMore = () => {
        navigate('/global-search-listing')
    }

    return (
        <div className='company-search-block pr-3'>
            <div className="form-group m-0 col-md-12 p-0 primary-input">
                <input type="text" className="form-control" onChange={handleChange} onBlur={handleBlur} placeholder='Search...'/>
            </div>
            {active ? 
                <>
                    <div className='company-search-data'>
                        <div className='data-item company'>
                            <h6 className="data-title">Companies</h6>
                            <ul className='list-unstyled m-0'>
                                <li>
                                    <div className='d-inline-flex justify-content-between align-items-center'>
                                        <span className='symbol'>SFL</span>
                                        <span className='name'>Sapphire Fibres Ltd</span>
                                        <span className='sector'>Textile Composite</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-inline-flex justify-content-between align-items-center'>
                                        <span className='symbol'>SFL</span>
                                        <span className='name'>Sapphire Fibres Ltd</span>
                                        <span className='sector'>Textile Composite</span>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-inline-flex justify-content-between align-items-center'>
                                        <span className='symbol'>SFL</span>
                                        <span className='name'>Sapphire Fibres Ltd</span>
                                        <span className='sector'>Textile Composite</span>
                                    </div>
                                </li>
                            </ul>
                        </div>    
                        <div className='data-item global'>
                            <h6 className="data-title">Research Reports</h6>
                            <ul className='list-unstyled m-0'>
                                <li>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <span className='global-icon'><img src={icon} alt="icon"/></span>
                                        <div className='global-data'>
                                            <p className='global-name'>The Promise to Abraham</p>
                                            <p className="global-meta d-flex justify-content-start align-items-center">
                                                <span className='global-location'>Islamabad</span>
                                                -
                                                <span className='global-date'>October 20, 2010</span>                                    
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <span className='global-icon'><img src={icon} alt="icon"/></span>
                                        <div className='global-data'>
                                            <p className='global-name'>The Promise to Abraham</p>
                                            <p className="global-meta d-flex justify-content-start align-items-center">
                                                <span className='global-location'>Islamabad</span>
                                                -
                                                <span className='global-date'>October 20, 2010</span>                                    
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='data-item global'>
                            <h6 className="data-title">News</h6>
                            <ul className='list-unstyled m-0'>
                                <li>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <span className='global-icon'><img src={icon} alt="icon"/></span>
                                        <div className='global-data'>
                                            <p className='global-name'>The Promise to Abraham</p>
                                            <p className="global-meta d-flex justify-content-start align-items-center">
                                                <span className='global-location'>Islamabad</span>
                                                -
                                                <span className='global-date'>October 20, 2010</span>                                    
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <p className='view-more-btn'><button className='btn btn-ink' onClick={handleViewMore}>View more</button></p>
                    </div>
                </> 
            : null}
        </div>
    )
}

export default CompanySearch;