import React from 'react';
import './Style.css';

const TableFilter = (props) => {
    return (
        <div className='d-inline-flex flex-wrap align-items-center table-filter-block'>
            <input
                id="search"
                type="text"
                placeholder="Search"
                aria-label="Search Input"
                value={props.filterText}
                onChange={props.onFilter}
            />
            {props.filterText ? 
                <button type="button" onClick={props.onClear}>X</button>
            : null}
        </div>
    )
}

export default TableFilter;