import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import {
  DefaultFinancialDataAPI,
  SearchSuggestionAPI,
  FetchFinancialDataAPI,
} from "../../../../api/ApiList";
import FinancialDataSheet from "../../components/FinancialDataSheet/FinancialDataSheet";
import FinancialsFilter from "../../components/SearchBarWithFilters/FinancialsFilter";
import GlobalNotification from "../../components/GlobalNotification/GlobalNotification";
import { INotificationItems } from "../../Interfaces/AnalystPanelInterfaces";
import "./index.css";

export default function Financials() {
  const [financialData, setFinancialData] = useState();
  const [listOfCompanies, setListOfCompanies] = useState();
  const [companyFilter, setCompanyFilter] = useState({
    company_id: 189,
    type: "bs",
    period: "quarter",
  });
  const [notifications, setNotifications] =
    useState<Array<INotificationItems>>();
  const [notify, setNotify] = useState<boolean>(true);
  const [cookies, setCookies] = useCookies();
  const [accessToken, setAccesstoken] = useState(
    cookies?.auth?.access_token ?? cookies?.auth?.access_token
  );
  const [approvalTable, setApprovalTable] = useState(false);

  // console.log(DefaultFinancialDataAPI);

  useEffect(() => {
    axios({
      method: "post",
      url: SearchSuggestionAPI,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${
          cookies?.auth?.access_token ?? cookies?.auth?.access_token
        }`,
      },
    })
      .then(function (response) {
        //  console.log("RESPONSE FROM API ====>", response.data)
        //  console.log("Length ======>", response.data)
        setListOfCompanies(response.data);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  const notificationRequest = () => {
    axios
      .get(
        "https://brain.akseerresearch.com/api/askanalyst/financial/notifications",
        {
          headers: {
            Authorization: `Bearer ${
              cookies?.auth?.access_token ?? cookies?.auth?.access_token
            }`,
          },
        }
      )
      .then(function (response) {
        console.log(response.data.response, "notification response");
        setNotifications(response.data.response);
        setNotify(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const notifyOnClick = (e) => {
    const payload = {
      company_id: parseInt(e.target.getAttribute("data-compId")),
      user_id: parseInt(e.target.getAttribute("data-userId")),
      type: e.target.getAttribute("data-type"),
      period: e.target.getAttribute("data-period"),
    };

    const formData = new FormData();

    formData.append("company_id", JSON.stringify(payload.company_id));
    formData.append("user_id", JSON.stringify(payload.user_id));
    formData.append("type", JSON.stringify(payload.type));
    formData.append("period", JSON.stringify(payload.period));

    axios({
      method: "post",
      url: "https://brain.akseerresearch.com/api/financial/approval/data",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${cookies?.auth?.access_token}`,
      },
      data: formData,
    })
      .then(function (response) {
        console.log(response, "notification response after click ");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchFinancialData = (data) => {
    let url = FetchFinancialDataAPI;

    fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${
          cookies?.auth?.access_token ?? cookies?.auth?.access_token
        }`,
      },
    })
      .then((response) => response.json())
      .then(function (data) {
        setFinancialData(data);
      })
      .catch(function (err) {
        console.log(err);
        toast.error("No Data Found", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  return (
    <div>
      <GlobalNotification
        notify={notify}
        notificationRequest={notificationRequest}
        setNotify={setNotify}
        notifications={notifications}
        notifyOnClick={notifyOnClick}
      />
      {listOfCompanies ? (
        <FinancialsFilter
          listOfCompanies={listOfCompanies}
          fetchFinancialData={fetchFinancialData}
          companyFilter={companyFilter}
          setCompanyFilter={setCompanyFilter}
        />
      ) : (
        "Loading..."
      )}
      <div className="ask-analyst-panel-financials">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <FinancialDataSheet
                company={companyFilter}
                data={financialData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
