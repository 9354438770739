// import React, { useState, useEffect } from "react";
import KeyUpdatesList from "../../components/KeyUpdatesList/KeyUpdateList";
// import moment from "moment";
// import axios from "axios";
// import DataTable from "react-data-table-component";
// import { useNavigate } from "react-router-dom";
// import { KeyUpdatesListAPI } from "../../../../api/ApiList";
// import randomColor from "randomcolor";
// import AddKeyUpdateCategory from "../../components/AddKeyUpdateCategory/AddKeyUpdateCategory";

const KeyUpdates = () => {
  // const [data, setData] = useState([]);

  // ---------------- UseEffet to handle rendering ---------------

  // useEffect(() => {
  //   axios
  //     .get(KeyUpdatesListAPI)
  //     .then((res) => {
  //       console.log("Key updates list API =====>", res.data);
  //       setData(res.data);
  //     })
  //     .catch((err) => console.log("Error in Key updates List API", err));
  // }, []);

  return (
    <>
      <div className="keyupdate-sec">
        <h3 className="title">
          <span>Key Updates</span>
        </h3>
        <KeyUpdatesList />
      </div>

      {/* <CategoryAddModal addModalShow={addModalShow} closeModal={closeModal} /> */}
    </>
  );
};

export default KeyUpdates;
