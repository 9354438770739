import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
// import { KeyUpdatesListAPI } from '../../../api/ApiList';
import PolicySOPList from "./PolicySOPList";
import UploadFile from "./UploadFile";

const Policy = () => {
  const [categoryAddModal, setCategoryAddModal] = useState(false);

  // ---------------- UseEffet to handle rendering ---------------

  // useEffect(() => {
  //     axios.get(KeyUpdatesListAPI)
  //     .then((res) => {
  //         console.log("Key updates list API =====>", res.data)
  //         setData(res.data);
  //     })
  //     .catch(err => console.log("Error in Key updates List API", err));
  // }, [])

  // -------------- Show and Hide Modal Logic --------------------

  const handleAddModalShow = () => {
    setCategoryAddModal(!categoryAddModal);
  };
  return (
    <>
      <div className="d-inline-flex flex-wrap w-100 policy-sop-main">
        {/* <div className="d-inline-flex w-100 add-policy-filter">
          <Button
            variant="primary"
            name="uploadpolicy"
            className="add-policy"
            onClick={handleAddModalShow}
          >
            + Upload File
          </Button>
        </div> */}
        <div className="d-inline-flex justify-content-between w-100">
          <div className="col-lg-12 p-0">
            <div className="policy-sop-sec">
              <h3 className="title">
                <span>Policies & SOPs</span>
              </h3>
              <PolicySOPList />
            </div>
          </div>
        </div>
      </div>
      <UploadFile
        addModalShow={categoryAddModal}
        handleAddModalShow={handleAddModalShow}
      />
    </>
  );
};

export default Policy;
