import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../../components/InputField/InputField";
import TextArea from "../../../../components/TextArea/textField";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import {
  KeyUpdatesAddAPI,
  KeyupdatesCategoriesAPI,
} from "../../../../api/ApiList";

type CategoryType = {
  id: number | string;
  name: string
}[]

type PayloadType = {
  cat_id: string;
  name: string;
  description: string;
  detail_description: string
}

const AddKey = () => {
  // const [field , meta] = useField(props);
  const [categories, setCategories] = useState<CategoryType>([]);
  const [addPage, setAddPage] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [payload, setPayload] = useState<PayloadType>({
    cat_id: "",
    name: "",
    description: "",
    detail_description: ""
  });
  const inputRef = useRef(null);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const handleSubmit = (values) => {

    const _payload = new FormData();
    _payload.append("name", payload.name);
    _payload.append("description", payload.description);
    _payload.append("detail_description", payload.detail_description);
    _payload.append("cat_id", payload.cat_id);
    axios({
      method: "post",
      url: KeyUpdatesAddAPI,
      data: _payload,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(payload , "_payload_payload_payload_payload_payload")
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
    console.log(currentContentAsHTML);
    setPayload({ ...payload, detail_description: currentContentAsHTML });
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  createMarkup(convertedContent).__html = true;

  const addpage = () => {
    setAddPage(true);
  };

  const removepage = () => {
    setAddPage(false);
  };

  /*  
        https://brain.akseerresearch.com/api/keyupdates
        name
        description
        detail_description
        cat_id
    */

  const validate = Yup.object({
    subject: Yup.string().required("Subject is required"),
    description: Yup.string().required("Description is required"),
    cat_id: Yup.string().required("Please Select a category"),
  });

  useEffect(() => {
    axios
      .get(`${KeyupdatesCategoriesAPI}`)
      .then((res) => setCategories(res.data))
      .catch((err) =>
        console.log("Error in getCategories in AddKeyupdates API", err)
      );
  }, []);

  return (
    <>
      <div className="addkey-main">
        <h3 className="title">
          <span>Add Key Updates</span>
        </h3>
        {/* <div className="keyupdates-select-category primary-select">
          <div className="primary-select">
            <label className="">Select Category: </label>
            <select
                onChange={(e) => {
                    setPayload({ ...payload, cat_id: e.target.value });
                    }}
                className="form-control"
                as="select"
                name="cat_id">

                {categories.map((cat) => (
                    <option value={cat.id}>{cat.name}</option>
                ))}
            </select>
          </div>
        </div> */}
        {/* <div className="post-by">
          <p>Posted by : <strong>{cookies?.auth.user.name}</strong></p>
        </div> */}
        <div className="form-block keyupdate-form-section mt-3">
          <div className="primary-select">
            <label className="px-2">Select Category</label>
            <select
                onChange={(e) => {
                  setPayload({ ...payload, cat_id: e.target.value });
                }}
                className="form-control"
                name="cat_id">

                {categories.map((cat) => (
                    <option value={cat.id}>{cat.name}</option>
                ))}
            </select>
          </div>
          <div className="default-fields">
            <label>Write Subject</label>
            <input
              onChange={(e) => {
                setPayload({ ...payload, name: e.target.value });
              }}
              type="text"
              name="description"
              placeholder="Write Subject"
              ref={inputRef}
              className="form-control w-100 "
            />
            <label>Write Description</label>
            <textarea
              name="detail_description"
              placeholder="Write Description"
              cols={30}
              rows={30}
              className="form-control w-100 "
              onChange={(e) => {
                setPayload({ ...payload, description: e.target.value });
              }}
            />
            <div className="post-by">
              <p>Posted by : <span>{cookies?.auth.user.name}</span></p>
            </div>
          </div>
          {addPage === true ? (
            <>
              <button className="btn btn-link" type="button" onClick={removepage}>
                + Remove Page
              </button>
              <div className="rich-block">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />
              </div>
            </>
          ) : (
            <button type="button" onClick={addpage} className="btn btn-link" >
              + Add Page
            </button>
          )}
          <div className="form-group w-100 my-3 d-flex flex-wrap justify-content-end">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Save
            </button>
            {/* <span
              className="btn btn-primary"
              onClick={() => console.log(payload)}
            >
              Show Payload
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AddKey;
