import React, { useState , useEffect} from "react";
import LogoImg from "../../assets/media/akseer.svg";
import axios from "axios";
import UserImg from "../../assets/media/profile_user.jpg";
import SessionExpire from "./sessionExpire";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import { useCookies } from "react-cookie";
import { logoutAPI } from "../../api/ApiList";
import { SetUserAuth, GetUserAuth, DeleteUserAuth } from "../../AuthFunctions";
import { toast } from "react-toastify";

const Header = (props) => {
  const [able, setAble] = useState(true);
  const [navigateFlag , setNavigateFlag] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let pathName = location.pathname.split("/")[1];

  const [userAction, setUserAction] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies();
  const [sessionMessage, setSessionMessage] = useState("");
  const [sessionModal, setSessionModal] = useState(false);

  const handleUserAction = () => {
    setUserAction(!userAction);
  };

  const handleProfile = () => {
    navigate("/user/managment/profile");
  };

  const logout = () => {
    setAble(false);

    removeCookies("auth");
    removeCookies("user");
    removeCookies("accessToken");
    removeCookies("userInformation");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("authentication");
    localStorage.removeItem("sessionTime");
    localStorage.removeItem("userActive");
    localStorage.removeItem("UserAuth");
    sessionStorage.removeItem("userPermission");
    DeleteUserAuth();
    toast.success("Logout Successful");
    navigate("/");

    axios
      .post(logoutAPI, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${
            cookies.auth.access_token !== undefined ?? cookies.auth.access_token
          }`,
        },
      })
      .then(function (response) {
        removeCookies("auth");
        removeCookies("user");
        removeCookies("accessToken");
        removeCookies("userInformation");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("authentication");
        localStorage.removeItem("sessionTime");
        localStorage.removeItem("userActive");
        localStorage.removeItem("UserAuth");
        sessionStorage.removeItem("userPermission");
        DeleteUserAuth();
        // toast.success("Logout Successful");
        navigate("/");
      })
      .catch(function (error) {
        setAble(true);
        console.log(error);
        removeCookies("auth");
        removeCookies("user");
        removeCookies("accessToken");
        removeCookies("userInformation");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("authentication");
        localStorage.removeItem("sessionTime");
        localStorage.removeItem("userActive");
        localStorage.removeItem("UserAuth");
        sessionStorage.removeItem("userPermission");
        DeleteUserAuth();
        navigate("/");
        toast.success("Logout Successful");
      });
  };

  /* Session expiry functionality start */

  const sessionTimer = localStorage.getItem("sessionTime");

  function sessionTimeout() {
    // if(navigateFlag){
      setSessionModal(false);
      removeCookies("accessToken");
      removeCookies("auth");
      removeCookies("userInformation");
      localStorage.removeItem("sessionTime");
      localStorage.removeItem("userActive");
      localStorage.removeItem("UserAuth");
      sessionStorage.removeItem("userPermission");
      DeleteUserAuth();
      navigate("/" , {replace: true});
      window.location.reload();
    // }
  }

  var count = sessionTimer * 1000;
  console.log(count, "count value");
  // setTimeout(() => {
  //   setSessionMessage("Your session has timed out. Please login again.");
  //   handleSession();
  //   sessionTimeout();
  // }, 3600000);

  setInterval(() => {
    var d=new Date();
    // console.log(d.valueOf() , " current time ");
    let loginTime = parseInt(localStorage.getItem("LoginTime"));
    if((d.valueOf() - loginTime) >= 3600000){
      console.log(loginTime , " loginTime after 15 sec");
      console.log((d.valueOf() - loginTime) , "time differnce in millis");
      setSessionMessage("Your session has timed out. Please login again.");
      handleSession();
    }
    // else{
    //   console.log((d.valueOf() - loginTime) , "time differnce in millis else");
    // }
  } , 1000)

  const handleSession = () => {
    setSessionModal(true);
  };

  const closeModal = () => {
    setSessionModal(false);
    sessionTimeout();
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-between w-100">
            <div className="navbar-brand">
              <Link to="/dashboard">
                <img src={LogoImg} alt="logo" />
              </Link>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {pathName !== "dashboard" ? (
                <div className="direct-dashboard mr-4">
                  <Link
                    className="btn"
                    style={styles.directDashboardBtn}
                    to="/dashboard"
                  >
                    <svg
                      style={styles.directDashboardBtnIcon}
                      width="20px"
                      height="20px"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fal"
                      data-icon="arrow-right"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className="svg-inline--fa fa-arrow-right fa-w-14"
                    >
                      <path
                        fill="#3969d5"
                        d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                        className=""
                      ></path>
                    </svg>
                    <span>Back to Dashboard</span>
                  </Link>
                </div>
              ) : null}
              <div
                className={userAction ? "user-option active" : "user-option"}
              >
                <p className="inline-block user-img">
                  <img src={UserImg} />
                  <span
                    className="user-trigger d-inline-block"
                    onClick={handleUserAction}
                  >
                    <svg
                      width="20px"
                      height="12px"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="angle-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      className="svg-inline--fa fa-angle-down fa-w-10"
                    >
                      <path
                        fill="currentColor"
                        d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"
                        className=""
                      ></path>
                    </svg>
                  </span>
                </p>
                <ul className="list-unstyled m-0">
                  <li className="option-item">
                    <small>Admin</small>
                  </li>
                  {/* <li className="option-item">
                    <p className="m-0" onClick={handleProfile}>
                      <span className="icon">
                        <svg
                          width="20px"
                          height="20px"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="eye"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          className="svg-inline--fa fa-eye fa-w-18"
                        >
                          <path
                            d="M288 288a64 64 0 0 0 0-128c-1 0-1.88.24-2.85.29a47.5 47.5 0 0 1-60.86 60.86c0 1-.29 1.88-.29 2.85a64 64 0 0 0 64 64zm284.52-46.6C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 96a128 128 0 1 1-128 128A128.14 128.14 0 0 1 288 96zm0 320c-107.36 0-205.46-61.31-256-160a294.78 294.78 0 0 1 129.78-129.33C140.91 153.69 128 187.17 128 224a160 160 0 0 0 320 0c0-36.83-12.91-70.31-33.78-97.33A294.78 294.78 0 0 1 544 256c-50.53 98.69-148.64 160-256 160z"
                            className=""
                          ></path>
                        </svg>
                      </span>
                      View Profile
                    </p>
                  </li> */}
                  <li className="option-item">
                    <p className="m-0" onClick={able ? logout : null}>
                      <span className="icon">
                        <svg
                          width="20px"
                          height="20px"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="sign-out"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="svg-inline--fa fa-sign-out fa-w-16"
                        >
                          <path
                            d="M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z"
                            className=""
                          ></path>
                        </svg>
                      </span>
                      Logout
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <SessionExpire
        sessionModal={sessionModal}
        closeModal={closeModal}
        sessionMessage={sessionMessage}
      />
    </>
  );
};

export default Header;

const styles = {
  directDashboardBtn: {
    display: "flex",
    padding: "5px",
    alignItems: "center",
    color: "#3969d5",
    fontWeight: "400",
    fontSize: "16px",
  },
  directDashboardBtnIcon: {
    padding: "3px",
    transform: "rotate(180deg)",
    margin: "0 5px",
    background: "#fff",
    borderRadius: "50%",
  },
};
