import React , {useState , useEffect} from 'react';
import CSV from '../../../../assets/media/download-csv.svg';
import loader from '../../../../assets/loader.gif';
import DataTable from "react-data-table-component";
import DataTableExtension from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
import './ChartingDataTable.css';

const ChartingDataTable = ({data , dataList}) => {
    const [dataSeries , setDataSeries] = useState<any>();
    const [dataArr , setDataArr] = useState<any>({});
    const [objArr , setObjArr] = useState<any>([]);
    const [dataMapKeys , setDataMapKeys] = useState<any>(['date']);

    useEffect(() => {
        setDataSeries(dataList);        
        console.log(data?.data , "data?.data");
        console.log(data?.header , "data?.header")
        setDataArr(data?.data)
        // setObjArr(data?.header);
        // if(data?.data){
        //     setDataMapKeys(Object.keys(data?.data[0]));
        // }
        let cloneVar:any = [{
            name: 'Date',
            selector: 'date',
        }]
        if(data?.header){
            data?.header.map((item , i) => {
                console.log(i , " i");
                let obj = {
                    name: item,
                    selector: item,
                }
                cloneVar.push({
                    name: item,
                    selector: item,
                    // selector: (row) => (item ? item : "N/A"),
                })
                console.log(cloneVar , "clone Var");
            })
        }
        setObjArr(cloneVar);
        
        console.log(data , " data list");

    } , [data])
    const tableObj = {
        columns: objArr,
        data: dataArr
    }


    return (
        <>
            <div className="comp-financial-block">
                {dataArr && dataArr.length > 0 ? 
                    <>
                        {/* <button className='download-csv' title="download csv"><img src={CSV} alt="csv" /></button>
                        <div className="col-12 p-0 data-block d-flex flex-wrap justify-content-between">
                            <div className='comp-financials'>
                                <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th className='text-left'>Date</th>
                                            {objArr.map((h) => {
                                                return <th className='text-center'>{h}</th>
                                                }
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataArr && dataArr.map((d , i) => (
                                            <tr key={i}>
                                                {dataMapKeys.map((k , i) => {
                                                    return(
                                                        <td className={k === 'date' ? 'text-left' :'text-center'}>{d[k]}</td>
                                                    )
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                        <DataTableExtension {...tableObj}>
                        <div className="brain-table-card mt-0">
                            {/* <div className="brain-table-card-header">
                                <h3 className="title">Contact Directory</h3>
                            </div> */}
                            <div className="brain-table-card-body">
                                {/* <DataTable
                                className="table w-100 mb-0"
                                data={data}
                                columns={columns}
                                pagination
                                paginationPerPage={15}
                                paginationComponentOptions={paginationComponentOptions}
                                /> */}
                                <DataTable 
                                    columns={tableObj.columns}
                                    data={tableObj.data}
                                    noHeader
                                    defaultSortAsc={false}
                                    pagination
                                    paginationPerPage={15}
                                    // highlightOnHover
                                />
                            </div>
                        </div>
                        </DataTableExtension>
                    </>
                :
                    null
                }
            </div>
        </>
    )
}

export default ChartingDataTable;