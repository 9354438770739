import React from "react";
import { generateRandomColor } from "../../../../GlobalFunctions";

export default function UpcomingEvents() {
  return (
    <div className="events w-100">
      <h3 className="title">
        <span>Upcoming Events</span>
      </h3>
      <div className="event-list">
        <ul className="list-unstyled m-0">
          <li>
            <div className="event-detail">
              <h6>Payroll Payout</h6>
              <p className="d-inline-flex justify-content-start align-items-center m-0">
                <span className="event-venue mr-3">Online</span>
                <span className="event-date">10-Feb-2022</span>
              </p>
            </div>
            <div
              className="event-date-icon"
              style={{ backgroundColor: generateRandomColor() }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="calendar"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-calendar fa-w-14 fa-2x"
              >
                <path
                  fill="#FFFFFF"
                  d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
                  className=""
                ></path>
              </svg>
            </div>
          </li>
          <li>
            <div className="event-detail">
              <h6>Company Standup</h6>
              <p className="d-inline-flex justify-content-start align-items-center m-0">
                <span className="event-venue mr-3">Front Lobby</span>
                <span className="event-date">10-Feb-2022</span>
              </p>
            </div>
            <div
              className="event-date-icon"
              style={{ backgroundColor: generateRandomColor() }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="calendar"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-calendar fa-w-14 fa-2x"
              >
                <path
                  fill="#FFFFFF"
                  d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
                  className=""
                ></path>
              </svg>
            </div>
          </li>
          <li>
            <div className="event-detail">
              <h6>Team Meeting</h6>
              <p className="d-inline-flex justify-content-start align-items-center m-0">
                <span className="event-venue mr-3">Front Lobby</span>
                <span className="event-date">10-Feb-2022</span>
              </p>
            </div>
            <div
              className="event-date-icon"
              style={{ backgroundColor: generateRandomColor() }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="calendar"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-calendar fa-w-14 fa-2x"
              >
                <path
                  fill="#FFFFFF"
                  d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
                  className=""
                ></path>
              </svg>
            </div>
          </li>
          <li>
            <div className="event-detail">
              <h6>Payroll Payout</h6>
              <p className="d-inline-flex justify-content-start align-items-center m-0">
                <span className="event-venue mr-3">Online</span>
                <span className="event-date">10-Feb-2022</span>
              </p>
            </div>
            <div
              className="event-date-icon"
              style={{ backgroundColor: generateRandomColor() }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="calendar"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-calendar fa-w-14 fa-2x"
              >
                <path
                  fill="#FFFFFF"
                  d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
                  className=""
                ></path>
              </svg>
            </div>
          </li>
          <li>
            <div className="event-detail">
              <h6>Company Standup</h6>
              <p className="d-inline-flex justify-content-start align-items-center m-0">
                <span className="event-venue mr-3">Front Lobby</span>
                <span className="event-date">10-Feb-2022</span>
              </p>
            </div>
            <div
              className="event-date-icon"
              style={{ backgroundColor: generateRandomColor() }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="calendar"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-calendar fa-w-14 fa-2x"
              >
                <path
                  fill="#FFFFFF"
                  d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
                  className=""
                ></path>
              </svg>
            </div>
          </li>
          <li>
            <div className="event-detail">
              <h6>Team Meeting</h6>
              <p className="d-inline-flex justify-content-start align-items-center m-0">
                <span className="event-venue mr-3">Zoom Call</span>
                <span className="event-date">10-Feb-2022</span>
              </p>
            </div>
            <div
              className="event-date-icon"
              style={{ backgroundColor: generateRandomColor() }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="calendar"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-calendar fa-w-14 fa-2x"
              >
                <path
                  fill="#FFFFFF"
                  d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
                  className=""
                ></path>
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
