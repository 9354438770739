import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { toast } from "react-toastify";
import InputField from "../../../components/InputField/InputField";
import SelectField from "../../../components/SelectField/SelectField";
import { contactDirectoryAddAPI } from "../../../api/ApiList";
// import '../../helper';

interface IEditObj {
  cell_no: string;
  department: string;
  designation: string;
  email: string;
  extension?: string;
  id: number;
  name: string;
  organization: string;
}

interface ISelectListMain {
  id: number;
  name: string;
}

interface IDataList {
  IEditObj;
}

type AppProps = {
  data: IDataList;
  addModalShow: Boolean;
  setData: Function;
  closeModal: Function;
  organizationList: ISelectListMain[];
  departmentList: ISelectListMain[];
  designationList: ISelectListMain[];
};

const ProjectAddModal = ({
  closeModal,
  addModalShow,
  setData,
  data,
  organizationList,
  departmentList,
  designationList
}: AppProps) => {
  //   console.log(props, "add props get");

  const [able, setAble] = useState<boolean>(false);

  const [cookies, setCookies] = useCookies();
  // let history = useHistory();
  // console.log(props , "props add");
  // let [name, setName] = useState("");
  ////validation////////
  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    organization: Yup.string().required("Organization is required"),
    department: Yup.string().required("Department is required"),
    designation: Yup.string().required("Designation is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    cell_no: Yup.string().required("Cell is required"),
  });
  ////end-validation////////

  const close = () => {
    closeModal();
  };

  console.log(cookies, "cookies");

  return (
    <>
      <Modal
        size="lg"
        centered
        className="table-modal add-project-modal"
        show={addModalShow ? true : false}
      >
        <Modal.Header>
          <Modal.Title className="text-left d-inline-block w-100 px-2">
            Add Contact Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Formik
              initialValues={{
                name: "",
                organization: "",
                department: "",
                designation: "",
                email: "",
                cell_no: "",
                extension: "",
              }}
              validationSchema={validate}
              onSubmit={async (values, { setFieldError }) => {
                const _payload = new FormData();
                _payload.append("name", values.name);
                _payload.append("organization", values.organization);
                _payload.append("department", values.department);
                _payload.append("designation", values.designation);
                _payload.append("email", values.email);
                _payload.append("cell_no", values.cell_no);
                _payload.append("extension", values.extension);
                setAble(true);
                axios({
                  headers: { 
                    "Content-Type": "application/json" , 
                    'Authorization': `Bearer ${cookies.accessToken}` 
                  },
                  method: "post",
                  url: `${contactDirectoryAddAPI}`,
                  data: _payload,
                })
                  .then(function (response) {
                    // console.log(response , "response after contact add");
                    setData((data) => [response.data.record, ...data]);
                    toast.success("Contact Added!" , {
                      position: toast.POSITION.BOTTOM_RIGHT
                    });
                    closeModal();
                    setAble(false);
                  })
                  .catch(function (error) {
                    if(error.response.status === 400){
                      var result = error.response.data.error;
                      Object.keys(result).map(function (key) {
                        setFieldError(key, result[key][0]);
                      });
                      setAble(false);
                    }
                    else{
                      closeModal();
                      toast.error("There is an Error");
                    }
                  });
              }}
            >
              {(formik) => (
                <>
                  <Form>
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <InputField
                          type="text"
                          label="Name"
                          name="name"
                          placeholder="Enter Employee Name"
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <SelectField
                          label="Organization"
                          name="organization"
                          placeholder="Enter Organization"
                          projectlist={organizationList}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <SelectField
                          label="Department"
                          name="department"
                          placeholder="Enter Department"
                          projectlist={departmentList}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <SelectField
                          label="Designation"
                          name="designation"
                          placeholder="Enter Designation"
                          projectlist={designationList}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-6">
                        <InputField
                          type="email"
                          label="Email"
                          name="email"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <InputField
                          type="number"
                          label="Cell No"
                          name="cell_no"
                          placeholder="Enter Extension"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      {/* <div className="form-group col-lg-6"></div> */}
                      <div className="form-group col-lg-6">
                        <InputField
                          type="number"
                          label="Extension"
                          name="extension"
                          placeholder="Enter Extension"
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label className="form-label m-0 d-inline-flex w-100"></label>
                        <div className="d-inline-flex w-100 justify-content-end position-relative">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={able}
                            name="submit"
                            style={{ background: "#3969d5" }}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-light btn-cancel ml-2"
                            name="cancel"
                            onClick={close}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectAddModal;
