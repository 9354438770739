import React, { useState }  from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Formik , Form} from 'formik';
import * as Yup from 'yup';
// import DropZone from 'react-dropzone';
import axios from 'axios';
import InputField from '../../../components/InputField/InputField';
import SelectField from '../../../components/SelectField/SelectField';
import { KeyUpdatesAddCategoryAPI } from '../../../api/ApiList';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import Dropzone from './Dropzone';

const UploadFile = (props) => {

    const [cookies, setCookies] = useCookies();
    const [count , setCount] = useState();

    const dataObj = [
        {
            "id": 1,
            "name": "ESG",
            "status": 1,
        },
        {
            "id": 2,
            "name": "Ask Analyst",
            "status": 1,
        }
    ]

    const validate = Yup.object({
        section: Yup.string().required("Section is required"),
        // file: Yup.object().shape({
        //     file: Yup.mixed().required("file is required").test('fileFormat', 'PDF only', (value) => {
        //         if(!value){
        //             console.log(value , "in if");
        //             alert("Please select atleast one file");
        //         }
        //         else{
        //             console.log(value, "asd");
        //             return value && ['application/pdf'].includes(value.type);
        //         }
        //     })
        // })
    });
    ////end-validation////////

    const handleOnDrop = (files , rejectedFiles) =>{
        console.log(files, "uploaded files");
    }

    const handleFileUpload = (val) => {

        console.log(count , "file count");
        console.log(val , "slection value");
        // if(count == 0){

        // }
    }

    // const handleAddCategory = (categoryName , {setFieldError}) => {

    //     let data = new FormData()
    //     data.append("section", JSON.stringify(categoryName.section));
    //     data.append("file", JSON.stringify(categoryName.file[0]));

    //     axios({
    //         method: "POST",
    //         url: KeyUpdatesAddCategoryAPI,
    //         data: data,
    //         headers: { 
    //           "Content-Type": "multipart/form-data",
    //           "Access-Control-Allow-Origin": "*",
    //           'Authorization': `Bearer ${cookies.auth.access_token ?? cookies.auth.access_token}` 
    //         },
    //       })
    //     .then(res => {
    //         props.handleAddModalShow();
    //         props.setCount(props.count + 1);
    //         toast.success(res?.data.success , {
    //             position: toast.POSITION.BOTTOM_RIGHT
    //         });
    //     })
    //     .catch( err => {
    //         console.log(err.response);
    //         var result = err.response.data.error;
    //         Object.keys(result).map(function(key) {
    //             setFieldError(key, result[key][0])
    //         });
    //     })
    // }   

    return (
        <>
            <Modal className="table-modal upload-file-modal" show={props.addModalShow} centered>
                <Modal.Header>
                    <Modal.Title className="text-left d-inline-block w-100">Upload File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            section:'',
                        }}
                        validationSchema = {validate}
                        onSubmit={(values) => {
                            handleFileUpload(values)
                        }}
                        // onSubmit={handleAddCategory(values , { setFieldError })}
                        // onSubmit={(values , { setFieldError }) =>{
                        //     let data = new FormData()
                        //     data.append("name", JSON.stringify(values.name));

                        //     axios({
                        //         method: "POST",
                        //         url: KeyUpdatesAddCategoryAPI,
                        //         data: data,
                        //         headers: { 
                        //         "Content-Type": "multipart/form-data",
                        //         "Access-Control-Allow-Origin": "*",
                        //         'Authorization': `Bearer ${cookies.auth.access_token ?? cookies.auth.access_token}` 
                        //         },
                        //     })
                        //     .then(res => {
                        //         props.handleAddModalShow();
                        //         toast.success(res?.data.success , {
                        //             position: toast.POSITION.BOTTOM_RIGHT
                        //         });
                        //     })
                        //     .catch( err => {
                        //         console.log(err)
                        //         var result = err.response.error.name[0];
                        //         console.log(result , "add category 123123123");
                        //         Object.keys(result).map(function(key) {
                        //             setFieldError(key, result[key][0])
                        //         });
                        //     })
                        // }}
                    >
                        {formik => (
                            <>
                                <Form>
                                    <SelectField label="Select a section" name="section" onChange={formik.handleChange} placeholder="Select a section" projectlist={dataObj} />
                                    <Dropzone onDrop={handleOnDrop} multiple={true} accepted={'pdf'} setCount={setCount}>Drop or Browse a file here</Dropzone>
                                    <div className="form-group d-inline-flex justify-content-center w-100 position-relative upload-btn-block">
                                        <Button type="submit" className="btn btn-primary mr-2" name="submit">Save</Button>
                                        <Button type="button" className="btn btn-light btn-cancel" onClick={props.handleAddModalShow} >Cancel</Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UploadFile;
