import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import SimpleAlert from "../../components/Alerts/SimpleAlert";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { getAuth } from "../../redux/actions/AuthActions";
import { GetUserAuth } from "../../AuthFunctions";
import "../Dashboard/Dashboard.css";

export default function MainLayout({ children }) {
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookies, removeCookies] = useCookies();
  const [userAuth, setUserAuth] = useState();

  useEffect(() => {
    setUserAuth(GetUserAuth());
  }, []);

  console.log("USER LOG INSIDE DASHBOARD ====>", userAuth);

  if (userAuth && cookies) {
    return (
      <>
        <Header />
        <div className="dashboard">
          <div className="dashboard-content w-100 d-flex flex-wrap justify-content-between">
            <div className="d-inline-flex flex-wrap w-100">
              <Sidebar />
              {children}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    Navigate("/");
    return <></>;
  }
}
