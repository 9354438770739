import React from 'react';
import { Link } from 'react-router-dom';
import alphaanalyst from '../../assets/media/alphaanalyst.svg'
import esg from '../../assets/media/esg.svg'
import payroll from '../../assets/media/payroll.svg'
import alphacapital from '../../assets/media/alphacapital.svg'
import askanalyst from '../../assets/media/askanalyst.svg'
import charting from '../../assets/media/charting.svg'
import researchreport from '../../assets/media/researchreport.svg'
import blogs from '../../assets/media/blogs.svg'
import dms from '../../assets/media/dms.svg'
import learn from '../../assets/media/learn.svg'
import policy from '../../assets/media/policy.svg'
import crm from '../../assets/media/crm.svg'
import finance from '../../assets/media/finance.svg'
import task from '../../assets/media/task.svg'
import contact from '../../assets/media/contact.svg'
import './projectlinks.css';
import { userHasProjectPermision } from '../../GlobalFunctions';
import { ASK_ANALYST } from '../../Permisions';
import { useCookies } from 'react-cookie';


const Projectlinks = () => {

    const [cookies, setCookies] = useCookies();

    return (
        <>
            <div className="project-links-sec">
                <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-start">
                    <div className="project-items">
                        <Link to="/dashboard">
                            <span className="project-icon">
                                <img src={alphaanalyst} alt="logo"/>
                            </span>
                            <h3>Alpha Analyst</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/dashboard">
                            <span className="project-icon">
                                <img src={alphacapital} alt="logo"/>
                            </span>
                            <h3>Alpha Capital</h3>
                        </Link>
                    </div>
                    {/* ================ ASK ANALYST ================ */}
                    {/* {
                        (userHasProjectPermision(cookies.auth?.authorization , ASK_ANALYST))
                        ? */}
                        <div className="project-items">
                            <Link to="/analyst-panel">
                                <span className="project-icon">
                                    <img src={askanalyst} alt="logo"/>
                                </span>
                                <h3>Ask Analyst</h3>
                            </Link>
                        </div>
                    {/* //     :
                    //     ""
                    // }  */}
                    <div className="project-items">
                        <Link to="/dashboard">
                            <span className="project-icon">
                                <img src={blogs} alt="logo"/>
                            </span>
                            <h3>Blogs</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/chartingtool">
                            <span className="project-icon">
                                <img src={charting} alt="logo"/>
                            </span>
                            <h3>Charting Tool</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/contact-directory">
                            <span className="project-icon">
                                <img src={contact} alt="logo"/>
                            </span>
                            <h3>Contact Directory</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to={{ pathname: "https://perfex.akseerresearch.com/admin/authentication" }} target="_blank" >
                            <span className="project-icon">
                                <img src={crm} alt="logo"/>
                            </span>
                            <h3>CRM</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/dashboard">
                            <span className="project-icon">
                                <img src={dms} alt="logo"/>
                            </span>
                            <h3>DMS</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/dashboard">
                            <span className="project-icon">
                                <img src={esg} alt="logo"/>
                            </span>
                            <h3>ESG</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to={{ pathname: "https://perfex.akseerresearch.com/admin/authentication" }} target="_blank" >
                            <span className="project-icon">
                                <img src={finance} alt="logo"/>
                            </span>
                            <h3>Finance</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/dashboard">
                            <span className="project-icon">
                                <img src={learn} alt="logo"/>
                            </span>
                            <h3>Learn</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/dashboard">
                            <span className="project-icon">
                                <img src={payroll} alt="logo"/>
                            </span>
                            <h3>Payroll</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/policy-sop">
                            <span className="project-icon">
                                <img src={policy} alt="logo"/>
                            </span>
                            <h3>Policies & SOPs</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to="/dashboard">
                            <span className="project-icon">
                                <img src={researchreport} alt="logo"/>
                            </span>
                            <h3>Research Report</h3>
                        </Link>
                    </div>
                    <div className="project-items">
                        <Link to={{ pathname: "https://perfex.akseerresearch.com/admin/authentication" }} target="_blank" >
                            <span className="project-icon">
                                <img src={task} alt="logo"/>
                            </span>
                            <h3>Task Management</h3>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projectlinks;
