import React from 'react';
// import PatternOne from './pattern-one';
import PatternOne from './PatternOne';
// import patternJsons from '../patterncontent/patternJson.json';
// import patternJsons from '../../PatternContent/PatternList.json';
import patternJsons from '../../PatternContent/patternJson.json';
import './Pattern.css';
import '../../PatternContent/patterncontent.css';

const Patternlist = ({getPattern}) => {
    // var files = JSON.parse(patternJsons);
    return (
        <>
            <div className="c-temp-list d-flex">
                {patternJsons.map((patternJson , index) => (
                    <PatternOne key={`pat--` + index} getPattern={getPattern} pat_id={`pat-` + index} patternJson={JSON.stringify(patternJson)}/>
                ))}
            </div>
        </>
    )
}

export default Patternlist;
