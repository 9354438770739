import React , {useEffect, useState} from 'react'

const DataSeriesSelected = ({dataList , setDataList , HandleInstanceRemoval , blurDataSeries}) => {
    const [dataSeries , setDataSeries] = useState<any>();

    // const HandleInstanceRemoval = (e , childIndex , parentIndex) => {
    //     // console.log(childIndex , " instance index");
    //     // console.log(parentIndex , " instance index");
    //     // console.log(e , " instance event");
    //     // console.log(dataList , "dataList on change removal");
    //     const dataSeriesClone:any = {...dataList};
    //     dataSeriesClone.sector[parentIndex].commodity.splice(childIndex , 1);
    //     if(dataSeriesClone.sector[parentIndex].commodity.length === 0){
    //         dataSeriesClone.sector.splice(parentIndex , 1);
    //     }
    //     // console.log(dataSeriesClone , "dataSeriesClone on change removal");
    //     setDataList(dataSeriesClone);
    // }
    useEffect(() => {
        // console.log(dataList , "props.dataList")
        setDataSeries(dataList);
    } , [dataList])
  return (
    <div className='data-series-selected'>
        <h3>Data Series Selected</h3>
        <div className='data-series-list-block d-inline-flex w-100 mb-3'>
            {blurDataSeries ? 
                <div className='blur'></div>
            : null}
            {dataSeries ? dataSeries.sector.map((s , index) => (
                <>
                    {(s.name !== '' && s.commodity.length > 0) ? 
                        <ul key={index} className='list-unstyled'>
                            {s.commodity.map((c , i) => {
                                if(c.name !== ""){
                                    return (
                                        <li key={i}>
                                            <div className='data-selected-item'>
                                                <p className='d-inline-block m-1'>{c.name}</p>
                                                <span onClick={(e):void => HandleInstanceRemoval(e , i , index)}>X</span>
                                            </div>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    : null}
                </>
            ))
            :
                null
            }
        </div>
    </div>
  )
}

export default DataSeriesSelected;
