import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import AddUserFilter from "./addUserFilter";
import ProjectAssign from "./projectAssign";
import "./user.css";
import { toast } from "react-toastify";
import { rolesActiveProjectsAPI, userAddAPI } from "../../../../api/ApiList";
import { userFieldsObject } from "../../Modals/Data";
import { IUserFields } from "../../Interfaces/UserManagmentInteferface";

const Loader = require("../../../../assets/loader.gif");

interface SelectedUserProjects {
  project_name: string;
  project_id: string | number;
  role_id: string | number;
  role_name: string;
}

const AddUser = (props): React.FunctionComponentElement<Element> => {
  const navigate: Function = useNavigate();
  const [userFields, setUserFields] = useState<IUserFields[]>(userFieldsObject);
  const [allProjects, setAllProjects] = useState<any>();
  const [selectedProjects, setSelectedProjects] = useState<any>();
  const [projectPayload, setProjectPayload] = useState<any>({});
  const [rolePayload, setRolePayload] = useState<any>({});
  const [projectAssignPayload, setProjectAssignPayload] = useState<any>();

  // ====================== Project Assign Payload Logic ======================

  const createProjectAssignPayload = (project_name, project_id, role_id) => {
    setProjectAssignPayload({
      ...projectAssignPayload,
      [project_name]: { project_id: project_id, role_id: role_id },
    });
  };

  const selectedProject = [];
  const [able, setAble] = useState(false);

  function GetProjectList() {
    useEffect(() => {
      axios.get(rolesActiveProjectsAPI).then((response) => {
        setAllProjects(response.data);
      });
    }, []);
  }

  const createPayload = (key, permissions = {}) => {
    setProjectPayload({ ...projectPayload, [key]: permissions });
  };

  const createRolePayload = (key, role) => {
    setRolePayload({ ...rolePayload, project_id: role });
  };

  const deletePayload = (key) => {
    if (key in projectPayload) {
      delete projectPayload[key];
    }
  };

  GetProjectList();

  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  if (allProjects) {
    return (
      <>
        <div className="d-inline-flex flex-wrap w-100 px-4">
          <>
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                project: [],
              }}
              validationSchema={validate}
              onSubmit={(values, { setFieldError }) => {
                setAble(true);
                axios({
                  headers: global.header,
                  method: "post",
                  url: `${userAddAPI}`,
                  data: {
                    ...values,
                    project: { project: JSON.stringify(projectAssignPayload) },
                    // something: JSON.parse(projectPayload),
                    // role: rolePayload,
                  },
                })
                  .then(function (response) {
                    navigate("/user/managment/users");
                    toast.success("User Succesfully Added");
                  })
                  .catch(function (error) {
                    setAble(false);
                    console.log("THESE ARE THE VALUES ======>", values);
                    var result = error.response.data.error;
                    toast.error("There was error, please review!");
                    Object.keys(result).map(function (key) {
                      setFieldError(key, result[key][0]);
                    });
                  });
                console.log(values, "validation");
              }}
            >
              {(formik) => (
                <>
                  <Form>
                    <div className="d-flex flex-wrap w-100">
                      <div className="user-input-block w-100">
                        <AddUserFilter userFields={userFields} />
                      </div>
                      <div className="project-input-block w-100">
                        <ProjectAssign
                          deletePayload={deletePayload}
                          projectPayload={projectPayload}
                          createPayload={createPayload}
                          allProjects={allProjects}
                          rolePayload={rolePayload}
                          createRolePayload={createRolePayload}
                          projectAssignPayload={projectAssignPayload}
                          createProjectAssignPayload={
                            createProjectAssignPayload
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-end w-100 px-2">
                      <Button
                        type="submit"
                        variant="primary"
                        name="submit"
                        className="add-user-btn my-2"
                        style={{ background: "#3969d5" }}
                        //  onClick={addUserSubmit}
                        disabled={able}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
          {/* <button
            onClick={() => console.log(projectPayload)}
            className="btn btn-primary btn-large"
          >
            Show Payload
          </button> */}
        </div>
      </>
    );
  } else {
    return (
      <div className="full-screen-loader">
        <img src={Loader} alt="loading ... " />
      </div>
    );
  }
};

export default AddUser;
