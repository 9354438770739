import axios from "axios";
import React, { useEffect, useState } from "react";
import loader from "../../../../assets/loader-small.gif";
import "./PermissionUpdateSwitch.css";

interface Props {
  initialStatus: number;
  action: string;
  p_id: number;
}

const switchNumbers = (value) => {
  if (value === 1) {
    return 0;
  }
  if (value === 0) {
    return 1;
  }
};

export default function PermissionUpdateSwitch({
  initialStatus,
  action,
  p_id,
}: Props) {
  const [swtichStatus, setSwitchStatus] = useState<string | number | boolean>();
  const [inLoadingState, setInLoadingState] = useState<boolean>(false);

  const handleChange = () => {
    setInLoadingState(true);
    setSwitchStatus(switchNumbers(swtichStatus));
    const form = new FormData();
    form.append("p_id", JSON.stringify(p_id));
    form.append(`${action}`, JSON.stringify(switchNumbers(swtichStatus)));
    axios({
      headers: { "Content-Type": "aplication/json" },
      method: "POST",
      url: `https://brain.akseerresearch.com/api/askanalyst/assign/permission`,
      data: form,
    })
      .then(function (response) {
        setSwitchStatus(!swtichStatus);
        setInLoadingState(false);
      })
      .catch(function (error) {
        console.log(error);
        setInLoadingState(false);
      });
    console.log("Current SwitchStatus ======>", swtichStatus);
  };

  useEffect(() => {
    setSwitchStatus(initialStatus);
  }, [initialStatus]);

  if (inLoadingState) {
    return (
      <div style={styles.loaderParent}>
        <img className="w-100" src={loader} alt="" />
      </div>
    );
  } else {
    if (initialStatus === undefined) {
      return <p>Undefined</p>;
    } else {
      return (
        <div className="custom-active-switch">
          <span
            className={`initialstatus-label ${!swtichStatus ? "active" : ""}`}
          >
            Inactive
          </span>
          <label className="switch">
            <input
              onChange={() => {
                handleChange();
              }}
              type="checkbox"
            />
            <span
              className={`slider round ${swtichStatus ? "active" : ""}`}
            ></span>
          </label>
          <span
            className={`initialstatus-label ${swtichStatus ? "active" : ""}`}
          >
            Active
          </span>
        </div>
      );
    }
  }
}

const styles = {
  loaderParent: {
    width: "35px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
