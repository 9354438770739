import MainLayout from '../../../../layout/MainLayout/MainLayout';
import AddKeyUpdates from '../../components/AddKeyUpdates/AddKeyUpdates';

const AddKeyUpdate = () => {


    return (
        <MainLayout>
            <div className='addkey-main-block px-4'>
                <AddKeyUpdates/>
            </div>           
        </MainLayout>

                          
    )
}

export default AddKeyUpdate;

