import "./Login.css";
import Login from "./Login";
// import { globalImagePath } from '../../../helper';
// https://askanalyst-testing.akseerresearch.com/api/login

const logo = require("../../assets/media/logo-akseer.svg").default;
const logoImg = require("../../assets/media/login-illustrate.svg").default;

const LoginPage = () => {
  return (
    <>
      <section className="login-sec old">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="d-flex flex-wrap justify-content-start">
                <div className="col-md-6">
                  <div className="login-img">
                    <img src={logoImg} className="w-100" alt="login-img" />
                  </div>
                </div>
                <div className="col-md-5 d-flex flex-column justify-content-center">
                  <h5 className="login-title">
                    Welcome to Brain <img src={logo} alt="login-img" />
                  </h5>
                  <div className="form-area ml-md-auto mx-auto">
                    <div className="text-block">
                      <h3 className="m-0">Login to your account</h3>
                    </div>
                    <Login />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
