import React , {useState} from 'react';
import Select from 'react-select';
import { number } from 'yup/lib/locale';
import './PeriodFrequency.css';

const PeriodFrequencyFilter = ({rangeFrequency , setRangeFrequency , mainFlag , submitAll , setFieldLevel , lastDate , sector , setSector , setDataList , setData , setBlurDataSeries}) => {
    const [frequencyList , setFrequencyList] = useState([
        { value: 1, label: "Daily" },
        { value: 2, label: "Weekly" },
        { value: 3, label: "Monthly" },
        { value: 4, label: "Bi Annual" },
        { value: 5, label: "Fiscal year" },
    ]);
    const [startDate , setStartDate] = useState<string>("");
    const [endDate , setEndDate] = useState<string>("");
    const [endDateInit , setEndDateInit] = useState<string>("");
    const [frequencyVal , setFrequency] = useState<number>(0)

    const handleStartDate = (e) => {
        setStartDate(e.target.value);
        setRangeFrequency({start:e.target.value , end: endDate, frequency: frequencyVal})
        let tempArr = e.target.value.split('-');
        tempArr[tempArr.length - 1] = JSON.stringify(parseInt(tempArr[tempArr.length - 1]) + 1);  
        setEndDateInit(tempArr.join('-'));     
    }
    const handleEndDate = (e) => {
        setEndDate(e.target.value)
        // console.log(e , "end date")
        // console.log(e.target.value , "end date")
        setRangeFrequency({start:startDate , end: e.target.value, frequency: frequencyVal})
    }

    const handleFrequencyChange = (e) => {
        setFrequency(e.value);
        // console.log(e , "frequency");
        setRangeFrequency({start:startDate , end: endDate, frequency: e.value})
    }

    const handleReset = () => {
        setFieldLevel(1)
        setSector([{value: null , label: ''}])
        setBlurDataSeries(false);
        setDataList({
            type: {id: null, name:""},
            sector:[{id: null, name: '' , commodity: [{id: null, name: ''}]}],
        })
        setData([]);
    }

    return (
        <div className='d-flex flex-wrap justify-content-start'>
            <div className="col-xl-5 col-lg-5 my-1 pl-0 d-flex">
                <div className="form-group field-wrap w-100 d-flex justify-content-start align-items-center pl-0 m-0">
                    {/* <label className="d-inline-block m-0 mr-2 px-2 col-3"> Select Period</label> */}
                    <span className='d-inline-flex align-items-center' style={{width: "50%"}}>
                        <small className='mr-1' style={{fontSize: "14px"}}>From :</small>
                        <input type="date" className="form-control primary-input mr-1" style={{width: "215px"}} max={lastDate} onChange={(e) => handleStartDate(e)}/>
                    </span>
                    <span className='d-inline-flex align-items-center w-50' style={{width: "50%"}}>
                        <small className='mr-1' style={{fontSize: "14px"}}>To :</small>
                        <input type="date" className="form-control primary-input ml-1" style={{width: "215px"}} min={startDate} max={lastDate} disabled={!startDate ? true : false} onChange={(e) => handleEndDate(e)}/>
                    </span>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 my-1 pl-0 d-flex">                
                <div className="form-group field-wrap w-100 d-flex justify-content-start align-items-center pl-0 m-0">
                    <label className="d-inline-block m-0 mr-2 px-2"> Frequency</label>
                    <Select className="w-100 col-8 p-0" name="type" closeMenuOnSelect={true} options={frequencyList} onChange={(e) => {handleFrequencyChange(e)}} />
                </div>
            </div>
            <div className="col-xl-3 col-lg-3 my-1 pl-0">                
                <div className="form-group d-flex justify-content-end align-items-center m-0">
                    <button className='btn btn-primary mr-2' disabled={(!frequencyVal && mainFlag == true)? true : false} onClick={submitAll}>FETCH DATA</button>
                    <button className='btn btn-cancel ml-2' onClick={handleReset}>RESET</button>
                </div>                
            </div>
        </div>
    )
}

export default PeriodFrequencyFilter;
