import axios from "axios";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import moment from "moment";
import "./FinancialDataSheet.css";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const arrowDown = require("../../../../assets/media/arrowDown.svg").default;

export default function FinancialDataSheet({ data, company }) {
  const [initData, setInitData] = useState<any>();
  const [updatedData, setUpdatedData] = useState<any>([]);
  const [hideBlinker, setHideBlinker] = useState(true);
  const [payload, setPayload] = useState<any>([]);
  const [year, setYear] = useState({});
  const [emptyFlag, setEmptyFlag] = useState(true);
  const [annualDate, setAnnualDate] = useState(2022);
  const [lastQuarter, setLastQuarter] = useState("march/2022");
  const [validationColumns, setValidationColumns] = useState([]);
  const [validationFlag, setValidationFlag] = useState<any>();
  const [scrollX, setScrollX] = useState(false);
  const [cookies, setCookies] = useCookies();

  let abc: any = document.getElementById("financeTable");

  const checkCellValiation = () => {
    console.log("VALIDATION RUNNING===");
    let emptyCells: any = [];
    let validatedCell: any = document.getElementsByClassName("validated_cell");
    validatedCell.forEach((item, index) => {
      if (item.value === "") {
        console.log(
          `Item with unique_cell_id = ${item.getAttribute(
            "data-unique_cell_id"
          )} |====> is EMPTY`
        );
        // document.querySelectorAll('[data-unique_cell_id]').classList.add("validation-failed")
        // console.log(document.querySelectorAll(`[data-unique_cell_id="${item.getAttribute("data-unique_cell_id")}"]`))
        document
          ?.querySelector(
            `[data-unique_cell_id="${item.getAttribute(
              "data-unique_cell_id"
            )}"]`
          )!
          .classList.add("validation-failed");
        emptyCells.push(item.getAttribute("data-unique_cell_id"));
      } else {
        document
          ?.querySelector(
            `[data-unique_cell_id="${item.getAttribute(
              "data-unique_cell_id"
            )}"]`
          )!
          .classList.remove("validation-failed");
      }
    });

    if (emptyCells.length <= 0) {
      return false;
    } else {
      return true;
    }
  };

  // const testSubmit = () => {
  //   checkCellValiation();
  // }

  var ticking = false;
  var lastScrollLeft = 0;
  const onScroll = () => {
    if (!ticking) {
      abc.requestAnimationFrame(function () {
        var documentScrollLeft = abc.scrollX;
        if (lastScrollLeft != documentScrollLeft) {
          setScrollX(true);

          lastScrollLeft = documentScrollLeft;
        } else {
          setScrollX(false);
        }

        ticking = false;
      });
      ticking = true;
    }
  };
  useEffect(() => {
    setInitData(data);
    // abc.addEventListener("scroll", onScroll);

    // return () => {
    //   abc.removeEventListener("scroll", onScroll);
    // };
  });

  useEffect(() => {
    setAnnualDate(2022);
    setUpdatedData([]);
    setValidationFlag(-1);
  }, [initData]);

  const updateAnnualDate = () => {
    setAnnualDate(annualDate + 1);
  };

  const revertAnnualDate = () => {
    setAnnualDate(annualDate - 1);
  };

  const updateQuarterData = () => {
    console.log("Date Being Saved", getQuarter());
    setLastQuarter(getQuarter());
  };

  const getQuarter: any = (separator = "/") => {
    let startDate;
    let newDate;

    if (lastQuarter) {
      startDate = `1.${moment(lastQuarter).format("MM.YYYY")}`;
      newDate = moment(startDate, "DD-MM-YYYY").add(3, "month");
      return `${moment(newDate).format(`MMM${separator}YYYY`)}`;
    }
  };

  //   onChange={(e) => {
  //   handleNewDataChange(
  //     e.target.getAttribute("data-id"),
  //     e.target.getAttribute("data-company_id"),
  //     e.target.getAttribute("data-heading_id"),
  //     e.target.getAttribute("data-description_id"),
  //     e.target.getAttribute("data-description"),
  //     e.target.getAttribute("data-classification"),
  //     e.target.getAttribute("data-year"),
  //     e.target.value,
  //     e.target.getAttribute("data-cell_id")
  //   );
  // }}

  const handleNewDataChange = (id, year, value, cell_id) => {
    checkEmptyFlag(value, cell_id);

    let newValue = {
      // "cell_id": cell_id,
      id: id,
      // "company_id": company_id,
      // "heading_id": heading_id,
      // "description_id": description_id,
      // "description": description,
      // "classification": classification,
      [year]: value,
    };

    if (payload.length === 0) {
      setPayload([...payload, newValue]);
    } else {
      payload.find((object, index) => {
        if (object.id === id) {
          setPayload(
            payload.map((item) => {
              if (item.id === id) {
                return { ...item, [year]: value };
              } else {
                return item;
              }
            })
          );
          return true;
        } else {
          setPayload([...payload, newValue]);
        }
      });
    }
  };

  const addNewColumn = () => {
    if (company.period === "annual") {
      setUpdatedData([...updatedData, { date: annualDate + 1 }]);
      updateAnnualDate();
    } else {
      // getQuarter
      setUpdatedData([...updatedData, { date: getQuarter() }]);
      updateQuarterData();
    }
  };

  const addNewColumnAfterValidation = () => {
    console.log("Columns are empty =====>", checkCellValiation());
    console.log("Updated Data length =====>", updatedData.length);

    if (updatedData.length <= 0) {
      console.log("SHOULD ONLY RUN ONCE");
      addNewColumn();
    }
    if (updatedData.length > 0) {
      console.log("Will always run");
      if (checkCellValiation()) {
        toast.error(
          "Please make sure all cells are filled, to add new column",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          }
        );
      } else {
        addNewColumn();
      }
    }
  };

  // console.log(updatedData);

  const removeLastColumn = () => {
    setUpdatedData([...updatedData, { date: annualDate - 1 }]);
    revertAnnualDate();
  };

  const checkEmptyFlag = (value, cell_id) => {
    if (value === "") {
      console.log(`${cell_id} is Empty`);
      setEmptyFlag(true);
    } else {
      console.log("NOT EMPTY AT ALLLLLL");
      setEmptyFlag(false);
    }
  };

  // const voidSubmit = () => {
  //   emptyFlag.find((object, index) => {
  //     if (object.isEmpty === true) {
  //       console.log(`${object.cell_id} is Empty`);
  //     }
  //   });
  // };

  // voidSubmit();

  const handleSubmit = (e) => {
    // e.preventDefault();
    // var _payload.append(payload);
    // _payload.append("Data", { company_id: company.company_id, type: company.type, period: company.period, data: payload });
    var _payload = new FormData();
    var _details = { ...company, user_id: cookies?.auth.user.id };
    // _payload.append("company_id", company.company_id);
    // _payload.append("user_id", cookies?.auth.user.id);
    // _payload.append("type", company.type);
    // _payload.append("period", company.period);

    _payload.append("details", JSON.stringify(_details));
    _payload.append("data", JSON.stringify(payload));

    axios({
      method: "POST",
      url: "https://brain.akseerresearch.com/api/financial/update",
      data: _payload,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        toast.success("Submitted for approval");
      })
      .catch(function (response) {
        console.log("ERROR IN Financial POST API ===>", response);
      });
  };

  const inputRef = useRef(null);

  if (initData) {
    return (
      <>
        <div className={`update-blinker ${hideBlinker ? "hide" : ""}`}>
          <p
            className=""
            onClick={() => {
              setHideBlinker(!hideBlinker);
            }}
          >
            Approval Requests from Amna{" "}
            <span>
              <img src={arrowDown} alt="drop" />
            </span>{" "}
          </p>
          <ul className="list-unstyled m-0">
            <li>
              <span>Mar/2003</span>
            </li>
            <li>
              <span>Apr/2003</span>
            </li>
            <li>
              <span>Mar/2003</span>
            </li>
            <li>
              <span>Apr/2003</span>
            </li>
            <li>
              <span>Mar/2003</span>
            </li>
            <li>
              <span>Apr/2003</span>
            </li>
            <li>
              <span>Mar/2003</span>
            </li>
            <li>
              <span>Apr/2003</span>
            </li>
            <li>
              <span>Mar/2003</span>
            </li>
            <li>
              <span>Apr/2003</span>
            </li>
            <li>
              <span>Mar/2003</span>
            </li>
            <li>
              <span>Apr/2003</span>
            </li>
          </ul>
        </div>
        <div style={styles.financialDataSheet} className="financial-data-sheet">
          <table
            id="financeTable"
            style={styles.financialDataSheetTable}
            onScroll={() => onScroll()}
            className={scrollX ? "labels-table scrollx" : "labels-table"}
          >
            <thead style={styles.stickyHead}>
              <tr className="thead-row">
                <th className="label-column-headers" colSpan={2}>
                  <span className="table-inner-cell first">ID</span>
                </th>
                <th className="label-column-headers">
                  <span className="table-inner-cell second">
                    Classification
                  </span>
                </th>
                <th className="label-column-headers">
                  <span className="table-inner-cell third">Description</span>
                </th>
                {initData[0].data.map((d) => (
                  <th>{d.date}</th>
                ))}
                {updatedData.map((d) => (
                  <th>{`${d.date}`}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {initData.map((d, firstIndex) => {
                return (
                  <tr>
                    <td
                      colSpan={2}
                      className="text-align-center label-column-cells"
                    >
                      <span className="table-inner-cell first">{d.id}</span>
                    </td>
                    <td
                      style={{ textAlign: "left" }}
                      className="label-column-cells"
                    >
                      <span className="table-inner-cell second">
                        {d.classification}
                      </span>
                    </td>
                    <td className="label-column-cells">
                      <span className="table-inner-cell third">
                        {d.description}
                      </span>
                    </td>
                    {d.data.map((_d, secondIndex) => {
                      return (
                        <>
                          <td
                            style={{ minWidth: "75px" }}
                            className="financial_data_Sheet_inner_cell"
                          >
                            <input
                              name={`editable_cell_${firstIndex + secondIndex}`}
                              data-id={d.id}
                              data-cell_id={`${d.heading_id}_${_d.date}`}
                              data-company_id={d.company_id}
                              data-heading_id={d.heading_id}
                              data-description_id={d.description_id}
                              data-description={`${d.description}`}
                              data-classification={`${d.classification}`}
                              data-year={_d.date}
                              onChange={(e) => {
                                handleNewDataChange(
                                  e.target.getAttribute("data-id"),
                                  e.target.getAttribute("data-company_id"),
                                  e.target.getAttribute("data-heading_id"),
                                  e.target.getAttribute("data-description_id")
                                  // e.target.getAttribute("data-description"),
                                  // e.target.getAttribute("data-classification"),
                                  // e.target.getAttribute("data-year"),
                                  // e.target.value,
                                  // e.target.getAttribute("data-cell_id")
                                );
                              }}
                              className="editable-table-cell"
                              ref={inputRef}
                              type="number"
                              defaultValue={_d.value}
                            />
                          </td>
                        </>
                      );
                    })}

                    {updatedData.map((b) => {
                      // setValidationColumns({ ...validationColumns, })

                      return (
                        <td>
                          <input
                            name={`editable_cell`}
                            data-id={d.id}
                            data-company_id={d.company_id}
                            data-heading_id={d.heading_id}
                            data-description_id={d.description_id}
                            data-description={`${d.description}`}
                            data-classification={`${d.classification}`}
                            data-unique_cell_id={`${d.description_id}_${b.date
                              .toString()
                              .toLowerCase()}`}
                            // data-year={`${(company.period === 'annual') ? b.date : b.date.toLowerCase()}`}
                            // data-cell_id={`${(company.period === 'annual') ? b.date : b.date.toLowerCase()}`}
                            data-year={`${b.date.toString().toLowerCase()}`}
                            data-cell_id={`${b.date.toString().toLowerCase()}`}
                            onChange={(e) => {
                              handleNewDataChange(
                                e.target.getAttribute("data-id"),
                                e.target.getAttribute("data-company_id"),
                                e.target.getAttribute("data-heading_id"),
                                e.target.getAttribute("data-description_id")
                                // e.target.getAttribute("data-description"),
                                // e.target.getAttribute("data-classification"),
                                // e.target.getAttribute("data-year"),
                                // e.target.value,
                                // e.target.getAttribute("data-cell_id")
                              );
                            }}
                            className="editable-table-cell validated_cell"
                            ref={inputRef}
                            type="number"
                            placeholder="0"
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            <div style={styles.addColumnBtnDiv} className="add-column-btn">
              <span
                className="btn"
                onClick={addNewColumnAfterValidation}
                style={styles.addColumnBtn}
              >
                +
              </span>
            </div>
            {/* <div style={styles.removeColumnBtnDiv} className="remove-column-btn">
            {<button onClick={ () => {
              setUpdatedData(updatedData.filter( (date) =>  date.date != annualDate ))
              revertAnnualDate(annualDate - 1);
            }} style={styles.removeColumnBtn}>-</button> }
          </div> */}
          </table>
          <div style={styles.newColumnSaveDiv} className="new-column-save">
            <button
              style={styles.newColumnSaveBtn}
              type="submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
              className="btn btn-primary my-2"
            >
              Save
            </button>
            {/* <button style={styles.newColumnSaveBtn} type='submit' onClick={(e) => {testSubmit(e)}} className='btn btn-primary my-2'>SAVE</button> */}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div style={styles.selectCompanyMessage}>
        <h4>Please select a company from search . . .</h4>
      </div>
    );
  }
}

const styles: { [key: string]: CSSProperties } = {
  financialDataSheet: {
    display: "flex",
    flexDirection: "row",
    // display: "flex",
    // flexDirection: "row",
    width: "100%",
    overflowX: "scroll",
    paddingRight: "100px",
    paddingBottom: "10px",
    // overflowY: "hidden",
    overflowY: "auto",
    height: "65vh",
  },
  stickyHead: {
    position: "sticky",
    top: "0",
    zIndex: "9999",
    background: "#ffffff",
    boxShadow: "0 0 10px #3969d54d",
  },

  selectCompanyMessage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "25em",
    color: "#007bff78",
  },

  financialDataSheetTable: {
    borderSpacing: "0",
    tableLayout: "fixed",
    position: "relative",
    marginLeft: "-5px",
  },
  positionAbs: {
    position: "absolute",
  },
  addColumnBtn: {
    border: "none",
    borderRadius: "50px",
    width: "30px",
    height: "30px",
    fontWeight: "bold",
    background: "black",
    color: "white",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addColumnBtnDiv: {
    position: "absolute",
    top: "1px",
    right: "-45px",
  },
  removeColumnBtn: {
    border: "none",
    borderRadius: "50px",
    width: "30px",
    height: "30px",
    fontWeight: "bold",
    background: "#ff5b5b",
    color: "white",
  },
  removeColumnBtnDiv: {
    position: "absolute",
    top: "40px",
    right: "-45px",
  },
  newColumnSaveDiv: {
    position: "absolute",
    right: "15px",
    bottom: "5px",
  },
  newColumnSaveBtn: {
    background: "#3969d5",
    width: "75px",
  },
  tableInnerSpan: {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "8px",
    // borderRight: "0.25px solid var(--light-border-color)",
    alignItems: "center",
  },
};
