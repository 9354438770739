import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import * as Yup from "yup";
import InputField from "../../../../components/InputField/InputField";
import { concat } from "lodash";
import { toast } from "react-toastify";
import { ProjectEditAPI } from "../../../../api/ApiList";
import FileUploadDropdown from "../../components/FileUploadDropdown/FileUploadDropdown";

const ProjectEditModal = (props) => {
  const [able, setAble] = useState(false);
  const validate = Yup.object({
    name: Yup.string().required("Project Name is required"),
  });
  const [projectName, setProjectName] = useState("");
  const [projectImage, setProjectImage] = useState<any>();

  const getProjectImage = (image) => {
    setProjectImage(image);
  };

  const handleSubmit = () => {
    const form = new FormData();
    form.append("name", projectName);
    form.append("image", projectImage.file);
    setAble(true);
    axios({
      headers: { "Content-Type": "aplication/json" },
      method: "PUT",
      url: `${ProjectEditAPI}/${props.editData.id}?name=${projectName}`,
      data: form,
    })
      .then(function (response) {
        props.closeModal();
        toast.success("Project Edit Succesful!");
        setAble(false);
      })
      .catch(function (error) {
        setAble(false);
        toast.error("there was an error");
      });
  };

  console.log("Edit Modal Data", props.editData);
  return (
    <>
      <Modal
        className="table-modal edit-project-modal"
        show={props.editModalShow ? true : false}
        centered
      >
        <Modal.Header>
          <Modal.Title
            className="text-left d-inline-block w-100"
            onClick={props.closeModal}
          >
            Edit Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                required
                className="form-control w-100"
                onChange={(e) => setProjectName(e.target.value)}
                type="text"
                name="name"
              />
            </div>
            <div className="form-group">
              <FileUploadDropdown
                projectImage={projectImage}
                getProjectImage={getProjectImage}
              />
            </div>
            <div className="form-group">
              <button
                style={{ color: "white" }}
                onClick={() => {
                  handleSubmit();
                }}
                className="btn btn-primary"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-light ml-2"
                name="cancel"
                onClick={props.closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectEditModal;
