import React from "react";

const ProjectAssign = ({
  allProjects,
  projectPayload,
  createPayload,
  deletePayload,
  rolePayload,
  createRolePayload,
  projectAssignPayload,
  createProjectAssignPayload,
}: any) => {
  const enableChild = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ) => {
    const listOfOptions: HTMLCollection = document.getElementsByClassName(
      `project-list-items-${id}`
    );

    Object.keys(listOfOptions).map((item) => {
      if (e.target.checked == true) {
        listOfOptions[item].removeAttribute("disabled");
      } else {
        listOfOptions[item].setAttribute("disabled", "true");
      }
    });
  };

  const uncheckRadioButtons = (projectName: string) => {
    const radios: HTMLCollection = document.getElementsByClassName(
      `permission_radio_${projectName.split(" ").join("_")}`
    );

    Object.keys(radios).map((r) => {
      radios[r].checked = false;
    });
  };

  return (
    <>
      <div className="d-flex flex-wrap w-100">
        {allProjects &&
          allProjects.length > 0 &&
          allProjects.map((project, index) => {
            return (
              <div
                className="d-inline-flex flex-wrap project-filter-main"
                key={index}
              >
                <div className="project form-group">
                  <label className="d-inline-flex w-100">
                    <input
                      data-project_name={project.name}
                      data-project_id={project.id}
                      type="checkbox"
                      name={`project[${project.name}].project_id`}
                      value={project.id.toString()}
                      onChange={(e) => {
                        if (
                          `${e.target.getAttribute("data-project_name")}` in
                          projectPayload
                        ) {
                          deletePayload(
                            `${e.target.getAttribute("data-project_name")}`
                          );
                        } else {
                          createPayload(
                            e.target.getAttribute("data-project_name")
                          );
                        }
                        createProjectAssignPayload(
                          e.target.getAttribute("data-project_name"),
                          null,
                          null
                        );
                        uncheckRadioButtons(project.name);
                        enableChild(e, index);
                      }}
                    />
                    {project.name}
                  </label>
                </div>
                <div className="access-options pl-3 w-100">
                  {project.role &&
                    project.role.length > 0 &&
                    project.role.map((role, i) => (
                      <label className="d-inline-flex w-100" key={i}>
                        <input
                          data-project_name={project.name}
                          data-project_id={project.id}
                          data-role_id={project.role[i].id}
                          data-role_name={project.role[i].role}
                          className={
                            "proj-" +
                            index +
                            ` project-list-items-${index}` +
                            ` permission_radio_${project.name
                              .split(" ")
                              .join("_")}`
                          }
                          type="radio"
                          name={`role[${project.name}].role_id`}
                          value={role.id.toString()}
                          id={project.index}
                          disabled
                          onChange={(e: any) => {
                            console.log("creating payload");

                            createProjectAssignPayload(
                              e.target.getAttribute("data-project_name"),
                              e.target.getAttribute("data-project_id"),
                              e.target.getAttribute("data-role_id")
                            );
                            // createRolePayload(
                            //   e.target.getAttribute("role_id"),
                            //   `${e.target.getAttribute("data-role_id")}`
                            // );
                            // createPayload(
                            //   e.target.getAttribute("data-project_name"),
                            //   {
                            //     project_id: [
                            //       `${e.target.getAttribute("data-project_id")}`,
                            //     ],
                            //   }
                            // );
                          }}
                        />
                        {role.role}
                      </label>
                    ))}
                </div>
              </div>
            );
          })}
        {/* <span
          onClick={() => {
            console.log("THE PAYLOAD =====> ", projectPayload);
          }}
          className="btn btn-primary"
        >
          Show Payload
        </span> */}
      </div>
    </>
  );
};

export default ProjectAssign;
