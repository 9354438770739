import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { json } from "stream/consumers";
import { ListOfRolesData } from "../../Modals/Data";
import "./PermissionModal.css";

interface IPermissionModal {
  show: boolean;
  toggleShow: Function;
  title?: string;
}

export default function PermissionModal({
  show,
  toggleShow,
  title,
}: IPermissionModal) {
  const [modalShow, setModalShow] = React.useState(show);
  const [projectList, setProjectList] = useState<any>();
  const [roleList, setRoleList] = useState<any>();
  const [createPermissionPayload, setCreatePermissionPayload] = useState<any>();
  const [selectedRole, setSelectedRole] = useState<any>();

  const fetchProjectList = () => {
    axios
      .get("https://brain.akseerresearch.com/api/project")
      .then((res) => {
        console.log("List Of Projects =====>", res.data);
        setProjectList(res.data);
      })
      .catch((err) => console.log(err));
  };
  // Bilal   api single project with roles
  // https://brain.akseerresearch.com/api/project/70

  const fetchRoleList = (projectId) => {
    axios
      .get(`https://brain.akseerresearch.com/api/project/${projectId}`)
      // .get(`https://brain.akseerresearch.com/api/role/${projectId}`)
      .then((res) => {
        console.log("LIST OF ROLES FETCHED ====>", res.data[0]);
        setRoleList(res.data[0].role);
      })
      .then((err) => console.log(err));
  };

  console.log("THE PROJECT LIST =======>", projectList);

  const createPermision = (permissionName, projectId, roleId) => {
    const formData = new FormData();
    formData.append("permission_name", permissionName);
    formData.append("project_id", projectId);
    formData.append("role_id", roleId);

    axios({
      method: "POST",
      url: "https://brain.akseerresearch.com/api/permission",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response.data, "data response");
        toast.success("Permission Created Succesfully!");
      })
      .catch((err) => {
        console.log(err.response, "error");
        toast.success("Permission Could not be created!");
      });
  };

  const handleSubmit = () => {
    console.log(createPermissionPayload);
    createPermision(
      createPermissionPayload.permission_name,
      createPermissionPayload.project_id,
      createPermissionPayload.role_id
    );
  };

  useEffect(() => {
    fetchProjectList();
  }, []);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter view-roles-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {title ? title : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="view-roles-modal-body">
        <div className="permission-form">
          <div className="form-group">
            <label className="label" htmlFor="">
              Select Project
            </label>
            <select
              onChange={(e: any) => {
                fetchRoleList(e.target.value);
              }}
              className="form-control"
              name=""
              id=""
            >
              <option>Select Project</option>
              {projectList &&
                projectList.map((p) => {
                  return <option value={p?.id}>{p.name}</option>;
                })}
            </select>
          </div>
          <div className="form-group">
            <label className="label" htmlFor="">
              Select Role
            </label>
            <select
              onChange={(e: any) => {
                console.log(JSON.parse(e.target.value));
                setCreatePermissionPayload(JSON.parse(e.target.value));
              }}
              className="form-control"
            >
              <option>Select Role</option>
              {roleList ? (
                roleList.map((r) => {
                  console.log("THE PROJECT ID =====>", r?.project?.id);
                  return (
                    <option
                      data-project_id={r?.project?.id}
                      value={JSON.stringify({
                        role_id: r?.id,
                        project_id: r?.project_id,
                      })}
                    >
                      {r?.role}
                    </option>
                  );
                })
              ) : (
                <option>No Roles Found</option>
              )}
            </select>
          </div>
          <div className="form-group">
            <label className="label" htmlFor="">
              Write Permission
            </label>
            <input
              type="text"
              name="permission_name"
              className="form-control"
              onChange={(e: any) => {
                setCreatePermissionPayload({
                  ...createPermissionPayload,
                  permission_name: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="form-group d-inline-flex justify-content-end w-100 position-relative">
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="btn btn-primary"
          >
            Save
          </button>
          <button
            className="btn btn-light btn-cancel ml-2"
            onClick={() => toggleShow()}
          >
            {" "}
            Close{" "}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = {
  permissionContainer: {},
  permisionHeading: {
    border: "1px solid blue",
    borderRadius: "5px",
  },
};
