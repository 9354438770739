import React, { useEffect, useState } from "react";
import axios from "axios";
import { SearchSuggestionAPI } from "../../../../api/ApiList";
import CustomSearchBar from "../../../../components/CustomSearchBar/CustomSearchBar";
import { DoDecryption } from "../../../../AuthFunctions";

export default function CompanyInformationSearchbar({
  onSelect,
  searchBarValue,
  searchBarValueChangeFlag,
}) {
  const [searchItems, setSearchItems] = useState();
  const [userRoleId, setUserRoleId] = useState();

  useEffect(() => {
    const formData = new FormData();
    formData.append(
      "role_id",
      DoDecryption(localStorage.getItem("UserAuth"))?.authorization[0]?.roles[0]
        ?.role_id
    );
    axios({
      method: "post",
      url: SearchSuggestionAPI,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setSearchItems(response.data);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  const handleQuerySelect = (company) => {
    console.log("THE COMPANY OBJECT =====>", company);
    onSelect(company);
  };

  console.log("SEARCG SUGGESTION API ====>", SearchSuggestionAPI);

  return (
    <>
      {searchItems ? (
        <CustomSearchBar
          searchBarValueChangeFlag={searchBarValueChangeFlag}
          _searchBarValue={searchBarValue}
          suggestions={searchItems ? searchItems : [{}]}
          handleQuerySelect={handleQuerySelect}
        />
      ) : (
        "loading...."
      )}
    </>
  );
}
