import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  contactDirectoryAPI,
  contactDirectoryDeleteAPI,
} from "../../../api/ApiList";
import Button from "react-bootstrap/Button";
import ContactAddModal from "./Add";
import ContactEditModal from "./Edit";
import { useCookies } from "react-cookie";
import loader from "../../../assets/loader.gif";
interface EditObj {
  cell_no: string;
  department: string;
  designation: string;
  email: string;
  extension: string;
  id: number;
  name: string;
  organization: string;
}

interface IDataList {
  IEditObj;
}

// interface SelectData {
//   id: number;
//   name: string;
// }

interface SelectList {
  id: number;
  name: string;
}

interface ISelectListMain {
  id: number;
  name: string;
}

const ContactList = () => {
  let [data, setData] = useState<any>();
  let [editData, setEditData] = useState<any>();
  let [addModalShow, setaddModalShow] = useState<boolean>(false);
  let [editModalShow, setEditModalShow] = useState<boolean>(false);
  let [organizationList, setOrganizationList] = useState<any>();
  let [departmentList, setDepartmentList] = useState<any>();
  let [designationList, setDesignationList] = useState<any>();
  let [_data, _setData] = useState([]);
  // const [able, setAble] = useState<boolean>(false);
  // let History = useNavigate();
  const [cookies, setCookies] = useCookies<any>();

  // const [filterText, setFilterText] = React.useState('');
  // const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  // const filteredItems = data.filter(
  // 	item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  // );

  // const subHeaderComponentMemo = React.useMemo(() => {
  // 	const handleClear = () => {
  // 		if (filterText) {
  // 			setResetPaginationToggle(!resetPaginationToggle);
  // 			setFilterText('');
  // 		}
  // 	};

  // 	return (
  // 		<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  // 	);
  // }, [filterText, resetPaginationToggle]);

  const list = async () => {
    const response = await axios.get(contactDirectoryAPI);
    // console.log(response.data , "contact list");
    setData(response.data);
    // console.log("response from data", response);
  };

  interface organizationDropdown {
    id: string | number;
    name: string;
  }

  let organizationDropdown: organizationDropdown[] = [
    {
      id: 1,
      name: "Akseer Research",
    },
    {
      id: 2,
      name: "Akseer Technologies",
    },
    {
      id: 3,
      name: "Alpha Capital",
    },
  ];
  let designationDropdown = [
    {
      id: 1,
      name: "Account officer",
    },
    {
      id: 2,
      name: "Accountant",
    },
    {
      id: 3,
      name: "Advisory Trainee",
    },
    {
      id: 4,
      name: "Arabic Translator",
    },
    {
      id: 5,
      name: "Backend Developer",
    },
    {
      id: 6,
      name: "CEO",
    },
    {
      id: 7,
      name: "CFO",
    },
    {
      id: 8,
      name: "Compliance Officer",
    },
    {
      id: 9,
      name: "Corporate Sales",
    },
    {
      id: 10,
      name: "CSR",
    },
    {
      id: 11,
      name: "Dealer / Agent",
    },
    {
      id: 12,
      name: "Development Intern",
    },
    {
      id: 13,
      name: "Director",
    },
    {
      id: 14,
      name: "Front-End Developer ",
    },
    {
      id: 15,
      name: "Full Stack Developer",
    },
    {
      id: 16,
      name: "Head of Sales Team",
    },
    {
      id: 17,
      name: "Head Of Settlements",
    },
    {
      id: 18,
      name: "Investment Analyst",
    },
    {
      id: 19,
      name: "IT Manager",
    },
    {
      id: 20,
      name: "IT Officer",
    },
    {
      id: 21,
      name: "KATS Operator",
    },
    {
      id: 22,
      name: "Manager",
    },
    {
      id: 23,
      name: "Manager Equity Sales",
    },
    {
      id: 24,
      name: "Office Assistant",
    },
    {
      id: 25,
      name: "Office Boy  ( Stock Branch )",
    },
    {
      id: 26,
      name: "Officer",
    },
    {
      id: 27,
      name: "Research Analyst",
    },
    {
      id: 28,
      name: "Research Trainee",
    },
    {
      id: 29,
      name: "Rider",
    },
    {
      id: 30,
      name: "Senior Investment Analyst",
    },
    {
      id: 31,
      name: "Senior Research Analyst",
    },
    {
      id: 32,
      name: "SQA Engineer",
    },
    {
      id: 33,
      name: "Still & Motion Graphics Designer",
    },
    {
      id: 34,
      name: "Team Lead",
    },
  ];
  let departmentDropdown = [
    {
      id: 1,
      name: "Accounts",
    },
    {
      id: 2,
      name: "Advisory",
    },
    {
      id: 3,
      name: "Back Office",
    },
    {
      id: 4,
      name: "Compliance",
    },
    {
      id: 5,
      name: "Customer Services",
    },
    {
      id: 6,
      name: "Dealer / Agent",
    },
    {
      id: 7,
      name: "Development",
    },
    {
      id: 8,
      name: "Equity Sales Desk",
    },
    {
      id: 9,
      name: "Finance",
    },
    {
      id: 10,
      name: "Graphic Designing",
    },
    {
      id: 11,
      name: "Institutional Sales",
    },
    {
      id: 12,
      name: "IT",
    },
    {
      id: 13,
      name: "IT (HO | Shahrae Faisal)",
    },
    {
      id: 14,
      name: "IT (PSX Branch)",
    },
    {
      id: 15,
      name: "KATS Operator",
    },
    {
      id: 16,
      name: "New Challi Branch ",
    },
    {
      id: 17,
      name: "Operations & Marketing",
    },
    {
      id: 18,
      name: "Pakistan Research",
    },
    {
      id: 19,
      name: "Quality Assurance",
    },
    {
      id: 20,
      name: "Retail Sales (New Challi)",
    },
    {
      id: 21,
      name: "Retail Sales (PSX Branch)",
    },
    {
      id: 22,
      name: "Senior Management",
    },
    {
      id: 23,
      name: "Settlements",
    },
    {
      id: 24,
      name: "Software Development",
    },
  ];

  useEffect(() => {
    list();
    setOrganizationList(organizationDropdown!);
    setDepartmentList(departmentDropdown!);
    setDesignationList(designationDropdown!);
  }, []);

  function closeModal() {
    setaddModalShow(false);
    setEditModalShow(false);
    list();
  }

  function editModal(e) {
    const dataClone = [...data];
    console.log(e, "edit id");
    console.log(dataClone, "complete array");
    // let obj: IDataList = dataClone.filter((obj: EditObj) => {
    //   return obj.id == e;
    // });

    // console.log(obj, "edit to object");

    setEditData(
      dataClone.filter((obj: EditObj) => {
        return obj.id == e;
      })
    );
    console.log("=======> ", editData);
    setEditModalShow(true);
  }

  const handleDelete = async (id) => {
    console.log(id, "delete user");
    // const Delete = async () => {
    await axios.get(`${contactDirectoryDeleteAPI}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.accessToken}`,
      },
    });
    // console.log(response.data , "delete");
    list();
    // setData();
    // };
  };

  // const projectlist = async () => {
  //   const response = await axios.get(rolesActiveProjectsAPI);
  //   setProjectList(response.data);
  //   _setData(response.data);

  //   console.log(response.data, "project list");
  // };

  // useEffect(() => {
  //   projectlist();
  // }, []);

  const paginationComponentOptions = {
    rowsPerPageText: "Show Entries",
    paginationRowsPerPageOptions: [15 , 30 , 50 , 100],
    selectAllRowsItem: true
  };

  const columns = [
    {
      name: "S.No",
      selector: (row , index) => (index),
      grow: 0.2
    },
    {
      name: "Employee Name",
      selector: (row) => (row.name ? row.name : "N/A"),
      grow: 2
    },
    {
      name: "Organization",
      selector: (row) => (row.organization ? row.organization : "N/A"),
      grow: 2
    },
    {
      name: "Department",
      selector: (row) => (row.department ? row.department : "N/A"),
      grow: 2
    },
    {
      name: "Designation",
      selector: (row) => (row.designation ? row.designation : "N/A"),
      grow: 2
    },
    {
      name: "Email",
      selector: (row) => (row.email ? row.email : "N/A"),
      grow: 5
    },
    {
      name: "Extension",
      selector: (row) => (row.extension ? row.extension : "N/A"),
      grow: 0.5
    },
    {
      name: "Cell No.",
      selector: (row) => (row.cell_no ? row.cell_no : "N/A"),
      grow: 2
    },
    // {
    //   name: "Actions",
    //   cell: (row) => (
    //     <>
    //       <p className="d-flex flex-wrap justify-content-around align-items-center my-1">
    //         <span
    //           onClick={() => editModal(row.id)}
    //           className="edit-action btn-action mx-1"
    //         >
    //           <img src={EditIcon} alt="edit" />
    //         </span>
    //         <span
    //           onClick={() => handleDelete(row.id)}
    //           className="delete-action btn-action mx-1"
    //         >
    //           <img src={DeleteIcon} alt="delete" />
    //         </span>
    //       </p>
    //     </>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  if (data) {
    return (
      <div className="contact-list-sec">
        <div className="d-inline-flex flex-wrap w-100 px-4">
          <div className="d-inline-flex flex-wrap w-100 project-main">
            <div className="d-inline-flex w-100 add-project-filter px-0">
              {/* <Button
                variant="primary"
                name="addproject"
                className="add-project"
                onClick={() => setaddModalShow(true)}
              >
                + Add Contact
              </Button> */}
            </div>
            <div className="brain-table-card">
              <div className="brain-table-card-header">
                <h3 className="title">Contact Directory</h3>
              </div>
              <div className="brain-table-card-body">
                <DataTable
                  className="table w-100 mb-0"
                  data={data}
                  columns={columns}
                  pagination
                  paginationPerPage={15}
                  paginationComponentOptions={paginationComponentOptions}
                />
              </div>
            </div>
          </div>
        </div>
        <ContactAddModal
          addModalShow={addModalShow}
          closeModal={closeModal}
          setData={setData}
          data={data}
          // projectList={projectList}
          organizationList={organizationList}
          departmentList={departmentList}
          designationList={designationList}
        />
        {editData && editData.length > 0 ? (
          <>
            <ContactEditModal
              editModalShow={editModalShow}
              closeModal={closeModal}
              organizationList={organizationList}
              departmentList={departmentList}
              designationList={designationList}
              editData={editData}
              setData={setData}
              data={data}
            />
          </>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="full-screen-loader">
        <img src={loader} alt="loading...." />
      </div>
    );
  }
};

export default ContactList;
