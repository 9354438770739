import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { userListAPI } from "../../../../api/ApiList";
import NotificationAlert from "../../../../components/NotificationAlert/NotificationAlert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { data as response } from "./UserListData.js";
import ActiveSwitch from "../../../../components/ActiveSwitch/ActiveSwitch";
import { IAlertObj } from "../../Interfaces/UserManagmentInteferface";
import Loader from "../../../../assets/loader.gif";

const UserList = () => {
  const [alert, setAlert] = useState<any>();
  const [showAssignedRole, setShowAssignedRole] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const closeAlert = () => {
    setAlert(false);
  };

  let alertObj: IAlertObj | undefined;

  useEffect(() => {
    if (alertObj !== undefined) {
      if (alertObj.flag === true) {
        setAlert(true);
        alertObj.flag = false;
        setTimeout(() => {
          setAlert(false);
        }, 3000);
      }
    }
  });

  useEffect(() => {
    axios
      .get(userListAPI)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const addUsers = () => {
    navigate("/user/managment/users/add");
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Show Entries",
  };

  const handleHideAssignedRole = () => {
    setShowAssignedRole(false);
  };

  const handleViewUserRoles = () => {
    console.log("HandleViewUserRoles Executed");
  };

  const navigate = useNavigate();

  const editUser = (userId) => {
    navigate(`/user/managment/users/edit/${userId}`);
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0.5
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "N/A"),
      grow: 1.5
    },
    {
      name: "Email",
      selector: (row) => (row.email ? row.email : "N/A"),
      grow: 5
    },
    {
      name: "View Roles",
      selector: (row) => {
        return <button className="btn btn-light">View Roles</button>;
      },
      grow: 1.5
    },
    {
      name: "Status",
      selector: (row) => {
        return <ActiveSwitch status={true} />;
      },
      grow: 2
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <span onClick={() => editUser(row.id)} className="edit-action">
            <svg
              width="15px"
              height="15px"
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="pencil"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="svg-inline--fa fa-pencil fa-w-16"
            >
              <path
                fill="#3969D585"
                d="M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zm-95.196 140.45L174 420.745V386h-48v-48H91.255l224.059-224.059 82.745 82.745zM126.147 468.598l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"
                className=""
              ></path>
            </svg>
          </span>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      grow: 1
    },
  ];

  return (
    <>
      {alert === true && alertObj !== undefined ? (
        <NotificationAlert
          message={alertObj.message}
          color={alertObj.color}
          closeAlert={closeAlert}
        />
      ) : null}
      <div className="d-inline-flex flex-wrap w-100">
        <div
          key="users1"
          className="d-inline-flex flex-wrap w-100 user-main px-4"
        >
          <div className="d-inline-flex w-100 add-user-filter px-0">
            <Button
              variant="primary"
              name="adduser"
              className="add-user"
              onClick={addUsers}
            >
              + Add User
            </Button>
          </div>
          {data ? (
            <div className="brain-table-card">
              <div className="brain-table-card-header">
                <h3 className="title">List of Users</h3>
              </div>
              <div className="brain-table-card-body">
                <DataTable
                  className="table w-100 mb-0"
                  data={data}
                  columns={columns}
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                />
              </div>
            </div>
          ) : (
            <div className="full-screen-loader">
              <img src={Loader} alt="" />
            </div>
          )}
        </div>
      </div>
      <Modal
        size="lg"
        className="table-modal add-role-modal"
        show={showAssignedRole}
        onHide={handleHideAssignedRole}
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-center d-inline-block w-100">
            Users Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="text-center">
          <Button variant="outline-dark" onClick={handleHideAssignedRole}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const styles = {
  grayBtn: {
    backgroundColor: "#EEEEEE",
    borderRadius: "5px",
  },
};

export default UserList;
