import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { KeyUpdatesEditCategoryAPI } from "../../../../api/ApiList";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import InputField from "../../../../components/InputField/InputField";

interface Item {
  id: number;
  name: string;
}

type AppProps = {
  selectedCategory: Item;
  editModalShow: Boolean;
  closeModal: Function;
  setCount: Function;
  count: number;
};

const CategoryEditModal = ({
  selectedCategory,
  editModalShow,
  closeModal,
  setCount,
  count,
}: AppProps) => {
  const [cookies, setCookies] = useCookies();

  const validate = Yup.object({
    name: Yup.string().required("Category Name is required"),
  });

  const handleSubmit = async (values) => {
    console.log(selectedCategory);
    // let data = new FormData();
    // data.append("name", JSON.stringify(values.name));
    axios({
      method: "PUT",
      url: `${KeyUpdatesEditCategoryAPI}/${selectedCategory.id}`,
      params: { name: values.name },
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${
          cookies?.auth.access_token ?? cookies?.auth.access_token
        }`,
      },
    })
      .then((res) => {
        console.log("Response from server", res.data);
        closeModal();
        setCount(count + 1);
        toast.success(res.data.success, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((err) => {
        console.log(err.response, "error in key category");
        closeModal();
        toast.error("There was an error while adding category", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });

    // const res = await axios({
    //     method: 'put',
    //     url: `${KeyUpdatesEditCategoryAPI}/${selectedCategory.id}`,
    //     data: {
    //         name: values.name,
    //     }
    // })
    // .then(res => {
    //     toast.success("sucess")
    // }).catch(err => {
    //     console.log("ERRORRRR ========>", err.response)
    //     toast.error("category name is already taken, please try another one")
    // })
  };

  const close = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        className="table-modal edit-category-modal"
        show={editModalShow ? true : false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-left d-inline-block w-100">
            Edit Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Formik
              initialValues={{
                name: selectedCategory.name,
              }}
              validationSchema={validate}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {(formik) => (
                <>
                  <Form>
                    <InputField
                      type="text"
                      value={formik.values.name}
                      label="Category Name"
                      name="name"
                      placeholder="Enter Category Name"
                      onChange={formik.handleChange}
                    />
                    <div className="form-group d-inline-flex justify-content-end w-100 position-relative">
                      <button
                        type="submit"
                        className="btn btn-primary mr-2"
                        name="submit"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={close}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CategoryEditModal;
