import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import ProjectWiseRoleFilters from "./ProjectWiseRoleFilters";

export default function PermisionFilter({
  getPermisionByRole,
  permisionByRoleId,
  getPermisionByRoleId,
}) {
  const [projectList, setProjectList] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState<any>();

  useEffect(() => {
    axios
      .get("https://brain.akseerresearch.com/api/project")
      .then((res) => {
        console.log("Project List", res.data);
        for (let i = 0; i <= res.data?.length; i++) {
          console.log("PROJECT IDS ====>", res.data[i].id);
          projectList.push({ value: res.data[i].id, label: res.data[i].name });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="form-group row w-50 flex-row-reverse">
        <div className="col-5">
          <ProjectWiseRoleFilters
            permisionByRoleId={permisionByRoleId}
            getPermisionByRoleId={getPermisionByRoleId}
            getPermisionByRole={getPermisionByRole}
            projecttId={selectedProject}
          />
        </div>
        <div className="col-5">
          <Select
            onChange={(values: any) => setSelectedProject(values.value)}
            options={projectList}
          />
        </div>
        {/* <button
          onClick={() => {
            console.log("PRINT---->", projectList);
          }}
        >
          Print Project List
        </button> */}
      </div>
    </>
  );
}
