import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout/MainLayout";
import CompanyInformationSection from "./sections/CompanyInformationSection/CompanyInformationSection";
import { Tabs, Tab } from "react-bootstrap";
// import GlobalNotification from "./components/GlobalNotification/GlobalNotification";
import RecentDevelopment from "./sections/RecentDevelopment/RecentDevelopment";
import Financials from "./sections/Financials/Financials";
import Registration from "./sections/Registration/Registration";
import ManageUser from "./sections/ManegeUsers/ManageUser";
// import { INotificationItems } from "./Interfaces/AnalystPanelInterfaces";
import "./index.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { DoDecryption, DoEncryption } from "../../AuthFunctions";

export default function AnalystPanel() {
  const [cookies, setCookies] = useCookies();
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${cookies?.auth?.access_token}`;
  const [userHasRoles, setUserHasRoles] = useState();
  // const [notifications, setNotifications] = useState<Array<INotificationItems>>();
  // const [notify, setNotify] = useState<boolean>(true);

  useEffect(() => {
    setUserHasRoles(
      DoDecryption(localStorage.getItem("UserAuth")).authorization
    );
  }, []);


  // const notificationRequest = () => {
  //   axios
  //     .get(
  //       "https://brain.akseerresearch.com/api/askanalyst/financial/notifications",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${
  //             cookies?.auth?.access_token ?? cookies?.auth?.access_token
  //           }`,
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       console.log(response.data.response, "notification response");
  //       setNotifications(response.data.response);
  //       setNotify(false);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const notifyOnClick = (e) => {
  //   const payload = {
  //     company_id: parseInt(e.target.getAttribute("data-compId")),
  //     user_id: parseInt(e.target.getAttribute("data-userId")),
  //     type: e.target.getAttribute("data-type"),
  //     period: e.target.getAttribute("data-period"),
  //   };

  //   const formData = new FormData();

  //   formData.append("company_id", JSON.stringify(payload.company_id));
  //   formData.append("user_id", JSON.stringify(payload.user_id));
  //   formData.append("type", JSON.stringify(payload.type));
  //   formData.append("period", JSON.stringify(payload.period));

  //   axios({
  //     method: "post",
  //     url: "https://brain.akseerresearch.com/api/financial/approval/data",
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: `Bearer ${cookies?.auth?.access_token}`,
  //     },
  //     data: formData,
  //   })
  //     .then(function (response) {
  //       console.log(response, "notification response after click ");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  return (
    <MainLayout>
      {/* <div className="askanalyst-notification-block d-inline-block position-absolute" style={{height: '40px',width: '60px'}}>
        <GlobalNotification
          notify={notify}
          notificationRequest={notificationRequest}
          setNotify={setNotify}
          notifications={notifications}
          notifyOnClick={notifyOnClick}
        />
      </div> */}
      <div className="container-fluid my-2 px-0 analyst-panel-dashboard">
        <div className="topbar-tabs-wrapper">
          <Tabs
            defaultActiveKey="financials"
            id="topbar-tabs"
            className="topbar-tabs"
          >
            <Tab
              className="topbar-tabs-items"
              eventKey="financials"
              title="Financials"
            >
              <Financials />
            </Tab>
            <Tab eventKey="company-information" title="Company Information">
              <CompanyInformationSection />
            </Tab>
            <Tab eventKey="recent-development" title="Recent Development">
              <RecentDevelopment />
            </Tab>
            <Tab eventKey="registeration" title="Registrations">
              <Registration />
            </Tab>
            <Tab eventKey="manage-user" title="Manage Users">
              <ManageUser userHasRoles={userHasRoles} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </MainLayout>
  );
}
