export const userFieldsObject = [
  {
    index: 0,
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Your Name",
    class: "pl-0",
    value: "",
  },
  {
    index: 1,
    name: "email",
    label: "Email",
    type: "email",
    placeholder: "Your Email",
    class: "pr-0",
    value: "",
  },
  {
    index: 2,
    name: "password",
    label: "Password",
    type: "text",
    placeholder: "**********",
    class: "pl-0",
    value: "",
  },
  {
    index: 3,
    name: "password_confirmation",
    label: "Confirm Password",
    type: "text",
    placeholder: "**********",
    class: "pr-0",
    value: "",
  },
];

export const ListOfRolesData = [
  {
    id: 1,
    roleName: "Admin",
    permissions: [
      "All Admins Control",
      "View and Edit Financial Summaries",
      "Enabled Bulk Reports",
      "View and Edit Payouts",
      "View and Edit Disputes",
    ],
  },
  {
    id: 2,
    roleName: "Developer",
    permissions: [
      "Some Admin Controls",
      "View Financial Summaries only",
      "View and Edit API Controls",
      "View Payouts only",
      "View and Edit Disputes",
    ],
  },
  {
    id: 3,
    roleName: "Analyst",
    permissions: [
      "No Admin Controls",
      "View and Edit Financial Summaries",
      "Enabled Bulk Reports",
      "View Payouts only",
      "View Disputes only",
    ],
  },
  {
    id: 4,
    roleName: "Support ",
    permissions: [
      "No Admin Controls",
      "View Financial Summaries only",
      "View Payouts only",
      "View and Edit Disputes",
      "Response to Customer Feedback",
    ],
  },
  {
    id: 5,
    roleName: "Trial",
    permissions: [
      "Some Admin Controls",
      "View Financial Summaries only",
      "View and Edit API Controls",
      "View Payouts only",
      "View and Edit Disputes",
    ],
  },
];

export const ProjectUsers = [
  {
    id: 1,
    name: "Bilal Arif",
    email: "BilalArifhussain@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 2,
    name: "Ali Abbas",
    email: "Ali Abbas@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 3,
    name: "Zia",
    email: "Zia@gmai.com",
    date: "1 Feb, 2022",
  },
  {
    id: 4,
    name: "Bilal Arif",
    email: "BilalArifhussain@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 5,
    name: "Ali Abbas",
    email: "Ali Abbas@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 6,
    name: "Zia",
    email: "Zia@gmai.com",
    date: "1 Feb, 2022",
  },
  {
    id: 7,
    name: "Bilal Arif",
    email: "BilalArifhussain@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 8,
    name: "Ali Abbas",
    email: "Ali Abbas@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 9,
    name: "Zia",
    email: "Zia@gmai.com",
    date: "1 Feb, 2022",
  },
  {
    id: 10,
    name: "Bilal Arif",
    email: "BilalArifhussain@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 11,
    name: "Ali Abbas",
    email: "Ali Abbas@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 12,
    name: "Zia",
    email: "Zia@gmai.com",
    date: "1 Feb, 2022",
  },
  {
    id: 13,
    name: "Bilal Arif",
    email: "BilalArifhussain@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 14,
    name: "Ali Abbas",
    email: "Ali Abbas@gmail.com",
    date: "1 Feb, 2022",
  },
  {
    id: 15,
    name: "Zia",
    email: "Zia@gmai.com",
    date: "1 Feb, 2022",
  },
];

export const ListOfPermissionsData = [
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
  {
    id: 2,
    project_name: "Ask Analyst Developer",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
    ],
  },
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
  {
    id: 2,
    project_name: "Ask Analyst Developer",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
    ],
  },
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
  {
    id: 2,
    project_name: "Ask Analyst Developer",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
    ],
  },
  {
    id: 1,
    project_name: "ESG",
    role_name: "Admin",
    role_id: 1,
    permission: [
      {
        id: 1,
        permission_id: 1,
        permission_name: "User Managment",
      },
      {
        id: 2,
        permission_id: 2,
        permission_name: "Content Managment",
      },
      {
        id: 3,
        permission_id: 3,
        permission_name: "Financial Managment",
      },
      {
        id: 1,
        permission_id: 1,
        permission_name: "Reporting",
      },
    ],
  },
];
