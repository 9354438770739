export const removeSpecialCharacters = (string): string => {
  var newString = string.replace(/[^A-Z0-9]/gi, "_");
  return newString;
};

// ---------------------- Return a random color from the List ------------------------

export const generateRandomColor = (): string => {
  const colors: string[] = [
    "#0168fa",
    "#5f6d88",
    "#800080",
    "#00cccc",
    "#f10075",
    "#5b47fb",
    "#10b759",
    "#ffc107",
    "#dc3545",
    "#a5d7fd",
    "#1c273c",
    "#136F64",
    "#D89EF8",
    "#0B426F",
    "#C48664",
    "#87259B",
    "#FF2929",
    "#D6FBCD",
    "#808080",
    "#F4B084",
    "#FFD966",
    "#A9D08E",
    "#A6A6A6",
    "#548235",
    "#8497B0",
    "#ED7D31",
  ];

  return colors[Math.floor(Math.random() * colors.length) + 1];
};
