import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// import InputField from "../InputField/InputField";
import InputField from "../../../../components/InputField/InputField";
import axios from "axios";
import AddUserFilterField from "./addUserFilterField";

const AddUserFilter = ({ userFields }) => {
  // console.log(userFields , "userFields AddUserFilter child");
  return (
    <>
      <div className="d-inline-flex flex-wrap w-100 user-filter-main">
        <div className="user-filter-title">
          <h3 className="title">Add User</h3>
        </div>
        <div className="d-flex flex-wrap justify-content-between w-100">
          {userFields &&
            userFields.length > 0 &&
            userFields.map((Field, index) => (
              <div key={index} className={Field.class + " form-group col-5"}>
                <InputField
                  id={Field.name + Field.index}
                  type={Field.type}
                  label={Field.label}
                  name={Field.name}
                  placeholder={""}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default AddUserFilter;
