import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Forgot from "./forgot";
import "./Login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from "react-router-dom";
import { loginAPI } from "../../api/ApiList";
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/actions/AuthActions";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";
import { SetUserAuth, GetUserAuth, DeleteUserAuth } from "../../AuthFunctions";

const signIcon1 = require("../../assets/media/signinfield1.svg").default;
const signIcon2 = require("../../assets/media/signinfield2.svg").default;
const eye = require("../../assets/media/eye.svg").default;

const dummyObj = [
  {
    Name: "Bilal",
    Password: "123456",
  },
];

const ShowUserAuth = () => {
  return GetUserAuth();
};

const DoDeleteUserAuth = () => {
  console.log("Deleting User Auth!");
  DeleteUserAuth();
};

const doEncryption = () => {
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(dummyObj),
    "secret key 123"
  ).toString();
  return ciphertext;
};

const doDecryption = (text) => {
  var bytes = CryptoJS.AES.decrypt(text, "secret key 123");
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};

const validate = (values) => {
  const errors: any = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};

// Main Component

export default function Login() {
  const [capslockWarning, setCapslockWarning] = useState(false);
  const [able, setAble] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies([
    "auth",
    "accessToken",
    "userInformation",
  ]);
  const [view, setView] = useState(false);

  const onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setCapslockWarning(true);
    } else {
      setCapslockWarning(false);
    }
  };

  const navigate = useNavigate();

  const [forgotModal, setForgotModal] = useState(false);
  const handleForgot = () => {
    setForgotModal(true);
  };
  const closeModal = () => {
    setForgotModal(false);
  };

  const showMyCookie = () => {
    // console.log("your cookie", cookies.auth.user);
  };

  const isAuthenticated = false;
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    var d=new Date();
    localStorage.setItem("LoginTime", JSON.stringify(d.valueOf()));
    console.log(d.valueOf() , "++++++ login time++++++");
    setAble(true);
    axios
      .post(loginAPI, values)
      .then(function (response) {
        let userInfo = {
          email: response.data.user.email,
          id: response.data.user.id,
          name: response.data.user.name,
          role: response.data.user.role,
          status: response.data.user.status,
        };
        let userPermission = response.data.user.permission;

        console.log("Login Response ====>", response.data);

        SetUserAuth(response.data);

        setCookies("userInformation", JSON.stringify(userInfo), {
          path: "/",
          maxAge: response.data.expires_in,
        });

        setCookies("accessToken", response.data.access_token, {
          path: "/",
          maxAge: response.data.expires_in,
        });

        setCookies(
          "auth",
          {
            access_token: response.data?.access_token,
            token_type: response.data?.token_type,
            expires_in: response.data?.expires_in,
            user: {
              id: response.data?.user?.id,
              name: response.data?.user?.name,
              email: response.data?.user?.email,
              email_verified_at: response.data?.user.email_verified,
              role_id: response.data?.user?.role_id,
              description: response.data?.user?.description,
              permission: [],
              status: response.data?.user?.status,
            },
          },

          {
            path: "/",
            maxAge: response.data.expires_in,
          }
        );

        localStorage.setItem("userActive", JSON.stringify(userInfo));
        localStorage.setItem("sessionTime", response.data.expires_in);
        sessionStorage.setItem("userPermission", userPermission);

        navigate("/dashboard");
      })
      .catch(function (error) {
        setAble(false);
        console.log(error);
        toast.error("Incorrect Email or Password!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (GetUserAuth()) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <span className="icon">
            <img src={signIcon2} alt="@" />
          </span>
          <input
            className={`form-control`}
            placeholder="Email Address"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />

          <div className="mt-1 text-left error-box">
            {formik.errors.email ? (
              <span className="text-danger">{formik.errors.email}</span>
            ) : null}
          </div>
        </div>
        <div className="form-group mb-3">
          <span className="icon">
            <img src={signIcon1} alt="@" />
          </span>
          {formik.values.password.length > 0 ? (
            <span className="eye-icon" onClick={() => setView(!view)}>
              <img src={eye} alt="view" />
            </span>
          ) : null}
          <input
            className={`form-control`}
            placeholder="Password"
            id="password"
            name="password"
            type={view ? "text" : "password"}
            onKeyDown={onKeyDown}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <div className="mt-1 text-left error-box">
            {capslockWarning ? (
              <span className="text-danger">
                Please note your CAPSLOCK is ON!
              </span>
            ) : (
              ""
            )}
            {formik.errors.password ? (
              <>
                <span className="text-danger "> {formik.errors.password} </span>
              </>
            ) : null}
          </div>
        </div>
        <div className="form-group my-0 d-inline-block w-100 position-relative">
          <p className="forgot-password text-right">
            <span onClick={handleForgot}>Forgot Password?</span>
          </p>
          <button className="btn" type="submit" disabled={able}>
            Login
          </button>
        </div>
      </form>
      <Forgot forgotModal={forgotModal} closeModal={closeModal} />
      {/* <button
        onClick={() => {
          console.log(doEncryption());
        }}
        className="btn btn-primary mt-5 mr-2"
      >
        Encrypt
      </button>
      <button
        onClick={() => {
          console.log(doDecryption(doEncryption()));
        }}
        className="btn btn-success mt-5"
      >
        Decrypt
      </button>
      <button
        onClick={() => {
          console.log("This is the user auth", ShowUserAuth());
        }}
        className="btn btn-success mt-5"
      >
        Show User Auth
      </button> */}
    </>
  );
}
