import React, {useState , useEffect}  from 'react';
import {Formik , Form} from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import InputField from '../../components/InputField/InputField';
import axios from 'axios';
import { ForgotPasswordAPI } from '../../api/ApiList';
import { toast } from 'react-toastify';
// import './login.css';



const ForgotModal = (props) => {
    const validate = Yup.object({
        email: Yup.string().required("Email is required")
    });


    const handleForgotPasswordRequest = (email) => {
    
        console.log("Request Email ==>", email)
        
        axios.post(ForgotPasswordAPI, {
            email: email
        })
        .then( res => {
            props.closeModal();
            toast.success(res?.data.success)
        })
        .catch( err => {
            console.log("Following error occured in forgot password request")
            console.log(err?.response.data.error);
            props.closeModal();
            toast.error(err?.response.data.error);
        })
    }


    return (
        <>
            <Modal centered className="table-modal forgot-modal" show={props.forgotModal?true:false}>
                <Modal.Header>
                    <span className='close-subs-view' onClick={props.closeModal}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" className=""></path></svg>
                    </span>
                </Modal.Header> 
                <Modal.Body>
                    <div className='forgot-content'>
                        <h3>Forgot <br/> Password?</h3>
                        <p>Don't worry we can help you out!</p>
                    </div>
                    <Formik
                        initialValues={{
                            email:"",
                        }}
                        validationSchema = {validate}
                        onSubmit={(values , { setFieldError }) =>{
                            handleForgotPasswordRequest(values.email)
                        }}
                    >
                        {formik => (
                            <>
                                <Form>
                                    {/* <div className="form-group w-100">
                                        <input type="email" name="email"  placeholder="Enter your work email here" onChange={formik.handleChange} />
                                    </div> */}
                                    <InputField type="email" label="Email Address" name="email"  placeholder="Enter your work email here" onChange={formik.handleChange}/>
                                    <div className="form-group d-inline-flex justify-content-center w-100 forgot-submit-btn">
                                        <button type="submit" className="btn btn-primary" name="submit">Request Password Change</button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>   
        </>
    )
}

export default ForgotModal;
