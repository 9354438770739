import React , {useState} from 'react';
import Select from 'react-select';
import SelectField from "../../../../components/SelectField/SelectField";
import InputField from "../../../../components/InputField/InputField";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

interface IErrList {
    index: number,
    year: string,
    qtr: string,
    cat: number,
    news: string,
}

interface IFormList {
    year: string,
    qtr: string,
    cat: number | null,
    news: string
  }

type AppProps = {
    nameIndex: number,
    formListObject: IFormList,
    errList: IErrList[],
    handleYearChange: Function,
    handleQtrChange: Function,
    handleCatChange: Function,
    handleNewsChange: Function,
}

const RecentDevelopmentForm = ({nameIndex , formListObject , errList , handleYearChange , handleQtrChange , handleCatChange , handleNewsChange} : AppProps) => {

    const [year , setYear] = useState<any>([
        {value: '2012' , name: '2012'},
        {value: '2013' , name: '2013'},
        {value: '2014' , name: '2014'},
        {value: '2015' , name: '2015'},
        {value: '2016' , name: '2016'},
    ])

    const [qtr , setQtr] = useState<any>([
        {value: 'Jan' , name: 'Jan'},
        {value: 'Apr' , name: 'Apr'},
        {value: 'Jul' , name: 'Jul'},
        {value: 'Oct' , name: 'Oct'}
    ])

    const [cat , setCat] = useState<any>([
        {value: 1 , name: 'Cat1'},
        {value: 2 , name: 'Cat2'},
        {value: 3 , name: 'Cat3'},
        {value: 4 , name: 'Cat4'}
    ])

  return (
    <div className='recent-development-form'>
        <div className='d-flex flex-wrap justify-content-start'>
            <div className="col-md-1 pl-0">
                <div className="form-group col-12 p-0 primary-select no-label year-select">
                    <select name={`year-${nameIndex}`} className="form-control w-100"  onChange={(e) => handleYearChange(e)} placeholder="Year">
                        <option value=''>Year</option>
                        {year.map((y , i) => (
                            <option key={i} value={y.value} selected={(y.value) === (formListObject.year) ? true : false}>{y.name}</option>
                        ))} 
                    </select>
                    {errList && (nameIndex === errList[nameIndex].index) && errList[nameIndex].year && errList[nameIndex].year !== (null || undefined) ? (
                        <span className="field-error">{errList[nameIndex].year}</span>
                    ) : null}
                </div>
            </div>
            <div className="col-md-1 pl-0">
                <div className="form-group col-12 p-0 primary-select no-label qtr-select">
                    <select name={`qtr-${nameIndex}`} className="form-control w-100" onChange={(e) => handleQtrChange(e)} placeholder="Quarter">
                        <option value=''>Quarter</option>
                        {qtr.map((q , i) => (
                            <option key={i} value={q.value} selected={(q.value) === (formListObject.qtr) ? true : false}>{q.name}</option>
                        ))} 
                    </select>
                    {errList && (nameIndex === errList[nameIndex].index) && errList[nameIndex].qtr && errList[nameIndex].qtr !== "" ? (
                        <span className="field-error">{errList[nameIndex].qtr}</span>
                    ) : null}
                </div>
            </div>
            <div className="col-md-2 pl-0">
                <div className="form-group col-12 p-0 primary-select no-label cat-select">
                    <select name={`cat-${nameIndex}`} className="form-control w-100" onChange={(e) => handleCatChange(e)} placeholder="Category">
                        <option value=''>Category</option>
                        {cat.map((c , i) => (
                            <option key={i} value={c.value} selected={(c.value) === (formListObject.cat) ? true : false}>{c.name}</option>
                        ))} 
                    </select>
                    {errList && (nameIndex === errList[nameIndex].index) && errList[nameIndex].cat && errList[nameIndex].cat !== (null || undefined) ? (
                        <span className="field-error">{errList[nameIndex].cat}</span>
                    ) : null}
                </div>
            </div>
            <div className="col-md-8 p-0 d-flex justify-content-start align-items-center h-100 w-100 p-0">
                <div className="p-0 news-add">
                    <div className="form-group primary-input">
                        <input type="text" name={`news-${nameIndex}`} placeholder="Enter News" defaultValue={(formListObject.news !== "" ? formListObject.news : "")} onChange={(e) => handleNewsChange(e)} className="form-control w-100"/>
                        {errList && (nameIndex === errList[nameIndex].index) && errList[nameIndex].news && errList[nameIndex].news !== "" ? (
                            <span className="field-error">{errList[nameIndex].news}</span>
                        ) : null}
                    </div>
                </div>
                {/* <div className='news-add-btn'>
                    <button className='btn btn-primary'>Save</button>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default RecentDevelopmentForm;
