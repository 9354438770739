import React from 'react';
import MainLayout from '../../layout/MainLayout/MainLayout'
import PolicySOP from './components/Policy';
import './Index.css';

const PolicySOPs = () => {
  return (
      <MainLayout>
          <PolicySOP/>
      </MainLayout>
  )
}

export default PolicySOPs              
