import MainLayout from "../../layout/MainLayout/MainLayout";
import Profile from "./section/Profile/Profile";
import AssignPermission from "./section/Permission/Assign";
import UserAdd from "./section/Users/AddUser";
import ProjectList from "./section/Project/List";
import UserList from "./section/Users/List";
import UserEdit from "./section/Users/Edit";
import Roles from "./section/Roles/List";
import PermissionList from "./section/Permission/List";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "../../layout/NotFoundPage/NotFoundPage";
import ListOfUsersByProject from "./section/Project/ListOfUsersByProject/ListOfUsersByProject";
import ListOfPermissionsByProject from "./section/Permission/ListOfPermissions/ListOfPermissions";
import "./index.css";

export default function index() {
  return (
    <div className="user-managment-section">
      <MainLayout>
        <Routes>
          <Route path="/" element={<UserList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/users/add" element={<UserAdd />} />
          <Route path="/users/edit/:id" element={<UserEdit />} />
          <Route path="/projects" element={<ProjectList />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/permission" element={<ListOfPermissionsByProject />} />
          <Route
            path="/permission/projects"
            element={<ListOfPermissionsByProject />}
          />

          {/* <Route path="/permission/list" element={<ListOfPermissions />} /> */}
          {/* <Route path="/permission/add/:id/:projectId" element={<AssignPermission />}/> */}

          <Route
            path="/permission/accesscontrol"
            element={<AssignPermission />}
          />
          <Route path="/projects/users" element={<ListOfUsersByProject />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MainLayout>
    </div>
  );
}
