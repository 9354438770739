import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { permissionAssignAPI } from "../../../../api/ApiList";
import PermissionUpdateSwitch from "../../components/PermissionUpdateSwitch/PermissionUpdateSwitch";
import "./Permission.css";
import { useParams } from "react-router-dom";
import { IObjectRole } from "../../Interfaces/UserManagmentInteferface";
import PermisionFilter from "./components/PermisionFilter/PermisionFilter";

const loader = require("../../../../assets/loader.gif");

const PermissionList = () => {
  let [data, setData] = useState<any>();
  let [objectRole, setObjectRole] = useState<IObjectRole>({ id: "", role: "" });
  let [objectPermission, setObjectPermission] = useState();
  let [editData, setEditData] = useState([]);
  let [statusData, setStatusData] = useState([]);
  let [permisionByRoleId, setPermisionByRoleId] = useState<any>();
  let param = useParams();

  const getPermisionByRoleId = (id) => {
    setPermisionByRoleId(id);
    console.log("THIS IS THE ROLE ID ====>", id);
  };

  const getPermisionByRole = (roleId) => {
    axios
      .get(`https://brain.akseerresearch.com/api/role/${roleId}`)
      .then((res) => {
        console.log("PERMISON BY ROLE LIST ====>,", res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPermisionByRole(permisionByRoleId);
  }, [permisionByRoleId]);

  // const list = async () => {
  //   const response = await axios.get(
  //     // `${permissionAssignAPI}/${param.id}/${param.projectId}`
  //     `${permissionAssignAPI}/1/1`
  //   );

  //   setData(response.data);
  //   setObjectRole(response.data.role);
  //   setObjectPermission(JSON.parse(response.data.role.permissions));
  // };
  // useEffect(() => {
  //   list();
  //   console.log("Parameter ID", param.id);
  //   console.log("Parameter projectId", param.projectId);
  //   axios
  //     .get("https://brain.akseerresearch.com/api/permission")
  //     .then((res) => {
  //       console.log("THE PERMISsiON LIST ====>,", res.data);
  //       // setStatusData([]);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   getPermisionByRole();
  // }, []);

  const columns = [
    {
      name: "#",
      selector: (row) => (row ? row.id : ""),
    },
    {
      name: "Permission Name",
      selector: (row) => (row ? row.permission_name : ""),
    },
    {
      name: "Create",
      selector: (row) => {
        return (
          <PermissionUpdateSwitch
            action={"add"}
            initialStatus={row?.permission_add}
            p_id={row?.id}
          />
        );
      },
    },
    {
      name: "Read",
      selector: (row) => {
        return (
          <PermissionUpdateSwitch
            action={`read`}
            initialStatus={row?.permission_read}
            p_id={row?.id}
          />
        );
      },
    },
    {
      name: "Update",
      selector: (row) => {
        return (
          <PermissionUpdateSwitch
            action={`update`}
            initialStatus={row.permission_update}
            p_id={row?.id}
          />
        );
      },
    },
    {
      name: "Delete",
      selector: (row) => {
        return (
          <PermissionUpdateSwitch
            action={`delete`}
            initialStatus={row.permission_delete}
            p_id={row?.id}
          />
        );
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Show Entries",
  };

  if (data) {
    return (
      <>
        <div className="d-inline-flex flex-wrap w-100 project-main px-4">
          <div className="d-inline-flex justify-content-end add-project-filter px-0">
            <PermisionFilter
              permisionByRoleId={permisionByRoleId}
              getPermisionByRoleId={getPermisionByRoleId}
              getPermisionByRole={getPermisionByRole}
            />
          </div>
          <div className="brain-table-card">
            {/* <div className="brain-table-card-header">
              <h3>Test Switch</h3>
              <PermissionUpdateSwitch
                initialStatus={parseInt("1")} />
            </div> */}
            <div className="brain-table-card-header">
              <h3>List of Access Controls</h3>
            </div>
            <div className="brain-table-card-body">
              {data ? (
                <DataTable
                  data={data}
                  columns={columns}
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                />
              ) : (
                "loading ... "
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="full-screen-loader">
        <img src={loader} alt="loading . . . . " />
      </div>
    );
  }
};

export default PermissionList;
