import React from "react";
import MainLayout from "../../layout/MainLayout/MainLayout";
import TabsView from './Tabs/Tabs'
import './Index.css';

const index = () => {
  return (
    <MainLayout>
      <div className="container-fluid mt-3">
        <TabsView/>
      </div>
    </MainLayout>
  );
};

export default index;
