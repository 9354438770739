import CryptoJS from "crypto-js";
import { Cookies } from "react-cookie";
import { Navigate } from "react-router-dom";

const SECRET_KEY_FOREVER = "akseerresearch890";

// ---------------------- Object Encryption Function ------------------------

export const DoEncryption = (data) => {
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY_FOREVER
  ).toString();

  return ciphertext;
};

// ---------------------- Object Decryption Function ------------------------

export const DoDecryption = (text) => {
  var bytes = CryptoJS.AES.decrypt(text, SECRET_KEY_FOREVER);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};

// ---------------------- GET User Auth ------------------------

export const GetUserAuth = () => {
  const userAuth = localStorage.getItem("UserAuth");

  if (userAuth) {
    console.log("Doing Encryption for ====>", userAuth);
    return DoDecryption(userAuth);
  } else {
    return false;
  }
};

// ---------------------- SET User Auth ------------------------

export const SetUserAuth = (user) => {
  localStorage.setItem("UserAuth", DoEncryption(user));
};

// ---------------------- DELETE User Auth ------------------------

export const DeleteUserAuth = () => {
  // console.log("blah");
  localStorage.removeItem("UserAuth");
};

export const AuthRoute = (
  permission,
  permissionRequired,
  linkToSuccess,
  linkToFailded
) => {
  if (permission === undefined) {
    return "/";
  } else if (permission === null) {
    return "/";
  }
  if (permission === permissionRequired) {
    return linkToSuccess;
  } else {
    return linkToFailded;
  }
};

// ---------------------- Check if user has permision for the project ------------------------

export const userHasProjectPermision = (userPermision, permisionFor) => {
  const userHasPermision = userPermision.find((a) =>
    a.id === permisionFor.id ? true : false
  );
  if (userHasPermision) {
    return true;
  } else {
    return false;
  }
};

// 1 - Show/component based on permision
// 2 - render project based on condition

// ---------------------- Check if user has permision for Component ------------------------

export const UserHasPermissionForComponent = (userPermision, permisionFor) => {
  const userHasPermision = userPermision.find((a) =>
    a.id === permisionFor.id ? true : false
  );
  if (userHasPermision) {
    return true;
  } else {
    return false;
  }
};

// ---------------------- Check if user has permision for the view ------------------------

export const UserHasPermissionForView = (userPermision, permisionFor) => {
  const userHasPermision = userPermision.find((a) =>
    a.id === permisionFor.id ? true : false
  );
  if (userHasPermision) {
    return true;
  } else {
    return false;
  }
};

// ---------------------- check permission in project  --------------------------------------

export const FindRequiredPermission = (
  permissionObj,
  projectName,
  permissionName
) => {
  let project = permissionObj.find((f) => f.name === projectName);
  let userHasPermission = undefined;

  if (project) {
    userHasPermission = project.roles.find((a) => a.roles === permissionName);
  }
  return userHasPermission;
};
