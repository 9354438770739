import React from "react";
import { ErrorMessage, useField, handleChange } from "formik";

const SelectField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className={label ? 'form-group primary-select' : 'form-group primary-select no-label'}>
        {label ? 
          <label htmlFor={field.name} className="form-label m-0 pl-2">
            {label}
          </label>
        : null}

        {/* <input
                    type={props.type}
                    {...field}
                    {...props}
                    className={`form-control w-100 ${
                        meta.touched && meta.error && "is-invalid"
                    }`}
                    placeholder={props.placeholder}
                    autoComplete="off"
                /> */}

        <select
          name="color"
          // value={values.color}
          className={`form-control w-100 ${
            meta.touched && meta.error && "is-invalid"
          }`}
          placeholder={props.placeholder}
          {...props}
          {...field}
          style={{ display: "block" }}
        >
          <option value="">{props.placeholder}</option>
          {props.projectlist &&
            props.projectlist.length > 0 &&
            props.projectlist.map((data, index) => (
              <option key={data.id} value={data.id}>
                {data.name}
              </option>
            ))}
        </select>
        <ErrorMessage
          name={field.name}
          component="span"
          className="field-error"
        />
      </div>
    </>
  );
};

const styles = {
  label: {
    fontSize: "16px",
    color: "#000000a6",
  },
};

export default SelectField;
