import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PatternHeader from './patternheader';
import PatternFooter from './patternfooter';
import PatternChild from './patterncontentchild';
import patternJsons from './patternJson.json';
import './patterncontent.css';

const PatternContent = ({HandleRemove, instanceObject, ...props}) => {
    let createInstanceObject = patternJsons[instanceObject.patNo][instanceObject.title].columns;
    const [showTitleModal, setShowTitleModal] = useState(false);
    const [titleMaxLength, setTitleMaxLength] = useState(0);
    const [titleRemainingLength, setTitleRemainingLength] = useState(0);
    const [buttonActive, setButtonActive] = useState(true);
    const [BoxTitleValue , setBoxTitleValue] = useState('');
    const [titleTextSize, setTitleTextSize ] = useState();
    const [selectedArea , setSelectedArea] = useState('');

    // box title functions start
    const handleCloseTableModal = () => {
        setShowTitleModal(false);
        setTitleRemainingLength(0);
    }

    const handleTitleTextSubmit = () => {
        alert(selectedArea)
        boxTitle(selectedArea);
        // setShowDataModal(false);
        setTitleRemainingLength(0);
    }

    const handleShowTitleModal = (event) => {
        var element = event.target.parentNode;
        // console.log(element.offsetWidth , "current Element width");
        var elementWidth = element.offsetWidth;
        setSelectedArea(event.target.parentNode.className);
        // use width to get characters limit
        var elementCharCapacity = parseInt((elementWidth/8) * 1.3);
        setTitleMaxLength(elementCharCapacity);
        setTimeout(() => {
            setShowTitleModal(true);
        } , 1000)
    }

    const handleTitleChange = (event) =>{
        setTitleRemainingLength(titleMaxLength - event.target.value.length);
        setBoxTitleValue(event.target.value);
    }

    const boxTitle = (element) => {
        let elementPath = `.` + element + ` .content-box` ;
        let heading = document.createElement("h3");
        let dvTitle = document.querySelector(elementPath);
        dvTitle.innerHTML = "";
        heading.innerHTML = BoxTitleValue;
        setButtonActive(false);
        setTimeout(()=>{
            dvTitle.appendChild(heading);
        } , 100)
        setShowTitleModal(false);
        setBoxTitleValue('');
    }

    const handleReset = () => {
        let elementPath = `.` + selectedArea + ` .content-box` ;
        // console.log(elementPath);
        let resetElement = document.querySelector(elementPath);
        // console.log(resetElement);
        resetElement.innerHTML = "";
        setButtonActive(true);
    }


    return (
        <>
            <div className="auto-volume-p1">
                <PatternHeader InstanceObj={instanceObject} InstanceIndex={props.InstanceIndex} />
                <section className="body-content-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body-title">
                                    <div className="content-box w2-100 h2-100">

                                    </div>
                                    {buttonActive ?
                                        <button id={instanceObject.title} onClick={(e) => handleShowTitleModal(e)}>
                                            Set Main Heading Title
                                        </button>
                                    :
                                        <button type="button" className="reset-button btn-absolute" onClick={handleReset}>Reset</button>
                                    }
                                </div>
                                <div className="body-content d-flex flex-wrap justify-content-between">
                                    {createInstanceObject.map((column , index) => (
                                        <div key={index + 10} className={`d-inline-flex flex-wrap h2-100 w2-` + column.width}>
                                            {column.row.map((row_ , index) => (
                                                <>
                                                    <div
                                                    key={index + 1}
                                                    className={`d-inline-flex flex-wrap justify-content-between h2-` + row_.height + ` w2-` + row_.width}>
                                                        {row_.columns.map((row_column , index) => (
                                                            <div
                                                             key={index + `01`}
                                                             id={"Index" + props.InstanceIndex + "-" + row_column.id}
                                                             className={`p-bg d-inline-flex flex-wrap mxy-3 he-` + row_column.height + ` wi-` + row_column.width}>
                                                                <PatternChild
                                                                    boxId={"Index" + props.InstanceIndex + "-" + row_column.id}
                                                                    tableRatioProp={(column.width/row_.columns.length)  + "x" + row_.height}
                                                                    buttonCondition={row_.height}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PatternFooter/>
            </div>
            {/* <div className="html2pdf__page-break"></div> */}

            {/* Page Main Title Input Modal */}
            <Modal className="Block-title-modal" show={showTitleModal} onHide={handleCloseTableModal}>
                <Modal.Header>
                    <Modal.Title>Enter Page Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea rows="3" cols="50" className="mx-auto" maxLength={titleMaxLength} style={{fontSize: titleTextSize}} onChange={(e) => handleTitleChange(e)}></textarea>
                    {titleRemainingLength > 0 ?
                        <span className={ titleRemainingLength >= 15 ? "remain-char green" : titleRemainingLength >= 10 ? "remain-char yellow" : "remain-char red"}>{titleRemainingLength}</span>
                    :
                        null
                    }
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" onClick={handleTitleTextSubmit}>
                        Submit
                    </Button>
                    <Button variant="outline-dark" onClick={handleCloseTableModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PatternContent;
