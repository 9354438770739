import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import InputField from "../../../../components/InputField/InputField";
import { KeyUpdatesAddCategoryAPI } from "../../../../api/ApiList";
import { Cookies, useCookies } from "react-cookie";
import { toast } from "react-toastify";
// import '../helper';

type AppProps = {
  addModalShow: Boolean;
  setCount: Function;
  handleAddModalShow: Function;
  count: number;
};

const AddKeyUpdateCategory = ({
  count,
  setCount,
  addModalShow,
  handleAddModalShow,
}: AppProps) => {
  const [cookies, setCookies] = useCookies();

  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
  });
  ////end-validation////////

  const handleAddCategory = (categoryName, { setFieldError }) => {
    let data = new FormData();
    data.append("name", JSON.stringify(categoryName.name));

    axios({
      method: "POST",
      url: KeyUpdatesAddCategoryAPI,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${
          cookies.auth.access_token ?? cookies.auth.access_token
        }`,
      },
    })
      .then((res) => {
        console.log("Response from server", res.data);
        setCount(count + 1);
        handleAddModalShow();
        toast.success(res?.data.success, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((err) => {
        console.log(err.response);
        var result = err.response.data.error;
        Object.keys(result).map(function (key) {
          setFieldError(key, result[key][0]);
        });
      });
  };

  const close = () => {
    handleAddModalShow();
  };

  return (
    <>
      <Modal
        className="table-modal add-category-modal"
        show={addModalShow ? true : false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="text-left d-inline-block w-100">
            Add Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              name: "",
            }}
            validationSchema={validate}
            onSubmit={(values, { setFieldError }) => {
              let data = new FormData();
              data.append("name", JSON.stringify(values.name));

              axios({
                method: "POST",
                url: KeyUpdatesAddCategoryAPI,
                data: data,
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Access-Control-Allow-Origin": "*",
                  Authorization: `Bearer ${
                    cookies.auth.access_token ?? cookies.auth.access_token
                  }`,
                },
              })
                .then((res) => {
                  handleAddModalShow();
                  toast.success(res?.data.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  setCount(count + 1);
                })
                .catch((err) => {
                  console.log(err);
                  var result = err.response.error.name[0];
                  console.log(result, "add category 123123123");
                  Object.keys(result).map(function (key) {
                    setFieldError(key, result[key][0]);
                  });
                });
            }}
          >
            {(formik) => (
              <>
                <Form>
                  <InputField
                    type="text"
                    label="Category Name"
                    name="name"
                    placeholder="Enter Category Name"
                  />
                  <div className="form-group d-inline-flex justify-content-end w-100 position-relative">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2"
                      name="submit"
                    >
                      Add Category
                    </button>
                    <button
                      type="button"
                      className="btn btn-cancel"
                      onClick={close}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddKeyUpdateCategory;
