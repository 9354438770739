import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../../assets/loader-small.gif";
import "./ViewAssignments.css";

type AppProps = {
  data: any;
  viewAssign: boolean;
  setViewAssign: Function;
  _modalData: any;
  emptyViewModalState: Function;
};

export const ViewAssignments = ({
  data,
  viewAssign,
  setViewAssign,
  _modalData,
  emptyViewModalState,
}: AppProps) => {
  const handleClose = () => {
    setViewAssign(false);
  };

  const [loadingText, setLoadingText] = useState("loading . . . ");

  //   useEffect(() => {
  //     const timer = setTimeout(() => {
  //       setLoadingText("No Record Found . . . .");
  //     }, 5000);
  //     return () => setLoadingText("Loading . . . ");
  //   }, []);

  //   console.log(_modalData);

  return (
    <Modal
      className="table-modal assign-user-modal"
      show={viewAssign}
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="text-left d-inline-block w-100">
          Assign User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="assign-view-block">
          {/* {data[0] &&
          data[0].sector_companies &&
          data[0].sector_companies.length > 0 ? (
            <>
              {data[0].sector_companies.map((s) => (
                <div className="assign-items">
                  <h3>{s.sector_name}</h3>
                  <ul className="list-unstyled m-0 d-flex flex-wrap justify-content-start">
                    {s.company_name.map((c, i) => (
                      <>
                        <li key={i} className="my-0">
                          {c}
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          ) : (
            <div className="assign-items"> No Record Found</div>
          )} */}

          {/* =============== New View Assigned Company Logic =============== */}

          {_modalData ? (
            <>
              {_modalData?.map((s) => {
                console.log(s);
                return (
                  <div className="assign-items">
                    <h3>{s?.sector_name}</h3>
                    <ul className="list-unstyled m-0 d-flex flex-wrap justify-content-start">
                      {s?.companies?.map((c, i) => (
                        <>
                          <li key={i} className="my-0">
                            {c.name}
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="view-assigned-user-modal">
              <img src={Loader} alt="" />
            </div>
          )}
        </div>
        <div className="form-group d-inline-flex justify-content-end w-100 position-relative m-0">
          <button
            type="button"
            className="btn btn-cancel ml-2"
            name="cancel"
            onClick={() => {
              emptyViewModalState();
              handleClose();
            }}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewAssignments;
