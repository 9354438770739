import React, { useState, useEffect, CSSProperties } from "react";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { color } from "@amcharts/amcharts5";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FindRequiredPermission } from "../../../../AuthFunctions";

export default function RevenueDriverEditor({
  initial,
  handleChange,
  companySelected,
  createPayload,
  submitCompanyInformation,
  submitCompanyInformationDraft,
  sendForApproval,
  userHasRoles,
  approveFinalDraft,
  draftModeFlage,
}) {
  const [length, setlength] = useState(0);
  // console.log("INITIAL DATA IN revenue_driver ====>", initialVal);
  return (
    <>
      <div
        className="company-introduction-editor"
        style={styles.CompanyIntroductionEditor}
      >
        <CKEditor
          editor={Editor}
          data={`${initial}`}
          config={
            {
              // toolbar: ['bold', 'align', 'paragraph', 'indent', 'blockQuote', 'italic', 'numberedList', 'bulletedList']
            }
          }
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            editor.data.set(initial);
            const wordCountPlugin = editor.plugins.get("WordCount");
            const wordCountWrapper = document.getElementById(
              "revenue_driver_wordcount"
            );
            wordCountWrapper?.appendChild(wordCountPlugin.wordCountContainer);

            // console.log( 'Editor is ready to use!', editor );
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            createPayload("revenue_drivers", data);
            const strippedString = data.replace(/(<([^>]+)>)/gi, "");
            const dataLength = strippedString.replaceAll("&nbsp;", " ").length;
            // console.log(strippedString)
            setlength(dataLength);
            createPayload("revenue_drivers", data);
          }}
        />
        <p
          className="dataLength"
          style={length < 151 ? styles.dataLength : styles.dataLengthMax}
        >
          {length < 0 ? 0 : length}/150
        </p>
        {/* <div id='revenue_driver_wordcount'></div> */}
      </div>
      <div className="w-100 d-flex flex-row-reverse">
        <span
          onClick={() => {
            submitCompanyInformationDraft();
          }}
          className="btn btn-cancel"
        >
          Save As Draft
        </span>
        {FindRequiredPermission(userHasRoles, "Ask Analyst", "Contributor") ? (
          <span
            className="btn btn-cancel mr-2"
            onClick={() => {
              console.log("Approving Draft");
              sendForApproval();
            }}
          >
            Send For Approval
          </span>
        ) : (
          <></>
        )}

        {draftModeFlage ? (
          FindRequiredPermission(userHasRoles, "Ask Analyst", "Admin") ||
          (FindRequiredPermission(userHasRoles, "Ask Analyst", "Approver") &&
            draftModeFlage) ? (
            <span
              className="btn btn-cancel mr-2"
              onClick={() => {
                console.log("rejecting Draft");
              }}
            >
              {draftModeFlage ? "Reject Draft" : "Delete Draft"}
            </span>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {FindRequiredPermission(userHasRoles, "Ask Analyst", "Admin") ||
        FindRequiredPermission(userHasRoles, "Ask Analyst", "Approver") ? (
          <span
            className="btn btn-cancel mr-2"
            onClick={() => {
              approveFinalDraft();
            }}
          >
            {!draftModeFlage ? "Submit into Database" : "Approve Draft"}
          </span>
        ) : (
          <></>
        )}

        {/* <button
          className="btn btn-primary mr-2"
          // style={styles.button}
          onClick={() => submitCompanyInformation()}
        >
          save
        </button> */}
      </div>
      {/* <button className='btn btn-primary' onClick={ () => console.log(initial) }>show initial value</button> */}
    </>
  );
}

const styles: { [key: string]: CSSProperties } = {
  CompanyIntroductionEditor: {
    height: "100%",
    paddingBottom: "30px",
  },
  dataLength: {
    color: "green",
    padding: "5pt",
    margin: "0",
    position: "absolute",
    right: "0",
    top: "0",
  },
  dataLengthMax: {
    color: "red",
    padding: "5pt",
    margin: "0",
    position: "absolute",
    right: "0",
    top: "0",
  },
  button: {
    margin: "auto",
    background: "rgb(57, 105, 213)",
    minWidth: "100px",
    marginLeft: "calc(100% - 100px)",
    marginBottom: "20px",
  },
};
