import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomSearchBar from "../../../../components/CustomSearchBar/CustomSearchBar";
import Select from "react-select";
import "./index.css";

const FinancialsFilter = ({
  listOfCompanies,
  fetchFinancialData,
  companyFilter,
  setCompanyFilter,
}) => {
  const [searchItems, setSearcItems] = useState([]);

  const types = [
    { value: "bs", label: "Balance Sheet" },
    { value: "pl", label: "Income Statement" },
  ];

  const periods = [
    { value: "quarter", label: "Quarter" },
    { value: "annual", label: "Annual" },
  ];

  const handleQuerySelect = (company) => {
    setCompanyFilter({ ...companyFilter, company_id: company.id });
  };

  const searchCompany = () => {};

  useEffect(() => {}, []);

  return (
    <>
      <div className="financialFilter w-100">
        <div className="col-xl-5 col-12 mb-xl-0 mb-2 pl-0">
          {listOfCompanies ? (
            <CustomSearchBar
              suggestions={listOfCompanies ? listOfCompanies : [{}]}
              handleQuerySelect={handleQuerySelect}
            />
          ) : (
            "loading...."
          )}
        </div>
        <div className="col-xl-2 col-3 pl-0" style={{ zIndex: "99999" }}>
          <Select
            defaultValue={{ value: "bs", label: "Balance Sheet" }}
            onChange={(option) =>
              setCompanyFilter({ ...companyFilter, type: option?.value })
            }
            options={types}
            // style={{minHeight:"35px"}}
          />
        </div>
        <div className="col-xl-2 col-3 pl-0" style={{ zIndex: "99999" }}>
          <Select
            defaultValue={{ value: "quarter", label: "Quarter" }}
            onChange={(option) =>
              setCompanyFilter({ ...companyFilter, period: option?.value })
            }
            options={periods}
          />
        </div>
        <div className="col-1 pl-0">
          <button
            style={styles.searchBtn}
            onClick={() => {
              fetchFinancialData(companyFilter);
            }}
            className="btn btn-primary"
          >
            Search
          </button>
        </div>
      </div>
    </>
  );
};

export default FinancialsFilter;

const styles = {
  searchBtn: {
    background: "#3969D5",
    width: "100px",
  },
};
