import React from 'react'

const PatternFooter = () => {
    return (
        <>
            <footer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex flex-wrap">
                                <div className="col-md-3">
                                    <div className="foot-content footer-date">
                                        <span className="d-inlne-block">September 1, 2021 </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="foot-content footer-poweredby">
                                        <span className="d-inline-block">Powered by Alpha Analyst | Refer to last page(s) for disclosures and disclaimers</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="foot-content footer-count">
                                        <span className="d-inline-block">1</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default PatternFooter;
