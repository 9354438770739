import React, { useEffect, useState } from 'react'
import UserLogTable from './UserLogTable';
import axios from 'axios';
import MainLayout from '../MainLayout/MainLayout';

export default function UserLog() {

  const [log, setLog] = useState();

  useEffect(() => {
    axios.get("https://brain.akseerresearch.com/api/activitylog")
      .then((res) => {
        console.log("Log Data ======>", res.data)
        setLog(res.data);
      })
      .catch((err) => console.log(err));
  }, [])

  console.log("form main component ====>", log);

  return (
    <MainLayout>
    <div className="container-fluid">
       <div className="row">
           <div className="col-12">
               <div className="user-log-table">
                  {(log) ? <UserLogTable data={log}/> : "Loading...."}
               </div>
           </div>
       </div>
    </div>                        
   </MainLayout>
  )
}
