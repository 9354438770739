import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import "./DraftSidebar.css";
import Loader from "../../../../assets/loader-small.gif";

export default function DraftSidebar({
  toggleDraftSidebar,
  draftSidebarShow,
  triggerUpdate,
  fetchDraftCompanyInformation,
  selectCompany,
  draftApprovalMode,
  appendDraftApprovalId,
  hideDraftSidebar,
}) {
  const [draftList, setDraftList] = useState<any>();
  const [cookies, setCookies] = useCookies<any>();

  // const fetchCompanyInformation = (company_id): void => {
  //   console.log("GETING COMPANYY INFORMATION =============>", company_id);
  //   axios
  //     .post(
  //       "https://brain.akseerresearch.com/api/askanalyst/information/searched/company",
  //       { search: company_id }
  //     )
  //     .then((response) => {
  //       console.log(console.log("Company Information ====>", response))
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  /**
   * Hook that alerts clicks outside of the passed ref
   */

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          hideDraftSidebar();
          // console.log("Alert =====>");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * Component that alerts if you click outside of it
   */
  // export default function OutsideAlerter(props) {
  //   const wrapperRef = useRef(null);
  //   useOutsideAlerter(wrapperRef);

  //   return <div ref={wrapperRef}>{props.children}</div>;
  // }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    axios({
      method: "POST",
      url: "https://brain.akseerresearch.com/api/askanalyst/information/company/draft/list/user",
      data: { user_id: cookies?.auth?.user?.id },
    })
      .then((res) => {
        // console.log("THE DRAFT LIST ======>", res.data);

        setDraftList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [triggerUpdate]);

  // console.log("COOKIES +=========>", cookies?.auth?.user?.id);

  if (draftList) {
    return (
      <div
        ref={wrapperRef}
        className={`draft-sidebar draft-slider ${
          draftSidebarShow ? "draft-sidebar-active" : ""
        }`}
      >
        <span
          onClick={() => {
            toggleDraftSidebar();
          }}
          className={`btn draft-sidebar-close-btn`}
        >
          x
        </span>
        <div>
          <ul>
            {draftList.map((d, i) => {
              return (
                <li>
                  <button
                    className="btn draft-btn"
                    data-draft_id={d.id}
                    onClick={(e: any) => {
                      fetchDraftCompanyInformation(
                        e.target.getAttribute("data-draft_id")
                      );
                      appendDraftApprovalId(
                        e.target.getAttribute("data-draft_id")
                      );
                      draftApprovalMode(false);
                    }}
                  >
                    Draft #{i + 1}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="draft-sidebar-loader">
        {/* <img className="w-100" src={Loader} alt="" /> */}
      </div>
    );
  }
}
