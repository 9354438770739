import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/SelectField/SelectField";
import { concat } from "lodash";
import { roleseditAPI } from "../../../../api/ApiList";
import { toast } from "react-toastify";

const RoleEditModal = (props): React.FunctionComponentElement<Element> => {
  const [able, setAble] = useState<boolean>(false);

  const validate = Yup.object({
    role: Yup.string().required("Role is required"),
    project: Yup.string().required("Project is required"),
  });
  ////end-validation////////

  return (
    <>
      <Modal
        className="table-modal edit-project-modal"
        show={props.editmodalshow ? true : false}
        centered
      >
        <Modal.Header>
          <Modal.Title
            className="text-left d-inline-block w-100"
            onClick={props.closemodal}
          >
            Edit Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Formik
              initialValues={{
                role: props.editdata.role,
                project: props.editdata.project_id,
              }}
              validationSchema={validate}
              onSubmit={async (values, { setFieldError }) => {
                // console.log(values);
                setAble(true);
                axios({
                  headers: { "Content-Type": "aplication/json" },
                  method: "PUT",
                  url: `${roleseditAPI}/${props.editdata.id}`,
                  data: values,
                })
                  .then(function (response) {
                    toast.success("Roles Edit Succesful");
                    props.closemodal();
                    setAble(false);
                  })
                  .catch(function (error) {
                    toast.error("There was an Error");
                    setAble(false);
                    var result = error.response.data.error;
                    Object.keys(result).map(function (key) {
                      setFieldError(key, result[key][0]);
                    });
                  });
              }}
            >
              {(formik) => (
                <>
                  <Form>
                    {/* <InputField {...props} type="text" label="Project Name" name="name" inputvalue={editName}  placeholder="Enter Project Name"/> */}

                    <div className="form-group">
                      <SelectField
                        label="Project"
                        name="project"
                        placeholder="Select a Project"
                        projectlist={props.projectlist}
                        onChange={formik.handleChange}
                        value={formik.values.project}
                      />
                      <InputField
                        type="text"
                        label="Role"
                        name="role"
                        placeholder="Enter Role Name"
                        onChange={formik.handleChange}
                        value={formik.values.role}
                      />
                    </div>

                    <div className="form-group d-inline-flex justify-content-end w-100 position-relative">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={able}
                        name="submit"
                        style={{ background: "#3969d5" }}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-light btn-cancel ml-2"
                        name="cancel"
                        onClick={props.closemodal}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RoleEditModal;
