import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import { useCookies } from "react-cookie";
import bellIcon from "../../../../assets/media/bell.svg";
import { Link } from "react-router-dom";
import "./CompanyInformationNotifications.css";

type AppProps = {
  notify: boolean;
  setNotify: Function;
  notificationRequest: Function;
  draftApprovalMode: Function;
  resetSearchBarValue: Function;
  fetchDraftCompanyInformation: Function;
  draftApprovalIdParam: any;
};

const CompanyInformationNotifications = ({
  notify,
  setNotify,
  notificationRequest,
  draftApprovalMode,
  resetSearchBarValue,
  fetchDraftCompanyInformation,
  draftApprovalIdParam,
}: AppProps) => {
  const [hideBlinker, setHideBlinker] = useState<boolean>(true);
  const [cookies, setCookies] = useCookies<any>();
  const [notifications, setNotifications] = useState<any>();

  useEffect(() => {
    const pusher = new Pusher("cf736425b7e4224a6bb2", {
      cluster: "ap2",
      forceTLS: true,
      // encrypted: "true",
    });
    const channel = pusher.subscribe("company-description-channel");
    channel.bind("company-description-updates", (data) => {
      setNotify(true);
      getNotifications();
    });
  }, []);

  useEffect(() => {
    getNotifications();
  }, [notify]);

  const getNotifications = () => {
    axios
      .get("https://brain.akseerresearch.com/api/notifications")
      .then((res) => {
        // console.log("Company Information Notifications ====>", res);s
        setNotifications(res.data);
      })
      .then((err) => console.log(err));
  };

  const notifyList = notifications?.map((item: any) => (
    <li
      data-compId={item.company_id}
      onClick={(e: any) => {
        console.log(
          "Company ID in Notification ======>",
          e.target.getAttribute("data-compId")
        );
      }}
    >
      <Link
        to={`/analyst-panel/${item.id}`}
        data-compId={item.company_id}
        data-userId={item.user_id}
        data-type={item.type}
        data-period={item.period}
        onClick={(e: any) => {
          // alert("drafts for approval");
          // console.log("NOTIFICATION ITEM", item);
          resetSearchBarValue();
          draftApprovalMode(true);
          // fetchDraftCompanyInformation(draftApprovalIdParam);
        }}
      >
        {item.message}
      </Link>
    </li>
  ));

  return (
    <div className="company-information-notification-parent">
      <div
        className={`main-blinker ${hideBlinker ? "hide" : ""}`}
        onMouseLeave={() => {
          setHideBlinker(!hideBlinker);
        }}
      >
        <p
          className=""
          onClick={() => {
            setHideBlinker(!hideBlinker);
          }}
        >
          <span className="count">
            {notifications !== undefined ? notifications.length : 0}
          </span>
          <span>
            <img src={bellIcon} alt="bell" />
          </span>
          {!hideBlinker ? "New Approval Request" : ""}
        </p>
        <ul className="list-unstyled m-0">{notifyList}</ul>
      </div>
    </div>
  );
};

export default CompanyInformationNotifications;
