// import LoginPage from './layout/LoginPage/LoginPage';
import LoginPage from "./layout/LoginPage/LoginPage";
import { Route, Router, Routes } from "react-router-dom";
// import ProtectedRoute from './ProtectedRoute';
import Dashboard from "./layout/Dashboard/Dashboard";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ---------------------- User Managment Imports --------------------------------
import UserManagement from "./module/UserManagment";

// ---------------------- Charting Tool Imports ----------------------------------
import ChartingTool from "./module/ChartingTool/Index";

// ---------------------- Global Search Listing Imports ----------------------------------
import GlobalSearchListing from "./module/GlobalSearchListing/GlobalSearchListing";

// ---------------------- User Logs Imports --------------------------------------
import UserLog from "./layout/UserLogs/UserLog";

// ---------------------- Analyst Panel Imports ----------------------------------
import AnalystPanel from "./module/AnalystPanel";

// ---------------------- Contact Dairy Imports ----------------------------------
import ContactDairy from "./module/ContactDairy/Index";

// ---------------------- Policy SOPs Imports ----------------------------------
import PolicySOPs from "./module/PolicySOP/Index";

// ---------------------- Key updates Imports ------------------------------------
import KeyUpdates from "./module/KeyUpdates/Index";
import KeyUpdatesDetail from "./module/KeyUpdates/sections/KeyUpdatesDetail/KeyUpdatesDetail";
import AddKeyUpdate from "./module/KeyUpdates/sections/AddKeyUpdate/AddKeyUpdate";
import EditKeyUpdate from "./module/KeyUpdates/sections/EditKeyUpdate/EditKeyUpdate";

import "./App.css";
import { useCookies } from "react-cookie";
import { ASK_ANALYST } from "./Permisions";
import NotFoundPage from "./layout/NotFoundPage/NotFoundPage";

function App() {
  const [cookies, setCookies] = useCookies();

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${cookies?.auth?.access_token}`;
  return (
    <div className="App">
      <ToastContainer draggable={false} transition={Bounce} autoClose={5000} />
      <Routes>
        <Route path="/" exact element={<LoginPage />} />
        <Route path="/dashboard" exact element={<Dashboard />} />

        {/*  ---------------------- Key updates routes  ----------------------------------- */}
        <Route path="/keyupdates" element={<KeyUpdates />} />
        <Route path="/keyupdatedetail/:id" element={<KeyUpdatesDetail />} />
        <Route path="/keyupdates/addkeyupdate" element={<AddKeyUpdate />} />
        <Route
          path="/keyupdates/editkeyupdate/:id"
          element={<EditKeyUpdate />}
        />
        {/*  ---------------------- Key updates routes  ----------------------------------- */}

        {/*  ---------------------- User Managment Routes  ----------------------------------- */}
        <Route path="/user/managment/*" element={<UserManagement />} />

        {/* <Route path="/analyst-panel" element={<ProtectedRoute component={<AnalystPanel />} projectPermisionRequired={ASK_ANALYST} />} /> */}
        <Route path="/analyst-panel" element={<AnalystPanel />} />
        <Route
          path="/analyst-panel/:draftApprovalId"
          element={<AnalystPanel />}
        />

        {/*  ----------------------    Charting Tool Routes   ----------------------------------- */}
        <Route path="/chartingtool" element={<ChartingTool />} />

        {/*  ----------------------    Global Search Listing Routes   ----------------------------------- */}
        <Route path="/global-search-listing" element={<GlobalSearchListing />} />

        {/*  ----------------------    Contact Directory routes   ------------------------ */}
        <Route path="/contact-directory" element={<ContactDairy />} />

        {/*  ----------------------    User Logs   --------------------------------------- */}
        <Route path="/user-logs" element={<UserLog />} />

        {/*  ----------------------    Policy SOPs   ------------------------------------- */}
        <Route path="/policy-sop" element={<PolicySOPs />} />

        {/*  ----------------------  PAGE NOT FOUND   ----------------------------------- */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
