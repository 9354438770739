import React, { useState } from "react";
import axios from "axios";
import { Cookies, useCookies } from "react-cookie";
// import CustomSearchBar from "../../../../components/CustomSearchBar/CustomSearchBar";
import CompanyInformationSearchbar from "../../components/CompanyInformationSearchbar/CompanyInformationSearchbar"; 
import Select from "react-select";
import {
  ISelectedCompany,
  ICompanyInformation,
} from "../../Interfaces/AnalystPanelInterfaces";


type AppProps = {
  setSelectedCompanyData: Function
}

export default function SearchBarWithFilters( {setSelectedCompanyData} : AppProps) {
  const [searchItems, setSearcItems] = useState([]);
  const [cookies, setCookies] = useCookies();
  const [companySelected, setCompanySelected] = useState<ISelectedCompany>();
  const [defaultCompanySelect, setDefaultCompanySelect] = useState<number>(0);
  const [fetchLoader, setFetchLoader] = useState<boolean>(true);

  const selectCompany = (company): void => {
    setCompanySelected(company);
    setSelectedCompanyData(company);
    // console.log(company , "company in recent development");
    // fetchCompanyInformation(company.id);
  };

  // const fetchCompanyInformation = (company_id): void => {
  //   setFetchLoader(false);
  //   console.log("GETING COMPANYY INFORMATION =============>", company_id);
  //   axios
  //     .post(
  //       "https://brain.akseerresearch.com/api/askanalyst/information/searched/company",
  //       { search: company_id }
  //     )
  //     .then((response) => {
  //       console.log(re)
  //       setDefaultCompanySelect(() => {
  //         return defaultCompanySelect + 1;
  //       });
  //       setFetchLoader(true);
  //     })
  //     .catch((err) => {
  //       setFetchLoader(true);
  //       console.log(err);
  //     });
  // };

  const __handleOnSelect = (item) => {
    // setFetchingDataLoader(true);
    // dispatch(_setSelectedData(item));

    console.log("This is response item =====> item1", item)
    // console.log("This is response item =====> id", id)

    console.log("SEARCHING FOR COMPANIESS ====>");
    axios
      .post(
        "https://brain.akseerresearch.com/api/information/searched/company",
        {
          search: 215,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.auth.access_token}`,
          },
        }
      )
      .then(function (response) {
        // setSearchSelected(item);
        // setSearchItems(response.data);
        // setAllData(response.data.api);
        // setFetchingDataLoader(false);
        // setLoadingFlag(true);
        // console.log(item , "item")
        console.log("Response from company search api", response.data.api);
        // store.dispatch(actions.sectorSearch(response.data[0].name));
      })
      .catch(function (error) {
        //   setFetchingDataLoader(false);
        //   setAlert(true);
      });
  };

  const handleQuerySelect = (id, value) => {
    __handleOnSelect(id);
  };

  return (
    <>
      <div className="col-xl-5 mb-xl-0 mb-2 pl-xl-0">
        {searchItems ? (
          // <CustomSearchBar
          //   suggestions={searchItems ? searchItems : [{}]}
          //   handleQuerySelect={handleQuerySelect}
          // />
          <CompanyInformationSearchbar onSelect={selectCompany} />
        ) : (
          "loading...."
        )}
      </div>
      <div className="col-xl-2 mb-xl-0 mb-2 pl-xl-0">
        <Select placeholder="Select Category" />
      </div>
      <div className="col-xl-2 mb-xl-0 mb-2 pl-xl-0">
        <Select placeholder="Select Year" />
      </div>
      <div className="col-xl-2 mb-xl-0 mb-2 pl-xl-0">
        <Select placeholder="Select Quarter" />
      </div>
      <div className="col-xl-1 p-xl-0">
        <button style={styles.button} className="w-100 btn btn-primary h-100">
          Search
        </button>
      </div>
    </>
  );
}

const styles = {
  button: {
    // margin: "auto",
    // position: "absolute",
    // right: "30px",
    // bottom: "0",
    background: "#3969D5",
    // minWidth: "130px"
  },
};
