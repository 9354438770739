import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import EditButton from "../../../components/EditButton/EditButton";
import { ProjectUsers as data } from "../../../Modals/Data";
// import { ListOfPermissionsData } from "../../../Modals/Data";
import InlineItemList from "../../../components/InlineItemList/InlineListItem";
import InlineListItem from "../../../components/InlineItemList/InlineListItem";
import PermissionModal from "../../../components/PermissionModal/PermissionModal";
import axios from "axios";
import PermissionInlineList from "../PermissonInlineList/PermissionInlineList";
import { permissionUpdateAPI } from "../../../../../api/ApiList copy";
const Loader = require("../../../../../assets/loader.gif");

export default function ListOfPermissionsByProject(): React.FunctionComponentElement<Element> {
  const columns = [
    {
      name: "#",
      selector: (row) => {
        return row.id;
      },
      grow: 0.5
    },
    {
      name: "Project",
      selector: (row) => {
        return row?.projectName;
      },
      grow: 1
    },
    {
      name: "Role",
      selector: (row) => {
        return row?.roleName;
      },
      grow: 1
    },
    {
      name: "Permissions",
      selector: (row) => {
        return <PermissionInlineList data={row.permission} />;
      },
      grow: 4
    },
    {
      name: "Edit",
      selector: (row) => {
        return <EditButton onClick={() => toggleEditModal()} />;
      },
      grow: 1
    },
  ];

  const [showCreatePermissionModal, setShowCreatePermissionModal] =
    useState<boolean>(false);
  const [showEditPermissionModal, setShowEditPermissionModal] =
    useState<boolean>(false);
  const [ListOfPermissionsData, setListOfPermissionsData] = useState<any>();

  const toggleModal = () => {
    setShowCreatePermissionModal(!showCreatePermissionModal);
  };

  const toggleEditModal = () => {
    setShowEditPermissionModal(!showEditPermissionModal);
  };

  useEffect(() => {
    axios
      .get("https://brain.akseerresearch.com/api/project")
      .then((res) => {
        console.log("List of permissions ====>", res);
        // setListOfPermissionsData(res.data);

        let modifiedListOfProjects: any = [];
        let _modifiedPermission: any;

        let count = 1;
        res.data.map((d) => {
          d.roles.map((r, index) => {
            modifiedListOfProjects.push({
              id: count,
              projectId: d.id,
              projectName: d.name,
              roleName: r.role,
              permission: r.permission,
            });
            r.permission.map((p) => {
              // _modifiedPermission.push(`${p.permission_name}`);
            });
            count++;
          });

          // console.log(
          //   "Final ListOfPermission after algorithm ===>",
          //   modifiedListOfProjects
          // );
        });
        setListOfPermissionsData(modifiedListOfProjects);

        // console.log("Length of permissions =====>", res.data.permission.length);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log("THE LIST OF PERMISSION ------>", ListOfPermissionsData);

  if (data) {
    return (
      <>
        <div className="d-inline-flex flex-wrap w-100 project-main px-4">
          <div className="d-inline-flex justify-content-end add-project-filter px-0">
            <div className="primary-select form-group mb-0 mr-3">
              <span className="m-0">
                <select className="form-control">
                  <option value="">Ask Analyst Admin</option>
                  <option value="">ESG</option>
                  <option value="">Ask Analyst</option>
                  <option value="">Brain</option>
                </select>
              </span>
            </div>
            <div className="form-group mb-0">
              <button
                onClick={toggleModal}
                style={{ width: "180px" }}
                className="btn btn-primary"
              >
                + Create Permission
              </button>
            </div>
          </div>
          <div className="brain-table-card">
            <div className="brain-table-card-header">
              <h3>List of Permissions</h3>
            </div>
            <div className="brain-table-card-body">
              <DataTable
                data={ListOfPermissionsData}
                columns={columns}
                pagination
              />
            </div>
          </div>
        </div>

        {/* Create Permission Modal */}
        <PermissionModal
          title={"Create Permission"}
          show={showCreatePermissionModal}
          toggleShow={toggleModal}
        />

        <PermissionModal
          title={"Edit Permission"}
          show={showEditPermissionModal}
          toggleShow={toggleEditModal}
        />
      </>
    );
  } else {
    return (
      <div className="full-screen-loader">
        <img src={Loader} alt="" />
      </div>
    );
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  permissionListParent: {},
  permissionListItem: {},
};
