import React , {useEffect, useState} from 'react';
import axios from 'axios';
import {ChartingDataTypeFilter , ChartingSectorFilter , ChartingCommodityFilter} from '../../../../api/ApiList';
import Select from 'react-select';
import './FilterForm.css';

const FilterForm = ({setMainFlag , setDataList , dataList , commodity , setCommodity , blurDataSeries , sector , setSector , fieldLevel , setFieldLevel}) => {
    const [dataType , setDataType] = useState([{value: null , label: ''}]);
    // const [sector , setSector] = useState([{value: null , label: ''}]);
    const [defaultCommodity , setDefaultCommodity] = useState({value: 0 , label: 'Select...'})
    const [selectedSector , setSelectedSector] = useState({value: null , label: ''});
    const [submitFlag , setSubmitFlag] = useState(false);
    const [filtersPayload , setFiltersPayload] = useState(
        {
            type: {id: null, name:""},
            sector:[{id: null, name: '' , commodity: [{id: null, name: ''}]}],
        } 
    );

    const fetchDataType = async () => {

        const response = await axios.get(ChartingDataTypeFilter);
        // console.log(response.data , "data type");
        let tempArr:any = [];
        if(response.data && response.data.length > 0){
            response.data.map( (a) => {
                tempArr.push({
                    value: a.id,
                    label: a.name
                })
            })
        }
        // console.log(tempArr , "tempArr");
        setDataType(tempArr);
    }

    const handleDataTypeChange = async (e) => {
        // console.log(e , "datatype");
        const response = await axios.get(`${ChartingSectorFilter}/${e.value}`);
        let tempArr:any = [];
        if(response.data && response.data.length > 0){
            response.data.map( (a) => {
                tempArr.push({
                    value: a.id,
                    label: a.category
                })
            })
            setFieldLevel(2);
        }
        // console.log(tempArr , "sector list");
        setSector(tempArr);
        if(dataList.type.id === null){
            const dataListClone = {...dataList};
            // console.log(dataListClone , "dataListClone");
            dataListClone.type.id = e.value
            dataListClone.type.name = e.label
            setDataList(dataListClone);
        }
    }

    const handleSectorChange = async (e) => {
        // debugger
        // console.log(e)
        setDefaultCommodity({value: 0 , label: ''});
        setSelectedSector(e);
        const response = await axios.get(`${ChartingCommodityFilter}/${e.value}`);
        // console.log(response.data , "commodity");
        let tempArr:any = [];
        if(response.data && response.data.length > 0){
            response.data.map( (a) => {
                tempArr.push({
                    value: a.id,
                    label: a.name
                })
            })
            setFieldLevel(3);
        }
        // console.log(tempArr , "Commodity list");
        setCommodity(tempArr);
        if(dataList.type.id !== null){
            const dataListClone = {...dataList};
            // console.log(dataListClone , "dataListClone");
            // console.log(dataListClone.sector[0].id , "dataListClone");
            for(let i = 0; i <= dataListClone.sector.length-1; i++ ){
                if(dataListClone.sector[0].id === null){
                    let tempObj = {id: e.value, name: e.label , commodity: [{id: null, name: ''}]}
                    dataListClone.sector[0] = tempObj;
                }else if(dataListClone.sector[i].id === e.value){
                    setDataList(dataListClone);
                    break;
                }else if(i === dataListClone.sector.length-1 && dataListClone.sector[i].id !== e.value){
                    let tempObj = {id: e.value, name: e.label , commodity: [{id: null, name: ''}]}
                    dataListClone.sector.push(tempObj)                    
                }
            }
            setDataList(dataListClone);
        }
        // console.log(dataList , "new data list");
    }

    const handleCommodityChange = (e) => {
        // debugger;
        // console.log(e , "commodity");
        setDefaultCommodity(e);
        const dataListClone = {...dataList};
        dataListClone.sector.map((s , i) => {
            // console.log(s.id , "s id sector");
            // console.log(i , "s index sector");
            if(selectedSector.value === s.id){
                let tempCommodity = {id: e.value, name: e.label}
                if(s.commodity.length > 0 && s.commodity[0].id === null){
                    dataListClone.sector[i].commodity[0] = tempCommodity;
                    setSubmitFlag(true);
                }else{
                    dataListClone.sector[i].commodity.push(tempCommodity)
                    setSubmitFlag(true);
                }
                setFiltersPayload(dataListClone);
            }
        })
        const commodityClone = [...commodity];
        // let tempCommodity = commodityClone.filter(function(c) {
        //     return (c.value !== e.value)  
        // })
        // setCommodity(tempCommodity)
    }

    const submitPayload = () => {
        debugger;
        setMainFlag(true)
        setDataList(filtersPayload);
        // console.log(props.dataList , "props.dataList");
    }

    useEffect(() => {
        fetchDataType();
    }, [])

    return (
        <div className='filter-form-block'>
            {blurDataSeries ? 
                <div className='blur'></div>
            : null}
            <div className="col-md-6 pl-0">
                <div className="form-group field-wrap d-flex justify-content-start align-items-center pl-0">
                    <label className="d-inline-block m-0 col-4 px-2"> Data Type <span className='hierarchy-level'>1</span></label>
                    <Select className="w-100 col-8 p-0" name="type" isDisabled={fieldLevel >= 1 ? false : true} closeMenuOnSelect={true} options={dataType} onChange={(e) => {handleDataTypeChange(e)}} />
                </div>
            </div>
            <div className="col-md-6 pl-0">                
                <div className="form-group field-wrap d-flex justify-content-start align-items-center pl-0">
                    <label className="d-inline-block m-0 col-4 px-2"> Sector <span className='hierarchy-level'>2</span></label>
                    <Select className="w-100 col-8 p-0" name="type" isDisabled={fieldLevel >= 2 ? false : true} closeMenuOnSelect={true} options={sector} onChange={(e) => {handleSectorChange(e)}} />
                </div>
            </div>
            <div className="col-md-6 pl-0">    
                <div className="form-group field-wrap d-flex justify-content-start align-items-center pl-0">
                    <label className="d-inline-block m-0 col-4 px-2"> Commodity <span className='hierarchy-level'>3</span></label>
                    <Select className="w-100 col-8 p-0" name="type" value={defaultCommodity} isDisabled={fieldLevel >= 3 ? false : true} closeMenuOnSelect={true} options={commodity} onChange={(e) => {handleCommodityChange(e)}} />
                </div>
            </div>
            <div className="form-group col-md-6 d-flex justify-content-start align-items-center">
                <label className="d-inline-block w-100 mb-1"></label>
                <button className='btn btn-primary' disabled={submitFlag ? false : true} onClick={submitPayload}>SUBMIT</button>
            </div>
        </div>
    )
}

export default FilterForm;