import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./sidebar.css";
import { AuthRoute } from "../../AuthFunctions";

const Sidebar = () => {
  const [cookies] = useCookies();
  const handleSidebarLeave = () => {
    document
      .querySelector(".card.child .card-header button")
      .classList.add("collapsed");
    document.querySelector(".card .collapse").classList.remove("show");
  };

  return (
    <>
      <div className="sidebar-panel" onMouseLeave={handleSidebarLeave}>
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <svg
                    width="30px"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="home-alt"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    className="svg-inline--fa fa-home-alt fa-w-18"
                  >
                    <path
                      d="M541 229.16l-232.85-190a32.16 32.16 0 0 0-40.38 0L35 229.16a8 8 0 0 0-1.16 11.24l10.1 12.41a8 8 0 0 0 11.2 1.19L96 220.62v243a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-128l64 .3V464a16 16 0 0 0 16 16l128-.33a16 16 0 0 0 16-16V220.62L520.86 254a8 8 0 0 0 11.25-1.16l10.1-12.41a8 8 0 0 0-1.21-11.27zm-93.11 218.59h.1l-96 .3V319.88a16.05 16.05 0 0 0-15.95-16l-96-.27a16 16 0 0 0-16.05 16v128.14H128V194.51L288 63.94l160 130.57z"
                      className=""
                    ></path>
                  </svg>
                  <span>
                    <Link to="/dashboard">Dashboard</Link>
                  </span>
                </button>
              </h2>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M11.375 0.875H13.125V2.625H11.375V0.875Z"
                      fill="#3969D5"
                    />
                    <path
                      d="M11.375 3.5H13.125V5.25H11.375V3.5Z"
                      fill="#3969D5"
                    />
                    <path
                      d="M8.75 0.875H10.5V2.625H8.75V0.875Z"
                      fill="#3969D5"
                    />
                    <path d="M8.75 3.5H10.5V5.25H8.75V3.5Z" fill="#3969D5" />
                    <path
                      d="M12.25 7V9.625H1.75V2.625H7V1.75H1.75C1.51794 1.75 1.29538 1.84219 1.13128 2.00628C0.967187 2.17038 0.875 2.39294 0.875 2.625V9.625C0.875 9.85706 0.967187 10.0796 1.13128 10.2437C1.29538 10.4078 1.51794 10.5 1.75 10.5H5.25V12.25H3.5V13.125H10.5V12.25H8.75V10.5H12.25C12.4821 10.5 12.7046 10.4078 12.8687 10.2437C13.0328 10.0796 13.125 9.85706 13.125 9.625V7H12.25ZM7.875 12.25H6.125V10.5H7.875V12.25Z"
                      fill="#3969D5"
                    />
                  </svg>
                  <span>Apps</span>
                </button>
              </h2>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <svg
                    widht="30px"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="users"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    className="svg-inline--fa fa-users fa-w-20"
                  >
                    <path
                      d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z"
                      className=""
                    ></path>
                  </svg>
                  <span>
                    <Link to={"/user-logs"}>User Logs</Link>
                  </span>
                </button>
              </h2>
            </div>
          </div>
          <div className="card child">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M12.6562 11.9934L11.7188 11.0559V9.84375H10.7812V11.4441L11.9934 12.6562L12.6562 11.9934Z"
                      fill="#3969D5"
                    />
                    <path
                      d="M11.25 14.5313C10.601 14.5313 9.96664 14.3388 9.42704 13.9783C8.88744 13.6177 8.46687 13.1053 8.21852 12.5057C7.97017 11.9061 7.90519 11.2464 8.0318 10.6099C8.15841 9.97336 8.47092 9.3887 8.92981 8.92981C9.3887 8.47092 9.97336 8.15841 10.6099 8.0318C11.2464 7.90519 11.9061 7.97017 12.5057 8.21852C13.1053 8.46687 13.6177 8.88744 13.9783 9.42704C14.3388 9.96664 14.5313 10.601 14.5313 11.25C14.5303 12.1199 14.1842 12.954 13.5691 13.5691C12.954 14.1842 12.1199 14.5303 11.25 14.5313V14.5313ZM11.25 8.90625C10.7865 8.90625 10.3333 9.04371 9.94788 9.30124C9.56246 9.55878 9.26205 9.92482 9.08466 10.3531C8.90727 10.7814 8.86085 11.2526 8.95129 11.7072C9.04172 12.1619 9.26494 12.5795 9.59272 12.9073C9.9205 13.2351 10.3381 13.4583 10.7928 13.5487C11.2474 13.6392 11.7187 13.5927 12.1469 13.4153C12.5752 13.238 12.9412 12.9375 13.1988 12.5521C13.4563 12.1667 13.5938 11.7136 13.5938 11.25C13.5931 10.6286 13.346 10.0328 12.9066 9.59341C12.4672 9.154 11.8714 8.90687 11.25 8.90625V8.90625Z"
                      fill="#3969D5"
                    />
                    <path
                      d="M7.5 13.125C6.00865 13.1234 4.57885 12.5302 3.52431 11.4757C2.46976 10.4212 1.87661 8.99135 1.875 7.5H0.9375C0.939485 9.23987 1.63153 10.9079 2.8618 12.1382C4.09208 13.3685 5.76013 14.0605 7.5 14.0625V13.125Z"
                      fill="#3969D5"
                    />
                    <path
                      d="M5.625 3.75005H3.31781C4.07635 2.90077 5.07507 2.30207 6.1816 2.0333C7.28813 1.76453 8.45021 1.83838 9.51381 2.24507C10.5774 2.65177 11.4923 3.37209 12.1372 4.31056C12.7821 5.24904 13.1266 6.36134 13.125 7.50005H14.0625C14.0646 6.19541 13.6771 4.91987 12.9498 3.8368C12.2224 2.75373 11.1883 1.91245 9.97989 1.42072C8.77147 0.928993 7.44376 0.809211 6.16685 1.07672C4.88993 1.34422 3.72195 1.98683 2.8125 2.92224V0.937548H1.875V4.68755H5.625V3.75005Z"
                      fill="#3969D5"
                    />
                  </svg>
                  <span>
                    Key Updates
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fal"
                      data-icon="chevron-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className="svg-inline--fa fa-chevron-down fa-w-14"
                    >
                      <path
                        fill="currentColor"
                        d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"
                        className=""
                      ></path>
                    </svg>
                  </span>
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <ul className="list-unstyled m-0">
                  <li>
                    <Link to={"/keyupdates"}>View All Updates</Link>
                    <Link to={"/keyupdates"} >
                      View All Updates
                    </Link>
                  </li>
                  <li>
                    <Link to={"/keyupdates/addkeyupdate"}>Add Key Updates</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card child">
            <div className="card-header" id="headingFour">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <svg
                    widht="30px"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="users"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    className="svg-inline--fa fa-users fa-w-20"
                  >
                    <path
                      d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z"
                      className=""
                    ></path>
                  </svg>
                  <span>
                    User Management
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fal"
                      data-icon="chevron-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className="svg-inline--fa fa-chevron-down fa-w-14"
                    >
                      <path
                        fill="currentColor"
                        d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"
                        className=""
                      ></path>
                    </svg>
                  </span>
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              className="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <h6>Users</h6>
                <ul className="list-unstyled m-0">
                  <li>
                    <Link to={"/user/managment/users"}>User List</Link>
                  </li>
                  <li>
                    <Link to={"/user/managment/users/add"}>Add User</Link>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <h6>Projects</h6>
                <ul className="list-unstyled m-0">
                  <li>
                    <Link to={"/user/managment/projects"}>Projects List</Link>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <h6>Roles</h6>
                <ul className="list-unstyled m-0">
                  <li>
                    <Link to={"/user/managment/roles"}>Roles List</Link>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <h6>Permissions</h6>
                <ul className="list-unstyled m-0">
                  <li>
                    <Link to={"/user/managment/permission"}>
                      Permissions List
                    </Link>
                  </li>
                  <li>
                    <Link to={"/user/managment/permission/accesscontrol"}>
                      Permissions Access Controls
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
