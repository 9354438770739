import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useParams, useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import EditAssign from "./editAssign";
import "./user.css";
import { toast } from "react-toastify";
import {
  userEditAPI,
  rolesActiveProjectsAPI,
  userGetAPI,
} from "../../../../api/ApiList";
// import Loader from "../../assets/loader.gif";
import Loader from "../../../../assets/loader.gif";

let userObj = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  project: {},
  role: {},
};

let initialValues = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  project: {},
  role: {},
};

const EditUser = (props) => {
  const [initials, setInitials] = useState(initialValues);
  const [user, setUser] = useState();
  const [allProjects, setAllProjects] = useState([]);
  const [userFields, setUserFields] = useState([]);
  const [assign, setAssign] = useState();
  const [projectData, setProjectData] = useState();
  const [roleData, setRoleData] = useState();
  const [able, setAble] = useState(false);
  let param = useParams();

  let history = useNavigate();

  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  useEffect(async () => {
    getUser();
    getProjectList();
  }, []);

  const handleCheck = (e) => {
    // debugger;
    var ele = document.querySelectorAll(
      ".proj-" + e.target.attributes.project.value
    );
    let projectName = e.target.attributes.projectname.value;
    let projectId = e.target.attributes.value.value;
    if (e.target.attributes.istrue.value === "true") {
      // console.log(roleData , "roleData");
      delete projectData[projectName];
      delete roleData[projectName];
      setProjectData({ ...projectData });
      setRoleData({ ...roleData });
      e.target.setAttribute("istrue", "false");
      for (var i = 0; i < ele.length; i++) {
        // ele[i].unchecked = true;
        ele[i].checked = false;
        ele[i].removeAttribute("checked");
        ele[i].setAttribute("disabled", true);
      }
    } else {
      e.target.setAttribute("istrue", "true");
      for (var i = 0; i < ele.length; i++) {
        ele[i].removeAttribute("disabled");
        ele[i].checked = false;
        // ele[i].unchecked = true;
      }
      if (projectName in projectData === false) {
        console.log("false in projects");
        setProjectData({
          ...projectData,
          [projectName]: { project_id: projectId },
        });
      }
    }
    setInitials({ ...initials, project: projectData, role: roleData });
    initialValues = initials;
    userObj.project = projectData;
    userObj.role = roleData;
  };

  const handleRadio = (e) => {
    let projectName = e.target.attributes.project.value;
    let projectId = e.target.attributes.projectid.value;
    let roleId = e.target.attributes.value.value;

    if (projectData !== undefined && projectName in projectData) {
      delete projectData[projectName];
      delete roleData[projectName];
      setProjectData({
        ...projectData,
        [projectName]: { project_id: projectId, role_id: roleId },
      });
      setRoleData({
        ...roleData,
        [projectName]: { project_id: projectId, role_id: roleId },
      });
      setInitials({ ...initials, project: projectData, role: roleData });
      initialValues = initials;
      userObj.project = projectData;
      userObj.role = roleData;
    } else {
      setProjectData({
        ...projectData,
        [projectName]: { project_id: projectId, role_id: roleId },
      });
      setRoleData({
        ...roleData,
        [projectName]: { project_id: projectId, role_id: roleId },
      });
      setInitials({ ...initials, project: projectData, role: roleData });
      initialValues = initials;
      userObj.project = projectData;
      userObj.role = roleData;
    }
  };

  function submitForm(values, setFieldError) {
    values = { ...values, project: projectData, role: roleData };
    console.log(values, "values in submit function");
    setAble(true);
    axios({
      headers: { "Content-Type": "aplication/json" },
      method: "put",
      url: `${userEditAPI}/${param.id}`,
      data: values,
    })
      .then(function (response) {
        console.log(response.data, "response submit");
        history("/user/managment/users");
        // history.push("/users",'',);
        // history.location.state = {
        //     flag: true,
        //     message: "User Edit Successfully",
        //     color: "success"
        // };
        toast.success("User Edit Successful");
      })
      .catch(function (error) {
        setAble(false);
        toast.error("There was error while editing user!");
        var result = error.response.data.error;
        Object.keys(result).map(function (key) {
          setFieldError(key, result[key][0]);
        });
      });
  }

  function getProjectList() {
    axios.get(rolesActiveProjectsAPI).then((response) => {
      setAllProjects(response.data);
    });
  }

  const getUser = async () => {
    await axios.get(`${userGetAPI}/${param.id}`).then((response) => {
      setUser(response.data);
      userObj = {
        name: response.data[0].name,
        email: response.data[0].email,
        password: response.data[0].password,
        password_confirmation: response.data[0].password,
        project: JSON.parse(response.data[0].permission).project,
        role: JSON.parse(response.data[0].permission).role,
      };
      console.log(userObj);
      setUserFields([
        {
          index: 0,
          name: "name",
          label: "Name",
          type: "text",
          placeholder: response.data[0].name
            ? response.data[0].name
            : "User Name",
          class: "pl-0",
          value: response.data[0].name,
        },
        {
          index: 1,
          name: "email",
          label: "Email",
          type: "email",
          placeholder: response.data[0].email
            ? response.data[0].email
            : "User Email",
          class: "pr-0",
          value: response.data[0].email,
        },
        {
          index: 2,
          name: "password",
          label: "Password",
          type: "text",
          placeholder: "Password",
          class: "pl-0",
          value: response.data[0].password,
        },
        {
          index: 3,
          name: "password_confirmation",
          label: "Confirm Password",
          type: "text",
          placeholder: "Confirm Password",
          class: "pr-0",
          value: response.data[0].password,
        },
      ]);
      setAssign(JSON.parse(JSON.parse(response.data[0].permission).project));

      // ============ ERROR HERE ENDS ============

      setProjectData(
        JSON.parse(JSON.parse(response.data[0].permission).project)
      );
      setRoleData(JSON.parse(JSON.parse(response.data[0].permission).project));
      // setInitials({...initials , project:projectData, role:roleData});
      setInitials(userObj);
      initialValues = initials;
      // console.log(JSON.parse(response.data[0].permission).project , "project call");
    });
  };

  if (!user && !projectData) {
    return (
      <div className="full-screen-loader">
        <img src={Loader} />
      </div>
    );
  } else {
    return (
      <>
        {/* <div className="dashboard">
                    <div className="dashboard-content w-100 d-flex flex-wrap justify-content-between"> */}
        <div className="d-inline-flex flex-wrap w-100 px-4">
          {/* <div className='w-100'> */}
          {user !== undefined && user.length > 0 ? (
            <Formik
              enableReinitialize
              initialValues={userObj || initials}
              validationSchema={validate}
              onSubmit={(values, { setFieldError }) => {
                submitForm(values, setFieldError);
              }}
            >
              {(formik) => (
                <>
                  <Form>
                    <div className="d-flex flex-wrap justify-content-between w-100">
                      <div className="user-input-block w-100">
                        <div className="d-inline-flex flex-wrap w-100 user-filter-main">
                          <div className="user-filter-title">
                            <h3 className="title">Edit User</h3>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between w-100">
                            {userFields &&
                              userFields.length > 0 &&
                              userFields.map((Field_, index) => (
                                <div
                                  key={index}
                                  className={Field_.class + " form-group col-5"}
                                >
                                  <div className="form-group">
                                    <label className="form-label m-0 pl-3 mb-1">
                                      {Field_.label}
                                    </label>
                                    <input
                                      name={Field_.name}
                                      type={Field_.type}
                                      className="form-control w-100"
                                      placeholder={Field_.placeholder}
                                      autoComplete="off"
                                      defaultValue={Field_.value}
                                      onChange={formik.handleChange}
                                    />
                                    <ErrorMessage
                                      name={Field_.name}
                                      component="span"
                                      className="field-error"
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="project-input-block w-100">
                        {
                          // (assign) ? (allProjects) ? (allProjects.length > 0) ? "allprojects have lenght greater then 0" : "allprojects does not have value greater then zero" : "allprojects value missing" : "Assign Does not have a value"
                        }

                        {assign && allProjects && allProjects.length > 0 ? (
                          <EditAssign
                            initials={initials}
                            allProjects={allProjects}
                            handleCheck={handleCheck}
                            handleRadio={handleRadio}
                            assign={assign}
                            formik={formik}
                          />
                        ) : (
                          "There is an error in Values Passed"
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-end w-100 px-2">
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={able}
                        name="submit"
                        className="add-user-btn my-2"
                        style={{ background: "#3969d5;" }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          ) : null}
          {/* </div> */}
        </div>
        {/* </div>
                </div>   */}
      </>
    );
  }
};

export default EditUser;
