import React , {useState , useEffect} from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import {ChartingPVCData} from '../../../../api/ApiList';
import CompanySearch from '../../components/CompanySearch/CompanySearch';
import FilterForm from '../../components/FilterForm/FilterForm';
import DataSeriesSelected from '../../components/DataSeries/DataSeries';
import PeriodFrequencyFilter from '../../components/PeriodFrequencyFilter/PeriodFrequencyFilter';
import ChartingDataTable from '../../components/CompanyFinancials/CompanyFinancials';
import eye from '../../../../assets/media/eye-black.svg'
import LineChart from '../../components/LineChart/LineChart';
import ColumnChart from '../../components/ColumnChart/ColumnChart';

interface IRangeFrequency {
    start: string,
    end: string,
    frequency: number
}

const Sector = () => {
    const [activeChart , setActiveChart] = useState(false);
    const [company , setCompany] = useState(
        {
            "id": 189,
            "name": "Lucky Cement Ltd",
            "symbol": "LUCK",
            "sector_id": 16,
            "sector": "CEMENT",
        }
    )
    const [lastDate , setlastDate] = useState<string>();
    const [chartType , setChartType] = useState<number>(0);
    const [data , setData] = useState<any>()
    const [mainFlag , setMainFlag] = useState(false);
    const [fieldLevel , setFieldLevel] = useState(1);
    const [sector , setSector] = useState([{value: null , label: ''}]);
    const [commodity , setCommodity] = useState([{value: null , label: ''}]);
    const [rangeFrequency , setRangeFrequency] = useState<IRangeFrequency>();
    const [blurDataSeries , setBlurDataSeries] = useState<boolean>(false);
    const [dataList , setDataList] = useState(
        {
            type: {id: null, name:""},
            sector:[{id: null, name: '' , commodity: [{id: null, name: ''}]}],
        } 
    );

    const HandleInstanceRemoval = (e , childIndex , parentIndex) => {
        // console.log(childIndex , " child instance index");
        // console.log(parentIndex , " parent instance index");
        // let rebindCommodityObj = {
        //     value: dataList.sector[parentIndex].commodity[childIndex].id,
        //     label: dataList.sector[parentIndex].commodity[childIndex].name,
        // }
        // const commodityClone = [...commodity , rebindCommodityObj];
        // setCommodity()
        // console.log(rebindCommodityObj , "removed child data series");
        // console.log(commodityClone , " commodityClone");
        // setCommodity(commodityClone)
        // console.log(e , " instance event");
        // console.log(dataList , "dataList on change removal");
        const dataSeriesClone:any = {...dataList};
        dataSeriesClone.sector[parentIndex].commodity.splice(childIndex , 1);
        // if(dataSeriesClone.sector[parentIndex].commodity.length === 0){
        //     dataSeriesClone.sector.splice(parentIndex , 1);
        // }
        // console.log(dataSeriesClone , "dataSeriesClone on change removal");
        setDataList(dataSeriesClone);
    }

    const submitAll = async () => {
        // console.log(dataList , "main dataList");
        // console.log(rangeFrequency , "rangeFrequency");
        const payload = {
            // start: rangeFrequency?.start,
            // end: rangeFrequency?.end,
            // date: `2016-01-01 - 2017-01-01`,
  
            date: `${rangeFrequency?.start} - ${rangeFrequency?.end}`,
            frequency: rangeFrequency?.frequency,
            type: dataList.type,
            sector: dataList.sector,
        }

        // console.log(payload , " main payload");

        const formData = new FormData();
        formData.append("date" , payload.date);
        formData.append("frequency" , JSON.stringify(payload.frequency));
        formData.append("type" , JSON.stringify(payload.type));
        formData.append("sector" , JSON.stringify(payload.sector));
        
        await axios({
            method: "POST",
            url: ChartingPVCData,
            data: formData,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            setBlurDataSeries(true);
            console.log(response , "pvc success response");
            if(response.data.error){
                toast.warning(response.data.error , {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }else if(response.data.data.length == 0){
                toast.warning("Data doesn't exist for this date range" , {
                    autoClose: 10000,
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }else{
                setData(response.data)
            }
        }).catch((err) => {
            console.log(err.response , "error");
        })
    }

    const getLastDate = async() => {
        const dateResponse = await axios.get('https://brain.akseerresearch.com/api/chartingtool/getlastdate');
        setlastDate(dateResponse.data);
    }

    useEffect(() => {
        getLastDate();
    } , [])

    return (
        <>
            <div className='fields-block'>
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="col-lg-6 p-0">
                        <CompanySearch/>
                        <FilterForm 
                            dataList={dataList} 
                            setDataList={setDataList} 
                            setMainFlag={setMainFlag} 
                            fieldLevel={fieldLevel}
                            setFieldLevel={setFieldLevel}
                            sector={sector} 
                            setSector={setSector} 
                            commodity={commodity} 
                            setCommodity={setCommodity} 
                            blurDataSeries={blurDataSeries}
                        />
                    </div>
                    <div className="col-lg-6 p-0">
                        <DataSeriesSelected 
                            dataList={dataList} 
                            setDataList={setDataList} 
                            blurDataSeries={blurDataSeries} 
                            HandleInstanceRemoval={HandleInstanceRemoval}
                        />
                    </div>
                </div>
            </div>
            <div className="period-frequency-block">
                <PeriodFrequencyFilter 
                    rangeFrequency={rangeFrequency}
                    lastDate={lastDate} 
                    setRangeFrequency={setRangeFrequency} 
                    mainFlag={mainFlag} 
                    submitAll={submitAll} 
                    sector={sector} 
                    setSector={setSector} 
                    setFieldLevel={setFieldLevel}
                    setDataList={setDataList} 
                    setData={setData} 
                    setBlurDataSeries={setBlurDataSeries}
                />
            </div>
            <div className='sector-data-block'>
                <div className='chart-select'>
                    {chartType === 0 ? 
                        <>
                            <button className="btn btn-chart"><span><img src={eye} alt="eye"/></span> Charts</button>
                            <ul className='list-unstyled m-0'>
                                <li><span onClick={() => setChartType(1)}>Line</span></li>
                                <li><span onClick={() => setChartType(2)}>Bar</span></li>
                            </ul>
                        </>
                    :
                        <button className="btn btn-chart" onClick={() => {setChartType(0)}}><span><img src={eye} alt="eye"/></span> Table</button>
                    }
                </div>
                <div className='d-flex flex-wrap justify-content-between align-items-start'>
                    {chartType === 0 ? 
                        <div className="col-xl-12 p-0">
                            <ChartingDataTable data={data} dataList={dataList}/>
                        </div>
                    : chartType === 1 ? 
                        <>
                            <div className="col-lg-6 p-0 pr-1">
                                <ChartingDataTable data={data} dataList={dataList}/>
                            </div>
                            <div className="col-lg-6 p-0 pl-1"> 
                                <LineChart data={data}/>
                            </div>
                        </>
                    : chartType === 2 ? 
                        <>
                            <div className="col-lg-6 p-0 pr-1">
                                <ChartingDataTable data={data} dataList={dataList}/>
                            </div>
                            <div className="col-lg-6 p-0 pl-1"> 
                                <ColumnChart data={data}/>
                            </div>
                        </>
                    : null}
                </div>
            </div>
        </>
    )
}

export default Sector;