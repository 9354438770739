import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function NewAssignForm({
  listOfCompaniesAndSectors,
  createNewPayload,
  createPayload,
  assignCompanies,
}) {
  const [listOfSectors, setListOfSectors] = useState<any>([]);
  const [listOfComapniesBySector, setListOfCompaniesBySectors] = useState<any>(
    []
  );
  const [selectedSectorId, setSelectedSectorId] = useState<any>();
  const [selectedCompanies, setSelectedCompanies] = useState<any>();

  const createSectorList = () => {
    const tempArr: any = [];
    listOfCompaniesAndSectors.map((cs, i) => {
      tempArr.push({
        label: listOfCompaniesAndSectors[i].sector,
        value: listOfCompaniesAndSectors[i].id,
      });
    });

    return tempArr;
  };

  const createListOfCompaniesBySector = (sector_id) => {
    const tempArr: any = [];
    const companies = listOfCompaniesAndSectors.find((d) => d.id === sector_id);

    companies?.company?.map((c, i) => {
      tempArr.push({
        label: companies?.company[i].name,
        value: companies?.company[i].id,
      });
    });
    console.log("Companies Found ====> ", companies);

    console.log("Companies Found ====> ", tempArr);
    setListOfCompaniesBySectors(tempArr);
    return tempArr;
  };

  const createSelectedCompanies = (value) => {
    const tempArr: any = [];
    value.map((v) => {
      console.log("Returning Company ID ===>", v.value);
      tempArr.push(v.value);
    });
    return tempArr;
  };

  useEffect(() => {
    setListOfSectors(createSectorList());
  }, []);

  // createSectorList();

  return (
    <div className="form-group">
      <label htmlFor="">Select Sector</label>
      <Select
        name="secor-select"
        options={listOfSectors}
        onChange={(value: any): any => {
          createListOfCompaniesBySector(value.value);
        }}
      />
      <label htmlFor="company-list">Select Companies</label>
      <Select
        name="company-list"
        options={listOfComapniesBySector}
        onChange={(values) => {
          // console.log("selected Values ====>", values);
          createNewPayload(values);

          createPayload(
            JSON.stringify(assignCompanies(values)).replace(/[\])}[{(]/g, "")
          );
        }}
        isMulti
      />
    </div>
  );
}
