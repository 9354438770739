import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyupdatesCategoriesAPI } from "../../../../api/ApiList";
import randomColor from "randomcolor";
import CategoryEditModal from "../../components/EditKeyUpdateCategory/EditKeyUpdateCategory";
import { generateRandomColor } from "../../../../GlobalFunctions";

type AppProps = {
  setCount: Function;
  count: number;
};

interface Item {
  id: number;
  description: string;
  name: string;
  status: boolean;
}

let selectCategory = {
  id: 0,
  name: "",
};

export default function KeyUpdatesCategories({ setCount, count }: AppProps) {
  const [editModalShow, setEditModalShow] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState(selectCategory);
  const [categories, setCategories] = useState([]);

  const closeModal = () => {
    setEditModalShow(false);
  };

  const editModal = (id: number, name: string) => {
    setEditModalShow(true);
    setSelectedCategory({ id: id, name: name });
  };

  useEffect(() => {
    axios.get(KeyupdatesCategoriesAPI).then((res) => {
      console.log(res.data , "categories res.data");
      setCategories(res.data);
    });
  }, [count]);

  const generateCategoryList = categories.map((d: Item) => {
    return (
      <li key={d.id} cat-id={d.id}>
        <button
          style={{ backgroundColor: generateRandomColor() }}
          className="red"
        >
          <span>{d.name}</span>
          <p className="my-1 d-inline-flex">
            <span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="eye"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                className="svg-inline--fa fa-eye fa-w-18"
              >
                <path
                  fill="currentColor"
                  d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
                  className=""
                ></path>
              </svg>
            </span>
            <span
              data-id={d.id}
              data-name={d.name}
              onClick={(e) => editModal(d.id, d.name)}
            >
              <svg
                width="10px"
                height="10px"
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="pencil"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="svg-inline--fa fa-pencil fa-w-16"
              >
                <path
                  fill="#ffffff"
                  d="M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zm-95.196 140.45L174 420.745V386h-48v-48H91.255l224.059-224.059 82.745 82.745zM126.147 468.598l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"
                  className=""
                ></path>
              </svg>
            </span>
          </p>
        </button>
      </li>
    );
  });

  return (
    <>
      <div className="categories w-100">
        <h3 className="title">
          <span>Categories</span>
        </h3>
        <div className="category-list">
          <ul className="list-unstyled m-0">{generateCategoryList}</ul>
        </div>
      </div>
      <CategoryEditModal
        selectedCategory={selectedCategory}
        editModalShow={editModalShow}
        closeModal={closeModal}
        setCount={setCount}
        count={count}
      />
    </>
  );
}
